import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Description of my custom scalar type */
  MyCustomScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm?: Maybe<Array<RolePermAsgEntity>>;
  MenuOption?: Maybe<Scalars['String']>;
};

export type RolePermAsgEntity = AuditEntity & {
  __typename?: 'RolePermAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type EntRoleUsrAsgEntity = AuditEntity & {
  __typename?: 'EntRoleUsrAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreLedger = InterfaceTrx & {
  __typename?: 'CoreLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export enum ArTransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION'
}

export enum Currency {
  Afn = 'AFN',
  All = 'ALL',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Amd = 'AMD',
  Awg = 'AWG',
  Aud = 'AUD',
  Azn = 'AZN',
  Bsd = 'BSD',
  Bhd = 'BHD',
  Bdt = 'BDT',
  Bbd = 'BBD',
  Byn = 'BYN',
  Bzd = 'BZD',
  Bmd = 'BMD',
  Btn = 'BTN',
  Bob = 'BOB',
  Bam = 'BAM',
  Bwp = 'BWP',
  Brl = 'BRL',
  Gbp = 'GBP',
  Bnd = 'BND',
  Bgn = 'BGN',
  Mmk = 'MMK',
  Bif = 'BIF',
  Khr = 'KHR',
  Cad = 'CAD',
  Cve = 'CVE',
  Kyd = 'KYD',
  Xaf = 'XAF',
  Xpf = 'XPF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Kmf = 'KMF',
  Cdf = 'CDF',
  Crc = 'CRC',
  Hrk = 'HRK',
  Cuc = 'CUC',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Djf = 'DJF',
  Dop = 'DOP',
  Xcd = 'XCD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Fjd = 'FJD',
  Gmd = 'GMD',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Htg = 'HTG',
  Hnl = 'HNL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Isk = 'ISK',
  Inr = 'INR',
  Idr = 'IDR',
  Irr = 'IRR',
  Iqd = 'IQD',
  Ils = 'ILS',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Jod = 'JOD',
  Kzt = 'KZT',
  Kes = 'KES',
  Kwd = 'KWD',
  Kgs = 'KGS',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lsl = 'LSL',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Mop = 'MOP',
  Mkd = 'MKD',
  Mga = 'MGA',
  Mwk = 'MWK',
  Myr = 'MYR',
  Mvr = 'MVR',
  Mru = 'MRU',
  Mur = 'MUR',
  Mxn = 'MXN',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mad = 'MAD',
  Mzn = 'MZN',
  Nad = 'NAD',
  Npr = 'NPR',
  Ang = 'ANG',
  Twd = 'TWD',
  Nzd = 'NZD',
  Nio = 'NIO',
  Ngn = 'NGN',
  Kpw = 'KPW',
  Nok = 'NOK',
  Omr = 'OMR',
  Pkr = 'PKR',
  Pab = 'PAB',
  Pgk = 'PGK',
  Pyg = 'PYG',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rub = 'RUB',
  Rwf = 'RWF',
  Shp = 'SHP',
  Wst = 'WST',
  Stn = 'STN',
  Sar = 'SAR',
  Rsd = 'RSD',
  Scr = 'SCR',
  Sll = 'SLL',
  Sgd = 'SGD',
  Sbd = 'SBD',
  Sos = 'SOS',
  Zar = 'ZAR',
  Krw = 'KRW',
  Ssp = 'SSP',
  Lkr = 'LKR',
  Sdg = 'SDG',
  Srd = 'SRD',
  Szl = 'SZL',
  Sek = 'SEK',
  Chf = 'CHF',
  Syp = 'SYP',
  Tjs = 'TJS',
  Tzs = 'TZS',
  Thb = 'THB',
  Top = 'TOP',
  Ttd = 'TTD',
  Tnd = 'TND',
  Try = 'TRY',
  Tmt = 'TMT',
  Ugx = 'UGX',
  Uah = 'UAH',
  Aed = 'AED',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vuv = 'VUV',
  Ves = 'VES',
  Vnd = 'VND',
  Xof = 'XOF',
  Yer = 'YER',
  Zmw = 'ZMW'
}

export type CoreTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type GalleryEntity = InterfaceBase & {
  __typename?: 'GalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type DebitNoteEntity = InterfaceTrx & {
  __typename?: 'DebitNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<DebitNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type DebitNoteTrxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
};

export type CreditNoteEntity = InterfaceTrx & {
  __typename?: 'CreditNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<CreditNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type CreditNoteTrxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
};

export type RefundEntity = InterfaceTrx & {
  __typename?: 'RefundEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type OfficialReceiptEntity = InterfaceTrx & {
  __typename?: 'OfficialReceiptEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceEntity = InterfaceTrx & {
  __typename?: 'InvoiceEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceTrxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  InvoiceID: Scalars['String'];
};

export type AllocationEntity = InterfaceTrx & {
  __typename?: 'AllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  AllocDate?: Maybe<Scalars['DateTime']>;
  CreditDetail: Array<DebitNoteEntity>;
  DebitDetail: Array<CreditNoteEntity>;
};

export type ReminderEntity = InterfaceTrx & {
  __typename?: 'ReminderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type InterestEntity = InterfaceTrx & {
  __typename?: 'InterestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus: ArTransactionStatus;
  IsWaived: Scalars['Boolean'];
};

export type CityLedgerAttachmentEntity = InterfaceBase & {
  __typename?: 'CityLedgerAttachmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Invoice?: Maybe<InvoiceEntity>;
  CreditNote?: Maybe<CreditNoteEntity>;
  DebitNote?: Maybe<DebitNoteEntity>;
  OfficialReceipt?: Maybe<OfficialReceiptEntity>;
  Refund?: Maybe<RefundEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type ScheduleBillingEntity = {
  __typename?: 'ScheduleBillingEntity';
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BillNo: Scalars['String'];
  CardNo: Scalars['String'];
  BillDate: Scalars['DateTime'];
  BillAmount: Scalars['Float'];
  DebtorID: Scalars['String'];
  IsPosted: Scalars['Boolean'];
  IsGLPosted: Scalars['Boolean'];
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address>;
  Designation?: Maybe<Scalars['String']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  ProfilePictureGallery?: Maybe<GalleryEntity>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Others = 'Others'
}

export type TestCountry = {
  __typename?: 'TestCountry';
  con: Country;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type TestState = {
  __typename?: 'TestState';
  con: State;
};

export enum State {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Malacca = 'Malacca',
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

export type SalesChannelEntity = InterfaceBase & {
  __typename?: 'SalesChannelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type ReasonEntity = InterfaceBase & {
  __typename?: 'ReasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type RoomTypeAmenitiesEntity = InterfaceBase & {
  __typename?: 'RoomTypeAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  Name: Scalars['String'];
  GalleryID: Scalars['String'];
  IsSelected: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type LocationEntity = InterfaceBase & {
  __typename?: 'LocationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<Array<RoomEntity>>;
};


export type LocationEntityRoomArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type RoomStatusEntity = InterfaceBase & {
  __typename?: 'RoomStatusEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Description: RoomStatus;
  Code?: Maybe<Scalars['String']>;
  VacantOrOccupied?: Maybe<Scalars['String']>;
  CleanOrDirty?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export enum RoomStatus {
  VacantClean = 'Vacant_Clean',
  VacantDirty = 'Vacant_Dirty',
  OccupiedClean = 'Occupied_Clean',
  OccupiedDirty = 'Occupied_Dirty',
  VacantInspection = 'Vacant_Inspection',
  VacantReady = 'Vacant_Ready',
  OutOfOrder = 'Out_of_Order',
  OutOfInventory = 'Out_of_Inventory'
}

export type BlockRoomLogEntity = InterfaceTrx & {
  __typename?: 'BlockRoomLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  BlockStatus?: Maybe<RoomStatusEntity>;
  Gallery?: Maybe<GalleryEntity>;
  BlockRoomLogGallery?: Maybe<Array<BlockRoomLogGalleryEntity>>;
  ReleaseRoomStatus: Scalars['String'];
};

export type BlockRoomLogGalleryEntity = InterfaceBase & {
  __typename?: 'BlockRoomLogGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockRoomLogID: Scalars['String'];
  GalleryID: Scalars['String'];
  BlockRoomLog?: Maybe<BlockRoomLogEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HouseKeepingLogEntity = InterfaceTrx & {
  __typename?: 'HouseKeepingLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type RoomDiscrepancyLogEntity = InterfaceTrx & {
  __typename?: 'RoomDiscrepancyLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomStatusID?: Maybe<Scalars['String']>;
  HouseKeepingStatusID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  HouseKeepingStatus?: Maybe<RoomStatusEntity>;
};

export enum DiscrepancyReason {
  Skip = 'Skip',
  Sleep = 'Sleep'
}

export type SegmentEntity = InterfaceBase & {
  __typename?: 'SegmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type RoomLedgerEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  TransactionID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
};

export enum ChargeType {
  Booking = 'Booking',
  Package = 'Package',
  ExtendStay = 'ExtendStay',
  ExtendStayPkg = 'ExtendStayPkg',
  LateCheckOut = 'LateCheckOut',
  RoomUpgrade = 'RoomUpgrade',
  Taxes = 'Taxes'
}

export enum TransactionType {
  Rate = 'Rate',
  Package = 'Package'
}

export type TaxLedgerEntity = InterfaceTrx & {
  __typename?: 'TaxLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
};

export enum TaxType {
  HeritageTax = 'HeritageTax',
  TourismTax = 'TourismTax',
  Levy = 'Levy',
  GovTax = 'GovTax',
  ServiceCharge = 'ServiceCharge',
  StateFund = 'StateFund'
}

export type DepositLedgerEntity = InterfaceTrx & {
  __typename?: 'DepositLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
};

export enum DepositClass {
  SecurityDeposit = 'SecurityDeposit',
  KeyCardDeposit = 'KeyCardDeposit',
  Refund = 'Refund',
  Forfeiture = 'Forfeiture',
  Reversed = 'Reversed'
}

export enum PaymentType {
  Online = 'Online',
  Cash = 'Cash',
  VisaCard = 'VisaCard',
  MasterCard = 'MasterCard',
  AmericanExpressCard = 'AmericanExpressCard',
  DinersCard = 'DinersCard',
  JcbCard = 'JCBCard',
  Unionpay = 'UNIONPAY',
  CityLedger = 'CityLedger',
  Other = 'Other',
  DebitCard = 'DebitCard',
  Room = 'Room'
}

export type BillLedgerEntity = InterfaceTrx & {
  __typename?: 'BillLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  CreatedName?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  BillLedgerSignature?: Maybe<GalleryEntity>;
  PrincipalRegistration: RegistrationEntity;
};

export type OnlineItemEntity = InterfaceTrx & {
  __typename?: 'OnlineItemEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type PaymentEntity = InterfaceTrx & {
  __typename?: 'PaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  PaymentStatus: PaymentStatus;
  IsARPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Folio?: Maybe<FolioEntity>;
};

export enum PaymentClass {
  Advance = 'Advance',
  Room = 'Room',
  Bill = 'Bill',
  Tax = 'Tax',
  Folio = 'Folio',
  Refund = 'Refund',
  Forfeit = 'Forfeit',
  Rounding = 'Rounding'
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export type NonGuestInfoJson = {
  __typename?: 'NonGuestInfoJson';
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type FolioEntity = InterfaceBase & {
  __typename?: 'FolioEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  ClosedBy?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  FolioName?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJson>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  RefFolio?: Maybe<FolioEntity>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  NonGuestBalance: NonGuestBalance;
};

export enum FolioType {
  GuestFolio = 'GuestFolio',
  GroupFolio = 'GroupFolio',
  NonGuest = 'NonGuest',
  DepositFolio = 'DepositFolio',
  OpenFolio = 'OpenFolio',
  RefundForfeiture = 'RefundForfeiture'
}

export type NonGuestBalance = {
  __typename?: 'NonGuestBalance';
  TotalBill?: Maybe<Scalars['Float']>;
  TotalPaid?: Maybe<Scalars['Float']>;
  TotalLeft?: Maybe<Scalars['Float']>;
};

export type AdvancePaymentEntity = InterfaceTrx & {
  __typename?: 'AdvancePaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  IsPosted: Scalars['Boolean'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Booking?: Maybe<BookingEntity>;
  Folio?: Maybe<FolioEntity>;
};

export type BookingEntity = InterfaceTrx & {
  __typename?: 'BookingEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingNo: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BookingStatus: BookingStatus;
  ReferenceNo?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  SalesChannel?: Maybe<SalesChannelEntity>;
  Segment?: Maybe<SegmentEntity>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  AllRegistration?: Maybe<Array<RegistrationEntity>>;
  BookedRegistration?: Maybe<Array<RegistrationEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  Debtor?: Maybe<DebtorEntity>;
  TotalAdvPayment: Scalars['Float'];
  TotalRoomRate: Scalars['Float'];
};


export type BookingEntityBookedRegistrationArgs = {
  Status?: Maybe<Scalars['String']>;
};

export enum Source {
  WalkIn = 'Walk_In',
  Online = 'Online',
  Booking = 'Booking',
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'Travel_Agent',
  ChannelManager = 'Channel_Manager',
  Alliance = 'Alliance',
  WhatsApp = 'WhatsApp',
  Telephone = 'Telephone',
  Email = 'Email'
}

export enum BookingStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow',
  Inhouse = 'Inhouse',
  CheckOut = 'CheckOut',
  Pending = 'Pending'
}

export type CurrentInhouseOutput = {
  __typename?: 'CurrentInhouseOutput';
  RoomNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type GuestProfileEntity = InterfaceBase & {
  __typename?: 'GuestProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  ICGallery?: Maybe<GalleryEntity>;
  PassportGallery?: Maybe<GalleryEntity>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
  GuestHistoryList: Array<RegistrationEntity>;
  ServiceRequestCount: Scalars['Float'];
  NoOfVisit: Scalars['Float'];
  LastYearOfVisit: Scalars['Float'];
};

export enum GuestType {
  Normal = 'Normal',
  Vip = 'Vip',
  Skipper = 'Skipper',
  Blacklist = 'Blacklist'
}

export type BillScheduleEntity = InterfaceTrx & {
  __typename?: 'BillScheduleEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  HotelID?: Maybe<Scalars['String']>;
  TransactionID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsPosted: Scalars['Boolean'];
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
};

export type RateTypeEntity = InterfaceBase & {
  __typename?: 'RateTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type SeasonEntity = InterfaceBase & {
  __typename?: 'SeasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
};


export type SeasonEntitySeasonalRateArgs = {
  RateEffectiveID?: Maybe<Scalars['String']>;
};

export type DynamicRateJson = {
  __typename?: 'DynamicRateJson';
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type EffectiveRateJson = {
  __typename?: 'EffectiveRateJson';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type RateEntity = InterfaceBase & {
  __typename?: 'RateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RateTypeID?: Maybe<Scalars['String']>;
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  Category?: Maybe<RateCategory>;
  OnlineRate: Scalars['Boolean'];
  RateType?: Maybe<RateTypeEntity>;
  Hotel?: Maybe<HotelEntity>;
  RateEffective?: Maybe<Array<RateEffectiveEntity>>;
  ContractRates?: Maybe<Array<ContractRateEntity>>;
};

export enum RateCategory {
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'TravelAgent',
  Public = 'Public',
  DayUse = 'DayUse',
  HouseUse = 'HouseUse',
  Complimentary = 'Complimentary'
}

export type RateEffectiveEntity = InterfaceBase & {
  __typename?: 'RateEffectiveEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJson>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJson>>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
  Rate?: Maybe<RateEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateRoomTypeEntity = InterfaceBase & {
  __typename?: 'RateRoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['DateTime'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type SeasonalRateEntity = InterfaceBase & {
  __typename?: 'SeasonalRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RateEffectiveID: Scalars['String'];
  DynamicRate: Array<DynamicRateJson>;
  StandardRate: Scalars['Float'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  Season?: Maybe<SeasonEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type PackageRedemptionEntity = InterfaceBase & {
  __typename?: 'PackageRedemptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
};

export type PackageRedemptionListOutput = {
  __typename?: 'PackageRedemptionListOutput';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ComputationRule?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestNames?: Maybe<Array<Scalars['String']>>;
  Amount?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
  Redeemed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
};

export type RegistrationEntity = InterfaceTrx & {
  __typename?: 'RegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  ChargeRoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  Booking?: Maybe<BookingEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
  Room?: Maybe<RoomEntity>;
  Guest?: Maybe<GuestProfileEntity>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  PackageRedemption?: Maybe<Array<PackageRedemptionEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  RegistrationSignature?: Maybe<GalleryEntity>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  DepositSum: Scalars['Float'];
  PaymentSum: Scalars['Float'];
  IncidentalChargesSum: Scalars['Float'];
  IncidentalChargesCount: Scalars['Float'];
  FolioCount?: Maybe<Scalars['Float']>;
  RoomOutStanding: Scalars['Float'];
  TotalRoomPrice: Scalars['Float'];
  TotalRoomTax: Scalars['Float'];
  TotalPackagePrice: Scalars['Float'];
  TotalPrice: Scalars['Float'];
  ServiceRequestCount: Scalars['Float'];
  TotalOutStandingAmount: Scalars['Float'];
  AdvPaymentSum: Scalars['Float'];
};

export type NaRegistrationEntity = InterfaceTrx & {
  __typename?: 'NARegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SharerNo?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export type RoomEntity = InterfaceBase & {
  __typename?: 'RoomEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Location?: Maybe<LocationEntity>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  PrincipalRegistration?: Maybe<Array<RegistrationEntity>>;
  Registrations?: Maybe<Array<RegistrationEntity>>;
  TodayBookingNo?: Maybe<Array<RegistrationEntity>>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomAssignmentStatus?: Maybe<RoomStatusEntity>;
  ServiceRequestCount: Scalars['Float'];
  RoomStatusCode: Scalars['String'];
};


export type RoomEntityRoomAssignmentStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};

export type AvailabilityEntity = InterfaceTrx & {
  __typename?: 'AvailabilityEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  SystemAdj?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type RoomSaleOptimizationOutput = {
  __typename?: 'RoomSaleOptimizationOutput';
  RoomType?: Maybe<RoomTypeEntity>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
};

export type ContractAllotmentEntity = InterfaceBase & {
  __typename?: 'ContractAllotmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  AllotDate: Scalars['DateTime'];
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  Contract?: Maybe<ContractEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  AllowDeleteAllotment?: Maybe<Scalars['Boolean']>;
};

export type PricingOutput = {
  __typename?: 'PricingOutput';
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
  BasePrice?: Maybe<Scalars['Float']>;
  PackageName?: Maybe<Scalars['Float']>;
  PackagePrice?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Packages?: Maybe<Array<PackagesEntity>>;
};

export type PackagePriceOutput = {
  __typename?: 'PackagePriceOutput';
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomTypeCode?: Maybe<Scalars['String']>;
  RoomTypeName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PricingOutput>>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  SeasonType: Scalars['String'];
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type Occupancy = {
  __typename?: 'Occupancy';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Season: Scalars['String'];
  Occupancy?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
};

export type RoomTypesListOutput = {
  __typename?: 'RoomTypesListOutput';
  ID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
};

export type HotelAvailabilityOutput = {
  __typename?: 'HotelAvailabilityOutput';
  RoomTypesList?: Maybe<Array<RoomTypesListOutput>>;
  Occupancy?: Maybe<Array<Occupancy>>;
};

export type TotalPackagesResponse = {
  __typename?: 'TotalPackagesResponse';
  PackageName?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  PriceID?: Maybe<Scalars['String']>;
};

export type RoomTypeEntity = InterfaceBase & {
  __typename?: 'RoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PropertyType?: Maybe<PropertyType>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  MeasureType?: Maybe<MeasureType>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomTypeAmenities?: Maybe<Array<RoomTypeAmenitiesEntity>>;
  RoomTypeGallery?: Maybe<Array<RoomTypeGalleryEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  Price?: Maybe<Array<PricingEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  TotalPackages?: Maybe<Array<TotalPackagesResponse>>;
};

export enum PropertyType {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Campsite = 'Campsite',
  Cottage = 'Cottage',
  Dorm = 'Dorm',
  Room = 'Room',
  Villa = 'Villa'
}

export enum MeasureType {
  SquareMetre = 'Square_Metre',
  FootSquare = 'Foot_Square'
}

export type RoomTypeGalleryEntity = InterfaceBase & {
  __typename?: 'RoomTypeGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PricingEntity = InterfaceBase & {
  __typename?: 'PricingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  PackageAmount?: Maybe<Scalars['Float']>;
  SellingPrice?: Maybe<Scalars['Float']>;
  Season?: Maybe<Scalars['String']>;
};

export type RatePolicyEntity = InterfaceBase & {
  __typename?: 'RatePolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category?: Maybe<RateCategory>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type PackagesEntity = InterfaceBase & {
  __typename?: 'PackagesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export enum ComputationRules {
  FlatRate = 'FlatRate',
  PerPerson = 'PerPerson'
}

export type IncidentalChargeEntity = InterfaceBase & {
  __typename?: 'IncidentalChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Department?: Maybe<DepartmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason: Scalars['Boolean'];
  AllowSalesChannel: Scalars['Boolean'];
  AllowIncidental: Scalars['Boolean'];
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  SalesChannel?: Maybe<Array<SalesChannelEntity>>;
  Reason?: Maybe<Array<ReasonEntity>>;
  IncidentalCharge?: Maybe<Array<IncidentalChargeEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
};

export type ServiceRequestEntity = InterfaceTrx & {
  __typename?: 'ServiceRequestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion: Scalars['DateTime'];
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Department?: Maybe<DepartmentEntity>;
  Room?: Maybe<RoomEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Registration?: Maybe<RegistrationEntity>;
  ServiceRequestGallery?: Maybe<Array<ServiceRequestGalleryEntity>>;
};

export enum ServiceRequestStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export type ServiceRequestGalleryEntity = InterfaceBase & {
  __typename?: 'ServiceRequestGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ServiceRequestID: Scalars['String'];
  GalleryID: Scalars['String'];
  ServiceRequest?: Maybe<ServiceRequestEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type ArSpecification = {
  __typename?: 'ARSpecification';
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type HotelRatingEntity = InterfaceBase & {
  __typename?: 'HotelRatingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate: Scalars['DateTime'];
  ReviewCount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
};

export type ChannelManagerEntity = InterfaceBase & {
  __typename?: 'ChannelManagerEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass?: Maybe<Scalars['String']>;
  AuthKey?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  HotelMapID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type CmsLogEntity = InterfaceBase & {
  __typename?: 'CMSLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  BookingNo?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
};

export type CmsResponseEntity = InterfaceBase & {
  __typename?: 'CMSResponseEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  RawData?: Maybe<Scalars['String']>;
};

export type PaymentGatewayEntity = InterfaceBase & {
  __typename?: 'PaymentGatewayEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type FacilityEntity = InterfaceBase & {
  __typename?: 'FacilityEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  FacilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
};

export type FacilityGalleryEntity = InterfaceBase & {
  __typename?: 'FacilityGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  Facility?: Maybe<FacilityEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  NewsGallery?: Maybe<Array<NewsGalleryEntity>>;
};

export type NewsGalleryEntity = InterfaceBase & {
  __typename?: 'NewsGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  News?: Maybe<NewsEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type DocumentNumberingEntity = InterfaceBase & {
  __typename?: 'DocumentNumberingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  Invoice?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type PromotionEntity = InterfaceBase & {
  __typename?: 'PromotionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  PromotionDetail?: Maybe<Array<PromotionDetailEntity>>;
  PromotionDetailRatePolicy?: Maybe<Array<PromotionDetailEntity>>;
};


export type PromotionEntityPromotionDetailRatePolicyArgs = {
  RatePolicyID?: Maybe<Scalars['String']>;
};

export enum DiscountType {
  ByAmount = 'By_Amount',
  ByPercentage = 'By_Percentage'
}

export type PromotionDetailEntity = InterfaceBase & {
  __typename?: 'PromotionDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  Promotion?: Maybe<PromotionEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type SeasonCalendarEntity = InterfaceBase & {
  __typename?: 'SeasonCalendarEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType: SeasonType;
  Season?: Maybe<SeasonEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export enum SeasonType {
  Peak = 'Peak',
  SuperPeak = 'SuperPeak',
  NonPeak = 'NonPeak'
}

export type LateCheckOutChargeEntity = InterfaceBase & {
  __typename?: 'LateCheckOutChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DebtorTypeEntity = InterfaceBase & {
  __typename?: 'DebtorTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorType: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Hotel?: Maybe<HotelEntity>;
};

export type EmailCampignEntity = InterfaceBase & {
  __typename?: 'EmailCampignEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HotelGalleryEntity = InterfaceBase & {
  __typename?: 'HotelGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type GuestLedgerAcctJson = {
  __typename?: 'GuestLedgerAcctJson';
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type HotelEntity = InterfaceBase & {
  __typename?: 'HotelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<Address>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt: Scalars['Boolean'];
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecification>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  OnlinePaymentStoreID?: Maybe<Scalars['String']>;
  KeycardVendor?: Maybe<Scalars['String']>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  CompanyName?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Encoders?: Maybe<Scalars['JSON']>;
  Denomination?: Maybe<Scalars['Float']>;
  RoundingMethod?: Maybe<Scalars['String']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  Contact?: Maybe<ContactEntity>;
  CheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckInRoomStatus?: Maybe<RoomStatusEntity>;
  TransferRoomStatus?: Maybe<RoomStatusEntity>;
  CheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  BlockRoomReleaseStatus?: Maybe<RoomStatusEntity>;
  CancelCheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  DayUse?: Maybe<RateEntity>;
  HouseUse?: Maybe<RateEntity>;
  Complimentary?: Maybe<RateEntity>;
  AboutHotelImage?: Maybe<GalleryEntity>;
  LogoImage?: Maybe<GalleryEntity>;
  ChannelManager?: Maybe<ChannelManagerEntity>;
  PaymentGateway?: Maybe<PaymentGatewayEntity>;
  Facility?: Maybe<Array<FacilityEntity>>;
  News?: Maybe<Array<NewsEntity>>;
  Segment?: Maybe<Array<SegmentEntity>>;
  DocumentNumbering?: Maybe<DocumentNumberingEntity>;
  HotelRating?: Maybe<Array<HotelRatingEntity>>;
  Department?: Maybe<Array<DepartmentEntity>>;
  Promotion?: Maybe<Array<PromotionEntity>>;
  Location?: Maybe<Array<LocationEntity>>;
  RoomStatus?: Maybe<Array<RoomStatusEntity>>;
  RoomType?: Maybe<Array<RoomTypeEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  SeasonCalendar?: Maybe<Array<SeasonCalendarEntity>>;
  LateCheckOutCharge?: Maybe<Array<LateCheckOutChargeEntity>>;
  RatePolicy?: Maybe<Array<RateEntity>>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  EmailCampign?: Maybe<Array<EmailCampignEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Price?: Maybe<Array<PricingOutput>>;
  HotelDebtor?: Maybe<DebtorEntity>;
  HotelRate?: Maybe<Array<RoomTypeEntity>>;
  HotelGallery?: Maybe<Array<HotelGalleryEntity>>;
  HotelAdjustmentIncidentalID?: Maybe<Scalars['String']>;
  WeightedAverage: Scalars['Float'];
};


export type NaProcessLogEntity = InterfaceBase & {
  __typename?: 'NAProcessLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  PendingBooking?: Maybe<Scalars['Boolean']>;
  NoShowBooking?: Maybe<Scalars['Boolean']>;
  HouseKeepingUpdate?: Maybe<Scalars['Boolean']>;
  ReleaseBlockRoom?: Maybe<Scalars['Boolean']>;
  PostAdvancePayment?: Maybe<Scalars['Boolean']>;
  PostRoomRevenue?: Maybe<Scalars['Boolean']>;
  AvailabilityUpdate?: Maybe<Scalars['Boolean']>;
  AutoExtendStay?: Maybe<Scalars['Boolean']>;
  DailyRegistration?: Maybe<Scalars['Boolean']>;
  NAStatus?: Maybe<Scalars['String']>;
};

export type CustomizedDocumentEntity = InterfaceBase & {
  __typename?: 'CustomizedDocumentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DebtorContactEntity = InterfaceBase & {
  __typename?: 'DebtorContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  SalesChannelID: Scalars['String'];
  Debtor?: Maybe<DebtorEntity>;
  Contact?: Maybe<ContactEntity>;
};

export type DebtorProfileEntity = InterfaceBase & {
  __typename?: 'DebtorProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
};

export type CreditCardMapping = {
  __typename?: 'CreditCardMapping';
  Name?: Maybe<Scalars['String']>;
};

export type DebtorEntity = InterfaceBase & {
  __typename?: 'DebtorEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID: Scalars['String'];
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  CreditCardMapping?: Maybe<Array<CreditCardMapping>>;
  DebtorCategory?: Maybe<DebtorCategory>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  DebtorType?: Maybe<DebtorTypeEntity>;
  SegmentDetails?: Maybe<SegmentEntity>;
  Contract?: Maybe<Array<ContractEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  DebtorProfile?: Maybe<DebtorProfileEntity>;
  accessToken?: Maybe<Scalars['String']>;
  LastBooking?: Maybe<Scalars['DateTime']>;
  ARTotalOutstanding: Scalars['Float'];
  ARTotalDue: Scalars['Float'];
  LastPaymentDate?: Maybe<Scalars['DateTime']>;
};

export enum DebtorCategory {
  Corporate = 'Corporate',
  TravelAgent = 'TravelAgent',
  Government = 'Government'
}

export type ContractEntity = InterfaceBase & {
  __typename?: 'ContractEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  Debtor?: Maybe<DebtorEntity>;
  ContractRate?: Maybe<Array<ContractRateEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  ContractAllotmentByRoomType?: Maybe<Array<ContractAllotmentEntity>>;
  AllowDeleteContract?: Maybe<Scalars['Boolean']>;
};

export type ContractRateEntity = InterfaceBase & {
  __typename?: 'ContractRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  Contract?: Maybe<ContractEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type PlatformAmenitiesEntity = InterfaceBase & {
  __typename?: 'PlatformAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type SharedGalleryEntity = InterfaceBase & {
  __typename?: 'SharedGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  Gallery?: Maybe<GalleryEntity>;
};

export type RateElementEntity = InterfaceBase & {
  __typename?: 'RateElementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule?: Maybe<Scalars['String']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateAddOnEntity = InterfaceBase & {
  __typename?: 'RateAddOnEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateAdjustmentAuditEntity = InterfaceBase & {
  __typename?: 'RateAdjustmentAuditEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  OldRate?: Maybe<RateEntity>;
  NewRate?: Maybe<RateEntity>;
};

export type GuestMovementEntity = InterfaceBase & {
  __typename?: 'GuestMovementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Booking?: Maybe<BookingEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type HotelTaxPolicyEntity = InterfaceBase & {
  __typename?: 'HotelTaxPolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  TTxExempted?: Maybe<ReasonEntity>;
  TTxNonExempted?: Maybe<ReasonEntity>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type PlatformHeritageTaxEntity = InterfaceBase & {
  __typename?: 'PlatformHeritageTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTourismTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTourismTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformStateFundEntity = InterfaceBase & {
  __typename?: 'PlatformStateFundEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformLevyEntity = InterfaceBase & {
  __typename?: 'PlatformLevyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformServiceChargeEntity = InterfaceBase & {
  __typename?: 'PlatformServiceChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type HotelTaxOutput = {
  __typename?: 'HotelTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CurrentTaxOutput = {
  __typename?: 'currentTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CurrentTaxDetailsOutput = {
  __typename?: 'CurrentTaxDetailsOutput';
  HotelID?: Maybe<Scalars['String']>;
  GovTax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  SampleOutput?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type AtestEntity = InterfaceTrx & {
  __typename?: 'AtestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  Address?: Maybe<Array<Address>>;
};

export type ActivitiesEntity = InterfaceBase & {
  __typename?: 'ActivitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Debtor?: Maybe<DebtorEntity>;
  Audio?: Maybe<GalleryEntity>;
};

export type DocumentTemplateEntity = InterfaceBase & {
  __typename?: 'DocumentTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type EmailTemplateEntity = InterfaceBase & {
  __typename?: 'EmailTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  Postpaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
};

export type GlExportEntity = InterfaceTrx & {
  __typename?: 'GLExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['DateTime'];
  TrxDate: Scalars['DateTime'];
  GLType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Registration?: Maybe<HotelEntity>;
};

export type CbExportEntity = InterfaceTrx & {
  __typename?: 'CBExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  CBDate?: Maybe<Scalars['DateTime']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  CBType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  ID: Scalars['ID'];
  HotelID?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
};

export type HotelXOtpEntity = InterfaceTrx & {
  __typename?: 'HotelXOtpEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Expiry?: Maybe<Scalars['DateTime']>;
  IPAddress?: Maybe<Scalars['String']>;
};

export type PlatformDepartmentEntity = InterfaceBase & {
  __typename?: 'PlatformDepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
};

export type TaxEffectiveDateEntity = InterfaceBase & {
  __typename?: 'TaxEffectiveDateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
};

export type RoomTransferLogEntity = InterfaceTrx & {
  __typename?: 'RoomTransferLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type EDocParamsEntity = InterfaceBase & {
  __typename?: 'EDocParamsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocType: Scalars['String'];
  ColName: Scalars['String'];
};

export type EDocTemplateEntity = InterfaceBase & {
  __typename?: 'EDocTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
};

export type PaymentGlEntity = InterfaceTrx & {
  __typename?: 'PaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
};

export type RefundGlEntity = InterfaceTrx & {
  __typename?: 'RefundGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RefundType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
};

export type DepositGlEntity = InterfaceTrx & {
  __typename?: 'DepositGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DepositType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
};

export type AdvPaymentGlEntity = InterfaceTrx & {
  __typename?: 'AdvPaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AdvPaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
};

export type BankAccountEntity = InterfaceTrx & {
  __typename?: 'BankAccountEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactPerson?: Maybe<Array<ContactPerson>>;
  Hotel?: Maybe<HotelEntity>;
  BankGLAcct?: Maybe<GuestLedgerAcctJson>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
};

export type MeasurementEntity = InterfaceBase & {
  __typename?: 'MeasurementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
};

export type KeyCardEntity = {
  __typename?: 'KeyCardEntity';
  rowid: Scalars['Float'];
  s_data?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  PaySessionNo?: Maybe<Scalars['Float']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<FieldError>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type InterestResponse = {
  __typename?: 'InterestResponse';
  TotalTrxAmt: Scalars['Float'];
  TotalInterestAmt: Scalars['Float'];
};

export type Data = {
  __typename?: 'data';
  ID?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  osAmt?: Maybe<Scalars['String']>;
};

export type Test = InterfaceTrx & {
  __typename?: 'test';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
  Booking?: Maybe<Scalars['JSON']>;
};

export type OpenClosedResponse = {
  __typename?: 'OpenClosedResponse';
  OpenInvoice?: Maybe<Array<Data>>;
  OpendebitNote?: Maybe<Array<Data>>;
  OpenCreditNote?: Maybe<Array<Data>>;
  OpenOR?: Maybe<Array<Data>>;
  OpenRefund?: Maybe<Array<Data>>;
  ClosedInvoice?: Maybe<Array<Data>>;
  CloseddebitNote?: Maybe<Array<Data>>;
  ClosedCreditNote?: Maybe<Array<Data>>;
  ClosedOR?: Maybe<Array<Data>>;
  ClosedRefund?: Maybe<Array<Data>>;
};

export type DocResponse = {
  __typename?: 'docResponse';
  Invoice?: Maybe<Array<Test>>;
  CnDoc?: Maybe<Array<CreditNoteEntity>>;
  DnDoc?: Maybe<Array<DebitNoteEntity>>;
  OrDoc?: Maybe<Array<OfficialReceiptEntity>>;
  RefundDoc?: Maybe<Array<RefundEntity>>;
};

export type ArSummaryInfo = {
  __typename?: 'ARSummaryInfo';
  LastCreatedDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  noOfDocument?: Maybe<Scalars['Float']>;
};

export type ArAccountSummary = {
  __typename?: 'ARAccountSummary';
  DNSum?: Maybe<Scalars['Float']>;
  InvSum?: Maybe<Scalars['Float']>;
  CNSum?: Maybe<Scalars['Float']>;
  RefundSum?: Maybe<Scalars['Float']>;
  ORSum?: Maybe<Scalars['Float']>;
};

export type ArAccountOsLastPayment = {
  __typename?: 'ARAccountOSLastPayment';
  LastPaymentDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
};

export type ArAgingAnalysisRespose = {
  __typename?: 'ARAgingAnalysisRespose';
  day30: Scalars['Float'];
  day60: Scalars['Float'];
  day90: Scalars['Float'];
  day120: Scalars['Float'];
  day150: Scalars['Float'];
  day180: Scalars['Float'];
  daygt180: Scalars['Float'];
};

export type ScheduleBillingResponse = {
  __typename?: 'ScheduleBillingResponse';
  BookingNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
};

export type FrontDeskResponse = {
  __typename?: 'FrontDeskResponse';
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalAmt?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  DepositClass?: Maybe<DepositClass>;
  PaymentType?: Maybe<PaymentType>;
  FolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DepositID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  DepositResult?: Maybe<Scalars['MyCustomScalar']>;
};


export type FrontDeskInfoLineResponse = {
  __typename?: 'FrontDeskInfoLineResponse';
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalGroupDueIn?: Maybe<Scalars['Float']>;
  TotalGroupCheckIn?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
  TotalDepartureDueOut?: Maybe<Scalars['Float']>;
  DepartureDueOut?: Maybe<Scalars['Float']>;
  TotalRegistrationDueOut?: Maybe<Scalars['Float']>;
  RegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  TotalDepositRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  TotalNonGuestFolio?: Maybe<Scalars['Float']>;
  TotalContactTracingOutlet?: Maybe<Scalars['Float']>;
};

export type PaymentFoilioResponse = {
  __typename?: 'PaymentFoilioResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
};

export type NonGuestFolioResponse = {
  __typename?: 'NonGuestFolioResponse';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  IncidentalDescription?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReversedID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type PromoCodeResponse = {
  __typename?: 'PromoCodeResponse';
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
};

export type RoomInfoResponse = {
  __typename?: 'RoomInfoResponse';
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
};

export type BookingInfoResponse = {
  __typename?: 'BookingInfoResponse';
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  RoomInfo?: Maybe<Array<RoomInfoResponse>>;
};

export type FolioHistoryResponse = {
  __typename?: 'FolioHistoryResponse';
  InvoiceNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  FolioType?: Maybe<Scalars['String']>;
};

export type GuestReviewInfoLineResponse = {
  __typename?: 'GuestReviewInfoLineResponse';
  InActivated?: Maybe<Scalars['Float']>;
  LessThan3Star?: Maybe<Scalars['Float']>;
  MoreThan3Star?: Maybe<Scalars['Float']>;
};

export type SegmentReportOutput = {
  __typename?: 'SegmentReportOutput';
  SegmentID?: Maybe<Scalars['String']>;
  SegmentName?: Maybe<Scalars['String']>;
  SegmentYRoomCount?: Maybe<Scalars['Float']>;
  SegmentYGuestCount?: Maybe<Scalars['Float']>;
  SegmentYRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentYOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentYARR?: Maybe<Scalars['Float']>;
  SegmentMRoomCount?: Maybe<Scalars['Float']>;
  SegmentMGuestCount?: Maybe<Scalars['Float']>;
  SegmentMRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentMOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentMARR?: Maybe<Scalars['Float']>;
  SegmentDRoomCount?: Maybe<Scalars['Float']>;
  SegmentDGuestCount?: Maybe<Scalars['Float']>;
  SegmentDRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentDOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentDARR?: Maybe<Scalars['Float']>;
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  Room?: Maybe<RoomEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Contact?: Maybe<ContactEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  BookingID?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  BillSchedule?: Maybe<BillScheduleEntity>;
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  BillLedger?: Maybe<BillLedgerEntity>;
};

export type PaymentTestClass = {
  __typename?: 'PaymentTestClass';
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  Hotel?: Maybe<Array<HotelEntity>>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  Booking?: Maybe<BookingEntity>;
};

export type PaymentListing123 = {
  __typename?: 'PaymentListing123';
  RegistrationID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<Scalars['String']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Result?: Maybe<Array<RegistrationResult>>;
};

export type RevenueAnalysis = {
  __typename?: 'RevenueAnalysis';
  HotelDate?: Maybe<Scalars['DateTime']>;
  OCCRoom?: Maybe<Scalars['Float']>;
  DueIn?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  ExpOcc?: Maybe<Scalars['Float']>;
  OCC?: Maybe<Scalars['Float']>;
  NoOfPax?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  AvailableRoom?: Maybe<Scalars['Float']>;
  DayUse?: Maybe<Scalars['Float']>;
  CompUse?: Maybe<Scalars['Float']>;
  RoomRevenue?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  OtherRevenue?: Maybe<Scalars['Float']>;
  Arr?: Maybe<Scalars['Float']>;
  OOI?: Maybe<Scalars['Float']>;
  OOO?: Maybe<Scalars['Float']>;
};

export type SourceReportOutput = {
  __typename?: 'SourceReportOutput';
  SourceName?: Maybe<Scalars['String']>;
  SourceYRoomCount?: Maybe<Scalars['Float']>;
  SourceYGuestCount?: Maybe<Scalars['Float']>;
  SourceYRoomRevenue?: Maybe<Scalars['Float']>;
  SourceYOtherRevenue?: Maybe<Scalars['Float']>;
  SourceYARR?: Maybe<Scalars['Float']>;
  SourceMRoomCount?: Maybe<Scalars['Float']>;
  SourceMGuestCount?: Maybe<Scalars['Float']>;
  SourceMRoomRevenue?: Maybe<Scalars['Float']>;
  SourceMOtherRevenue?: Maybe<Scalars['Float']>;
  SourceMARR?: Maybe<Scalars['Float']>;
  SourceDRoomCount?: Maybe<Scalars['Float']>;
  SourceDGuestCount?: Maybe<Scalars['Float']>;
  SourceDRoomRevenue?: Maybe<Scalars['Float']>;
  SourceDOtherRevenue?: Maybe<Scalars['Float']>;
  SourceDARR?: Maybe<Scalars['Float']>;
};

export type CountryReportOutput = {
  __typename?: 'CountryReportOutput';
  CountryName?: Maybe<Scalars['String']>;
  CountryYRoomCount?: Maybe<Scalars['Float']>;
  CountryYGuestCount?: Maybe<Scalars['Float']>;
  CountryYRoomRevenue?: Maybe<Scalars['Float']>;
  CountryYOtherRevenue?: Maybe<Scalars['Float']>;
  CountryYARR?: Maybe<Scalars['Float']>;
  CountryMRoomCount?: Maybe<Scalars['Float']>;
  CountryMGuestCount?: Maybe<Scalars['Float']>;
  CountryMRoomRevenue?: Maybe<Scalars['Float']>;
  CountryMOtherRevenue?: Maybe<Scalars['Float']>;
  CountryMARR?: Maybe<Scalars['Float']>;
  CountryDRoomCount?: Maybe<Scalars['Float']>;
  CountryDGuestCount?: Maybe<Scalars['Float']>;
  CountryDRoomRevenue?: Maybe<Scalars['Float']>;
  CountryDOtherRevenue?: Maybe<Scalars['Float']>;
  CountryDARR?: Maybe<Scalars['Float']>;
};

export type DepartmentReportOutput = {
  __typename?: 'DepartmentReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type FolioOutput = {
  __typename?: 'FolioOutput';
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  RegID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
};

export type TaxReportOutput = {
  __typename?: 'TaxReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type PaymentOutput = {
  __typename?: 'PaymentOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RateAmount?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
};

export type VipInHouseOutput = {
  __typename?: 'VIPInHouseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GroupInHouseOutput = {
  __typename?: 'GroupInHouseOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type TmrGroupOutput = {
  __typename?: 'TmrGroupOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type HouseUseOutput = {
  __typename?: 'HouseUseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type CompOutput = {
  __typename?: 'CompOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type ExtendStayOutPut = {
  __typename?: 'ExtendStayOutPut';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type ForecastOutPut = {
  __typename?: 'ForecastOutPut';
  Category?: Maybe<Scalars['String']>;
  TodayDate?: Maybe<Scalars['Float']>;
  TomorrowDate?: Maybe<Scalars['Float']>;
  DayAfterTomorrow?: Maybe<Scalars['Float']>;
};

export type RegAndBooking = {
  __typename?: 'RegAndBooking';
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type ManagerReportOutput = {
  __typename?: 'ManagerReportOutput';
  SegmentReport?: Maybe<Array<SegmentReportOutput>>;
  SourceReport?: Maybe<Array<SourceReportOutput>>;
  CountryReport?: Maybe<Array<CountryReportOutput>>;
  DepartmentReport?: Maybe<Array<DepartmentReportOutput>>;
  TaxReport?: Maybe<Array<TaxReportOutput>>;
  PaymentReport?: Maybe<Array<PaymentOutput>>;
  BookingCancellation?: Maybe<Array<BookingCancellation>>;
  VIPInHouse?: Maybe<Array<VipInHouseOutput>>;
  GroupInHouse?: Maybe<Array<GroupInHouseOutput>>;
  TomorrowGroup?: Maybe<Array<TmrGroupOutput>>;
  HouseUseRoom?: Maybe<Array<HouseUseOutput>>;
  CompRoom?: Maybe<Array<CompOutput>>;
  ExtendStay?: Maybe<Array<ExtendStayOutPut>>;
  ThreeDayForcast?: Maybe<Array<ForecastOutPut>>;
};

export type DashBoardRespond = {
  __typename?: 'DashBoardRespond';
  DueIn?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalInHouseRoom?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  TotalDueOut?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  RevenueToday?: Maybe<Scalars['Float']>;
  RevenueMonthToDay?: Maybe<Scalars['Float']>;
  CollectionToday?: Maybe<Scalars['Float']>;
  CollectionMonthToDay?: Maybe<Scalars['Float']>;
  CurrentOccupiedRoom?: Maybe<Scalars['Float']>;
  CurrentInHouse?: Maybe<Scalars['Float']>;
  CurrentComplimentary?: Maybe<Scalars['Float']>;
  CurrentDayUse?: Maybe<Scalars['Float']>;
  CurrentOthers?: Maybe<Scalars['Float']>;
  CurrentOccupancyRate?: Maybe<Scalars['Float']>;
  CurrentArr?: Maybe<Scalars['Float']>;
  CurrentRoomRevenue?: Maybe<Scalars['Float']>;
  ProjectOccupiedRoom?: Maybe<Scalars['Float']>;
  ProjectInHouse?: Maybe<Scalars['Float']>;
  ProjectComplimentary?: Maybe<Scalars['Float']>;
  ProjectDayUse?: Maybe<Scalars['Float']>;
  ProjectOthers?: Maybe<Scalars['Float']>;
  ProjectOccupancyRate?: Maybe<Scalars['Float']>;
  ProjectArr?: Maybe<Scalars['Float']>;
  ProjectRoomRevenue?: Maybe<Scalars['Float']>;
};

export type HotelStatisticRespond = {
  __typename?: 'HotelStatisticRespond';
  TotalRoomRevenue?: Maybe<Scalars['Float']>;
  TotalFnBRevenue?: Maybe<Scalars['Float']>;
  TotalOtherRevenue?: Maybe<Scalars['Float']>;
  TotalOccupancyRate?: Maybe<Scalars['Float']>;
  TotalArr?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  OOOOOI?: Maybe<Scalars['Float']>;
  TotalRentableRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalComplimentary?: Maybe<Scalars['Float']>;
  TotalHouseUse?: Maybe<Scalars['Float']>;
  TotalDayUse?: Maybe<Scalars['Float']>;
  TotalPax?: Maybe<Scalars['Float']>;
};

export type SearchGuestResponse = {
  __typename?: 'SearchGuestResponse';
  GuestID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NRIC?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  Description?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
};

export type RatePolicyResponse = {
  __typename?: 'RatePolicyResponse';
  TotalSeason?: Maybe<Scalars['Float']>;
  TotalAddOn?: Maybe<Scalars['Float']>;
  SeasonCalendarDate?: Maybe<Scalars['DateTime']>;
  RateElementDate?: Maybe<Scalars['DateTime']>;
  RateTypeDate?: Maybe<Scalars['DateTime']>;
  RateSetupDate?: Maybe<Scalars['DateTime']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type InterfaceBase = {
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTrx = {
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BankDetailsInput = {
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type GalleryInput = {
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type CityLedgerAttachmentInput = {
  ID: Scalars['String'];
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SchBillingInput = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BillNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  BillDate?: Maybe<Scalars['DateTime']>;
  BillAmount?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};

export type SalesChannelInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeAmenitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  GalleryID?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DisplaySequenceInput = {
  ID?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
};

export type RoomStatusInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description: RoomStatus;
  Code: Scalars['String'];
  VacantOrOccupied: Scalars['String'];
  CleanOrDirty: Scalars['String'];
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BlockRoomLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HouseKeepingLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type RoomDiscrepancyLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SegmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepositLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  DepositClass: DepositClass;
  DepositNo: Scalars['String'];
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  ReferenceNo: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type OnlineItemInput = {
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass: PaymentClass;
  PaymentType: PaymentType;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJsonInput = {
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type FolioInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  InvoiceNo?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJsonInput>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LedgerIDsInput = {
  BillSchedulesSplit?: Maybe<Array<Scalars['Float']>>;
  BillSchedulesDescription?: Maybe<Array<Scalars['String']>>;
  BillSchedules?: Maybe<Array<Scalars['String']>>;
  BillSchedulesDate?: Maybe<Array<Scalars['DateTime']>>;
  RoomLedgersSplit?: Maybe<Array<Scalars['Float']>>;
  RoomLedgersDescription?: Maybe<Array<Scalars['String']>>;
  RoomLedgers?: Maybe<Array<Scalars['String']>>;
  RoomLedgersDate?: Maybe<Array<Scalars['DateTime']>>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  TaxLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentIDs?: Maybe<Array<Scalars['String']>>;
  AdvancePaymentIDs?: Maybe<Array<Scalars['String']>>;
  DepositLedgerIDs?: Maybe<Array<Scalars['String']>>;
};

export type AdvancePaymentInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  AdvancePaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type BookingInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ContactID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<BookingStatus>;
  ReferenceNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GuestProfileInput = {
  ID: Scalars['String'];
  ContactID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
};

export type BillScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RateTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type SeasonInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type DynamicRateJsonInput = {
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type EffectiveRateJsonInput = {
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type RateInput = {
  ID?: Maybe<Scalars['String']>;
  RateTypeID: Scalars['String'];
  HotelID: Scalars['String'];
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  OnlineRate: Scalars['Boolean'];
  Category?: Maybe<RateCategory>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RateEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJsonInput>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJsonInput>>;
};

export type RateRoomTypeInput = {
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type SeasonRateInput = {
  StandardRate: Scalars['Float'];
  DynamicRate: Array<DynamicRateJsonInput>;
};

export type SeasonalRateInput = {
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  SeasonRate: SeasonRateInput;
};

export type PackageRedemptionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  UserID?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  AvailabilityID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  contactInput?: Maybe<Array<ContactInput>>;
};

export type SpecialRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type EditRegInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  OldRatePolicyID?: Maybe<Scalars['String']>;
  OldNoOfRoom?: Maybe<Scalars['Float']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  NewRoomTypeID?: Maybe<Scalars['String']>;
  NewRatePolicyID?: Maybe<Scalars['String']>;
  NewNoOfRoom?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  Mode?: Maybe<Scalars['String']>;
};

export type RoomInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RoomSize: Scalars['Float'];
  DisplaySequence?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AvailabilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ContractAllotmentInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  AllotDate?: Maybe<Scalars['DateTime']>;
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type RoomTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  PropertyType?: Maybe<PropertyType>;
  MeasureType?: Maybe<MeasureType>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeGalleryInput = {
  ID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category: RateCategory;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PackagesInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type IncidentalChargeInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};


export type DepartmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ServiceRequestInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion?: Maybe<Scalars['DateTime']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ArSpecificationInput = {
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type HotelRatingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate?: Maybe<Scalars['DateTime']>;
  ReviewCount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ChannelManagerInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SeasonCalendarInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType?: Maybe<SeasonType>;
};

export type LateCheckOutChargeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DebtorTypeInput = {
  HotelID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type EmailCampignInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Attachment?: Maybe<Scalars['Upload']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
};

export type HotelGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJsonInput = {
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type HotelInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<AddressInput>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt?: Maybe<Scalars['Boolean']>;
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecificationInput>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  CompanyName?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DebtorContactInput = {
  ID: Scalars['String'];
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorProfileInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type CreditCardMappingInput = {
  Name?: Maybe<Scalars['String']>;
};

export type DebtorInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  CreditCardMapping?: Maybe<Array<CreditCardMappingInput>>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
};

export type ContractRateInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type AmenitiesInput = {
  ID: Scalars['String'];
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SharedGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RateElementInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Min?: Maybe<Scalars['Float']>;
  Max?: Maybe<Scalars['Float']>;
};

export type RateAddOnInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description: Scalars['String'];
  PostingRythm: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RateAdjustmentAuditInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
};

export type GuestMovementInput = {
  ID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type HotelTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformHeritageTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTourismTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformStateFundInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLevyInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformServiceChargeInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTaxInput = {
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxInput = {
  HotelID?: Maybe<Scalars['String']>;
  TaxIdentifier: TaxIdentifier;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export enum TaxIdentifier {
  Room = 'Room',
  Incidental = 'Incidental'
}

export type DocumentNumberHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
};

export type DocumentNumberDetailInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type AtestInput = {
  Address?: Maybe<Array<AddressInput>>;
};

export type ActivitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type DocumentTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
  PostPaid?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type TaxEffectiveDateInput = {
  ID: Scalars['String'];
  PlatformID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTransferLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
};

export type EDocTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Array<ContactPersonInput>>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MeasurementInput = {
  ID: Scalars['String'];
  Type: Scalars['String'];
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type KeyCardInput = {
  s_data?: Maybe<Scalars['String']>;
  Operation?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['String']>;
  CheckOutDate?: Maybe<Scalars['String']>;
  encoder?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  menuOption?: Maybe<Scalars['String']>;
};

export type PermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
  hotelPerms?: Maybe<HotelPermission>;
};

/** These permissions are latest applicable for HMS */
export enum HotelPermission {
  BookingIgnoreView = 'BOOKING_IGNORE_______VIEW',
  BookingIgnoreCreate = 'BOOKING_IGNORE_______CREATE',
  BookingBookingInfoEdit = 'BOOKING_BOOKING_INFO_______EDIT',
  BookingRoomingListListView = 'BOOKING_ROOMING_LIST_LIST____VIEW',
  BookingRoomingListListCreate = 'BOOKING_ROOMING_LIST_LIST____CREATE',
  BookingRoomingListListEdit = 'BOOKING_ROOMING_LIST_LIST____EDIT',
  BookingRoomingListListDelete = 'BOOKING_ROOMING_LIST_LIST____DELETE',
  BookingRoomAssignmentListView = 'BOOKING_ROOM_ASSIGNMENT_LIST____VIEW',
  BookingRoomAssignmentListAssignment = 'BOOKING_ROOM_ASSIGNMENT_LIST____ASSIGNMENT',
  BookingSpecialRequestView = 'BOOKING_SPECIAL_REQUEST_______VIEW',
  BookingSpecialRequestCreate = 'BOOKING_SPECIAL_REQUEST_______CREATE',
  BookingAdvancePaymentInfoView = 'BOOKING_ADVANCE_PAYMENT_INFO____VIEW',
  BookingAdvancePaymentInfoCreate = 'BOOKING_ADVANCE_PAYMENT_INFO____CREATE',
  BookingAdvancePaymentInfoEdit = 'BOOKING_ADVANCE_PAYMENT_INFO____EDIT',
  BookingBillingInstructionView = 'BOOKING_BILLING_INSTRUCTION_______VIEW',
  BookingBillingInstructionCreate = 'BOOKING_BILLING_INSTRUCTION_______CREATE',
  BookingHouseLimitView = 'BOOKING_HOUSE_LIMIT_______VIEW',
  BookingHouseLimitCreate = 'BOOKING_HOUSE_LIMIT_______CREATE',
  BookingCancelBookingCancel = 'BOOKING_CANCEL_BOOKING_______CANCEL',
  BookingCancelRoomCancel = 'BOOKING_CANCEL_ROOM_______CANCEL',
  BookingConfirmLetterView = 'BOOKING_CONFIRM_LETTER_______VIEW',
  BookingBillingScheduleView = 'BOOKING_BILLING_SCHEDULE_______VIEW',
  BookingBillingScheduleEdit = 'BOOKING_BILLING_SCHEDULE_______EDIT',
  FrontDeskIgnoreView = 'FRONT_DESK_IGNORE_______VIEW',
  FrontDeskCheckInView = 'FRONT_DESK_CHECK_IN_______VIEW',
  FrontDeskCheckInWalkInCreate = 'FRONT_DESK_CHECK_IN_WALK_IN____CREATE',
  FrontDeskCheckInAssignment = 'FRONT_DESK_CHECK_IN_______ASSIGNMENT',
  FrontDeskCheckInCheckIn = 'FRONT_DESK_CHECK_IN_______CHECK_IN',
  FrontDeskCheckInBookingInfoView = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____VIEW',
  FrontDeskCheckInBookingInfoEdit = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____EDIT',
  FrontDeskCheckInRoomingListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST____VIEW',
  FrontDeskCheckInRoomingListListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_VIEW',
  FrontDeskCheckInRoomingListListEdit = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_EDIT',
  FrontDeskCheckInRoomingListListCreate = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_CREATE',
  FrontDeskCheckInRoomingListListDelete = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_DELETE',
  FrontDeskCheckInIncidentalChargesView = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckInIncidentalChargesCreate = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckInIncidentalChargesVoid = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckInDepositView = 'FRONT_DESK_CHECK_IN_DEPOSIT____VIEW',
  FrontDeskCheckInDepositListCreate = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_CREATE',
  FrontDeskCheckInDepositListRefund = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_REFUND',
  FrontDeskCheckInDepositListPrint = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_PRINT',
  FrontDeskCheckInAdvancePaymentView = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT____VIEW',
  FrontDeskCheckInAdvancePaymentListCreate = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskCheckInAdvancePaymentListRefund = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskCheckInAdvancePaymentListPrint = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskCheckInFolioView = 'FRONT_DESK_CHECK_IN_FOLIO____VIEW',
  FrontDeskCheckInFolioListView = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VIEW',
  FrontDeskCheckInFolioListSplit = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SPLIT',
  FrontDeskCheckInFolioListTransfer = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_TRANSFER',
  FrontDeskCheckInFolioListPrint = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_PRINT',
  FrontDeskCheckInFolioListCreate = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_CREATE',
  FrontDeskCheckInFolioListVoid = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VOID',
  FrontDeskCheckInSpecialRequestView = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____VIEW',
  FrontDeskCheckInSpecialRequestCreate = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____CREATE',
  FrontDeskCheckInBillingScheduleView = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckInCheckinCancelCancel = 'FRONT_DESK_CHECK_IN_CHECKIN_CANCEL____CANCEL',
  FrontDeskCheckInKeyCardView = 'FRONT_DESK_CHECK_IN_KEY_CARD____VIEW',
  FrontDeskCheckInKeyCardCreate = 'FRONT_DESK_CHECK_IN_KEY_CARD____CREATE',
  FrontDeskGroupCheckinView = 'FRONT_DESK_GROUP_CHECKIN_______VIEW',
  FrontDeskGroupCheckinListView = 'FRONT_DESK_GROUP_CHECKIN_LIST____VIEW',
  FrontDeskGroupCheckinListAssignment = 'FRONT_DESK_GROUP_CHECKIN_LIST____ASSIGNMENT',
  FrontDeskGroupCheckinListCheckIn = 'FRONT_DESK_GROUP_CHECKIN_LIST____CHECK_IN',
  FrontDeskCheckOutView = 'FRONT_DESK_CHECK_OUT_______VIEW',
  FrontDeskCheckOutDepositView = 'FRONT_DESK_CHECK_OUT_DEPOSIT____VIEW',
  FrontDeskCheckOutDepositListCreate = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_CREATE',
  FrontDeskCheckOutDepositListRefund = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_REFUND',
  FrontDeskCheckOutDepositListPrint = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_PRINT',
  FrontDeskCheckOutIncidentalChargesView = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckOutIncidentalChargesCreate = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckOutIncidentalChargesVoid = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckOutFolioView = 'FRONT_DESK_CHECK_OUT_FOLIO____VIEW',
  FrontDeskCheckOutFolioListView = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VIEW',
  FrontDeskCheckOutFolioListSplit = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SPLIT',
  FrontDeskCheckOutFolioListTransfer = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_TRANSFER',
  FrontDeskCheckOutFolioListPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_PRINT',
  FrontDeskCheckOutFolioListCreate = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_CREATE',
  FrontDeskCheckOutFolioListVoid = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VOID',
  FrontDeskCheckOutFolioHistoryView = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____VIEW',
  FrontDeskCheckOutFolioHistoryShare = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____SHARE',
  FrontDeskCheckOutFolioHistoryPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____PRINT',
  FrontDeskCheckOutBillingScheduleView = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____VIEW',
  FrontDeskRoomAssignmentView = 'FRONT_DESK_ROOM_ASSIGNMENT_______VIEW',
  FrontDeskRoomAssignmentAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_______ASSIGNMENT',
  FrontDeskRoomAssignmentAssignRoomAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_ASSIGN_ROOM____ASSIGNMENT',
  FrontDeskInhouseView = 'FRONT_DESK_INHOUSE_______VIEW',
  FrontDeskInhouseBookingInfoView = 'FRONT_DESK_INHOUSE_BOOKING_INFO____VIEW',
  FrontDeskInhouseBookingInfoEdit = 'FRONT_DESK_INHOUSE_BOOKING_INFO____EDIT',
  FrontDeskInhouseRoomingListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST____VIEW',
  FrontDeskInhouseCheckinCancelCancel = 'FRONT_DESK_INHOUSE_CHECKIN_CANCEL____CANCEL',
  FrontDeskInhouseRoomingListListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_VIEW',
  FrontDeskInhouseRoomingListListEdit = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_EDIT',
  FrontDeskInhouseRoomingListListCreate = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_CREATE',
  FrontDeskInhouseRoomingListListDelete = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_DELETE',
  FrontDeskInhouseServiceRequestView = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____VIEW',
  FrontDeskInhouseServiceRequestListCreate = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST_LIST_CREATE',
  FrontDeskInhouseServiceRequestEdit = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____EDIT',
  FrontDeskInhouseServiceRequestUpdateStatus = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____UPDATE_STATUS',
  FrontDeskInhouseIncidentalChargesView = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VIEW',
  FrontDeskInhouseIncidentalChargesCreate = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____CREATE',
  FrontDeskInhouseIncidentalChargesVoid = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VOID',
  FrontDeskInhouseDepositView = 'FRONT_DESK_INHOUSE_DEPOSIT____VIEW',
  FrontDeskInhouseDepositListCreate = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_CREATE',
  FrontDeskInhouseDepositListRefund = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_REFUND',
  FrontDeskInhouseDepositListPrint = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_PRINT',
  FrontDeskInhouseAdvancePaymentView = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT____VIEW',
  FrontDeskInhouseAdvancePaymentListCreate = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskInhouseAdvancePaymentListRefund = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskInhouseAdvancePaymentListPrint = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskInhouseFolioView = 'FRONT_DESK_INHOUSE_FOLIO____VIEW',
  FrontDeskInhouseFolioListView = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VIEW',
  FrontDeskInhouseFolioListSplit = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SPLIT',
  FrontDeskInhouseFolioListTransfer = 'FRONT_DESK_INHOUSE_FOLIO_LIST_TRANSFER',
  FrontDeskInhouseFolioListPrint = 'FRONT_DESK_INHOUSE_FOLIO_LIST_PRINT',
  FrontDeskInhouseFolioListCreate = 'FRONT_DESK_INHOUSE_FOLIO_LIST_CREATE',
  FrontDeskInhouseFolioListVoid = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VOID',
  FrontDeskInhouseEarlyCheckoutView = 'FRONT_DESK_INHOUSE_EARLY_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutView = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutCreate = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____CREATE',
  FrontDeskInhouseExtendStayView = 'FRONT_DESK_INHOUSE_EXTEND_STAY____VIEW',
  FrontDeskInhouseExtendStayCreate = 'FRONT_DESK_INHOUSE_EXTEND_STAY____CREATE',
  FrontDeskInhouseRateAdjustmentView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____VIEW',
  FrontDeskInhouseRateAdjustmentCreate = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____CREATE',
  FrontDeskInhouseRateAdjustmentListView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT__LIST_VIEW',
  FrontDeskInhouseRoomTransferView = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____VIEW',
  FrontDeskInhouseRoomTransferCreate = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____CREATE',
  FrontDeskInhouseRoomUpgradeView = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____VIEW',
  FrontDeskInhouseRoomUpgradeCreate = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____CREATE',
  FrontDeskInhouseFolioHistoryView = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____VIEW',
  FrontDeskInhouseFolioHistoryShare = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____SHARE',
  FrontDeskInhouseFolioHistoryPrint = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____PRINT',
  FrontDeskInhouseHouseLimitView = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____VIEW',
  FrontDeskInhouseHouseLimitCreate = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____CREATE',
  FrontDeskInhouseBillingScheduleView = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____VIEW',
  FrontDeskInhouseKeyCardView = 'FRONT_DESK_INHOUSE_KEY_CARD____VIEW',
  FrontDeskInhouseKeyCardCreate = 'FRONT_DESK_INHOUSE_KEY_CARD____CREATE',
  FrontDeskDepositView = 'FRONT_DESK_DEPOSIT_______VIEW',
  FrontDeskDepositListCreate = 'FRONT_DESK_DEPOSIT_LIST____CREATE',
  FrontDeskDepositListRefund = 'FRONT_DESK_DEPOSIT_LIST____REFUND',
  FrontDeskDepositListPrint = 'FRONT_DESK_DEPOSIT_LIST____PRINT',
  FrontDeskDepositListShare = 'FRONT_DESK_DEPOSIT_LIST____SHARE',
  FrontDeskServiceRequestView = 'FRONT_DESK_SERVICE_REQUEST_______VIEW',
  FrontDeskServiceRequestListCreate = 'FRONT_DESK_SERVICE_REQUEST_LIST____CREATE',
  FrontDeskServiceRequestListEdit = 'FRONT_DESK_SERVICE_REQUEST_LIST____EDIT',
  FrontDeskServiceRequestListUpdateStatus = 'FRONT_DESK_SERVICE_REQUEST_LIST____UPDATE_STATUS',
  FrontDeskServiceRequestListAssignment = 'FRONT_DESK_SERVICE_REQUEST_LIST____ASSIGNMENT',
  FrontDeskNonguestFolioView = 'FRONT_DESK_NONGUEST_FOLIO_______VIEW',
  FrontDeskNonguestFolioListCreate = 'FRONT_DESK_NONGUEST_FOLIO_LIST____CREATE',
  FrontDeskNonguestFolioListEdit = 'FRONT_DESK_NONGUEST_FOLIO_LIST____EDIT',
  FrontDeskNonguestFolioListFolio = 'FRONT_DESK_NONGUEST_FOLIO_LIST____FOLIO',
  FrontDeskNonguestFolioListFolioCharges = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskNonguestFolioListFolioPayment = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskNonguestFolioListFolioClose = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskNonguestFolioListFolioPrint = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskContactTracingView = 'FRONT_DESK_CONTACT_TRACING_______VIEW',
  FrontDeskContactTracingInfoView = 'FRONT_DESK_CONTACT_TRACING_INFO____VIEW',
  FrontDeskContactTracingInfoShare = 'FRONT_DESK_CONTACT_TRACING_INFO____SHARE',
  HouseKeepingIgnoreView = 'HOUSE_KEEPING_IGNORE_______VIEW',
  HouseKeepingBlockRoomView = 'HOUSE_KEEPING_BLOCK_ROOM_______VIEW',
  HouseKeepingBlockRoomEdit = 'HOUSE_KEEPING_BLOCK_ROOM_______EDIT',
  HouseKeepingRoomStatusView = 'HOUSE_KEEPING_ROOM_STATUS_______VIEW',
  HouseKeepingRoomStatusUpdateStatus = 'HOUSE_KEEPING_ROOM_STATUS_______UPDATE_STATUS',
  HouseKeepingServiceRequestView = 'HOUSE_KEEPING_SERVICE_REQUEST_______VIEW',
  HouseKeepingServiceRequestCreate = 'HOUSE_KEEPING_SERVICE_REQUEST_______CREATE',
  HouseKeepingServiceRequestEdit = 'HOUSE_KEEPING_SERVICE_REQUEST_______EDIT',
  HouseKeepingServiceRequestUpdateStatus = 'HOUSE_KEEPING_SERVICE_REQUEST_______UPDATE_STATUS',
  HouseKeepingIncidentalChargesView = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VIEW',
  HouseKeepingIncidentalChargesCreate = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______CREATE',
  HouseKeepingIncidentalChargesVoid = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VOID',
  ServiceRequestIgnoreView = 'SERVICE_REQUEST_IGNORE_______VIEW',
  ServiceRequestIgnoreEdit = 'SERVICE_REQUEST_IGNORE_______EDIT',
  ServiceRequestIgnoreCreate = 'SERVICE_REQUEST_IGNORE_______CREATE',
  ServiceRequestIgnoreUpdateStatus = 'SERVICE_REQUEST_IGNORE_______UPDATE_STATUS',
  ServiceRequestIgnoreAssignment = 'SERVICE_REQUEST_IGNORE_______ASSIGNMENT',
  PackagesRedemptionIgnoreView = 'PACKAGES_REDEMPTION_IGNORE_______VIEW',
  PackagesRedemptionIgnoreEdit = 'PACKAGES_REDEMPTION_IGNORE_______EDIT',
  CorpGovtIgnoreView = 'CORP_GOVT_IGNORE_______VIEW',
  CorpGovtPrivateCorporationView = 'CORP_GOVT_PRIVATE_CORPORATION_______VIEW',
  CorpGovtPrivateCorporationCreate = 'CORP_GOVT_PRIVATE_CORPORATION_______CREATE',
  CorpGovtPrivateCorporationActive = 'CORP_GOVT_PRIVATE_CORPORATION_______ACTIVE',
  CorpGovtPrivateCorporationDelete = 'CORP_GOVT_PRIVATE_CORPORATION_______DELETE',
  CorpGovtPrivateCorporationCompanyInfoView = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____VIEW',
  CorpGovtPrivateCorporationCompanyInfoEdit = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____EDIT',
  CorpGovtPrivateCorporationContractsView = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____VIEW',
  CorpGovtPrivateCorporationContractsCreate = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____CREATE',
  CorpGovtPrivateCorporationContractsEdit = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____EDIT',
  CorpGovtPrivateCorporationContractsActive = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____ACTIVE',
  CorpGovtPrivateCorporationContractsDelete = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____DELETE',
  CorpGovtPrivateCorporationBookingRecordsView = 'CORP_GOVT_PRIVATE_CORPORATION_BOOKING_RECORDS____VIEW',
  CorpGovtPrivateCorporationAnalysisView = 'CORP_GOVT_PRIVATE_CORPORATION_ANALYSIS____VIEW',
  CorpGovtGovtBodiesView = 'CORP_GOVT_GOVT_BODIES_______VIEW',
  CorpGovtGovtBodiesCreate = 'CORP_GOVT_GOVT_BODIES_______CREATE',
  CorpGovtGovtBodiesActive = 'CORP_GOVT_GOVT_BODIES_______ACTIVE',
  CorpGovtGovtBodiesDelete = 'CORP_GOVT_GOVT_BODIES_______DELETE',
  CorpGovtGovtBodiesCompanyInfoView = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____VIEW',
  CorpGovtGovtBodiesCompanyInfoEdit = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____EDIT',
  CorpGovtGovtBodiesContractsView = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____VIEW',
  CorpGovtGovtBodiesContractsCreate = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____CREATE',
  CorpGovtGovtBodiesContractsEdit = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____EDIT',
  CorpGovtGovtBodiesContractsActive = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____ACTIVE',
  CorpGovtGovtBodiesContractsDelete = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____DELETE',
  CorpGovtGovtBodiesBookingRecordsView = 'CORP_GOVT_GOVT_BODIES_BOOKING_RECORDS____VIEW',
  CorpGovtGovtBodiesAnalysisView = 'CORP_GOVT_GOVT_BODIES_ANALYSIS____VIEW',
  CorpGovtRatePolicyView = 'CORP_GOVT_RATE_POLICY_______VIEW',
  TravelAgentIgnoreView = 'TRAVEL_AGENT_IGNORE_______VIEW',
  TravelAgentAgentListingView = 'TRAVEL_AGENT_AGENT_LISTING_______VIEW',
  TravelAgentAgentListingCreate = 'TRAVEL_AGENT_AGENT_LISTING_______CREATE',
  TravelAgentAgentListingActive = 'TRAVEL_AGENT_AGENT_LISTING_______ACTIVE',
  TravelAgentAgentListingDelete = 'TRAVEL_AGENT_AGENT_LISTING_______DELETE',
  TravelAgentAgentListingCompanyInfoView = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____VIEW',
  TravelAgentAgentListingCompanyInfoEdit = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____EDIT',
  TravelAgentAgentListingContractsView = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____VIEW',
  TravelAgentAgentListingContractsCreate = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____CREATE',
  TravelAgentAgentListingContractsEdit = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____EDIT',
  TravelAgentAgentListingContractsActive = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____ACTIVE',
  TravelAgentAgentListingContractsDelete = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____DELETE',
  TravelAgentAgentListingBookingRecordsView = 'TRAVEL_AGENT_AGENT_LISTING_BOOKING_RECORDS____VIEW',
  TravelAgentAgentListingAnalysisView = 'TRAVEL_AGENT_AGENT_LISTING_ANALYSIS____VIEW',
  TravelAgentRatePolicyView = 'TRAVEL_AGENT_RATE_POLICY_______VIEW',
  GuestProfileIgnoreView = 'GUEST_PROFILE_IGNORE_______VIEW',
  GuestProfileIgnoreEdit = 'GUEST_PROFILE_IGNORE_______EDIT',
  DigitalMarketingIgnoreView = 'DIGITAL_MARKETING_IGNORE_______VIEW',
  DigitalMarketingPromoCodeView = 'DIGITAL_MARKETING_PROMO_CODE_______VIEW',
  DigitalMarketingPromoCodeCreate = 'DIGITAL_MARKETING_PROMO_CODE_______CREATE',
  DigitalMarketingPromoCodeEdit = 'DIGITAL_MARKETING_PROMO_CODE_______EDIT',
  DigitalMarketingPromoCodeActive = 'DIGITAL_MARKETING_PROMO_CODE_______ACTIVE',
  DigitalMarketingMarketingAdsView = 'DIGITAL_MARKETING_MARKETING_ADS_______VIEW',
  DigitalMarketingMarketingAdsCreate = 'DIGITAL_MARKETING_MARKETING_ADS_______CREATE',
  DigitalMarketingMarketingAdsEdit = 'DIGITAL_MARKETING_MARKETING_ADS_______EDIT',
  DigitalMarketingMarketingAdsShare = 'DIGITAL_MARKETING_MARKETING_ADS_______SHARE',
  DigitalMarketingMarketingAdsEmailBlasting = 'DIGITAL_MARKETING_MARKETING_ADS_______EMAIL_BLASTING',
  DigitalMarketingGuestRatingReviewView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_______VIEW',
  DigitalMarketingGuestRatingReviewInfoView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____VIEW',
  DigitalMarketingGuestRatingReviewInfoUpdateStatus = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____UPDATE_STATUS',
  DigitalMarketingGuestRatingReviewInfoShare = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____SHARE',
  RoomServiceIgnoreView = 'ROOM_SERVICE_IGNORE_______VIEW',
  RoomServiceIgnoreCreate = 'ROOM_SERVICE_IGNORE_______CREATE',
  RoomServiceIgnoreCancel = 'ROOM_SERVICE_IGNORE_______CANCEL',
  RoomServiceIgnoreUpdateStatus = 'ROOM_SERVICE_IGNORE_______UPDATE_STATUS',
  CheckoutAdjustmentIgnoreView = 'CHECKOUT_ADJUSTMENT_IGNORE_______VIEW',
  CheckoutAdjustmentIgnoreCreate = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREATE',
  CheckoutAdjustmentIgnoreBenificiary = 'CHECKOUT_ADJUSTMENT_IGNORE_______BENIFICIARY',
  CheckoutAdjustmentIgnoreCreditNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREDIT_NOTE',
  CheckoutAdjustmentIgnoreDebitNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______DEBIT_NOTE',
  CheckoutAdjustmentIgnoreChangePayment = 'CHECKOUT_ADJUSTMENT_IGNORE_______CHANGE_PAYMENT',
  CheckoutAdjustmentIgnoreShare = 'CHECKOUT_ADJUSTMENT_IGNORE_______SHARE',
  BusinessInsightIgnoreView = 'BUSINESS_INSIGHT_IGNORE_______VIEW',
  BusinessInsightDigitalReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_______VIEW',
  BusinessInsightDigitalReportCashierSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_SUMMARY____VIEW',
  BusinessInsightDigitalReportCashierDetailListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_DETAIL_LISTING____VIEW',
  BusinessInsightDigitalReportTransactionLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_LEDGER____VIEW',
  BusinessInsightDigitalReportBookingStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_STATUS____VIEW',
  BusinessInsightDigitalReportManagerReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT____VIEW',
  BusinessInsightDigitalReportProductionRoomNoView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_NO___VIEW',
  BusinessInsightDigitalReportProductionRateCodeeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_RATE_CODEE____VIEW',
  BusinessInsightDigitalReportProductionRoomTypeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_TYPE____VIEW',
  BusinessInsightDigitalReportProductionCorpGovView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_CORP_GOV____VIEW',
  BusinessInsightDigitalReportProductionSegmentView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SEGMENT____VIEW',
  BusinessInsightDigitalReportProductionSourceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SOURCE____VIEW',
  BusinessInsightDigitalReportProductionStateView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_STATE____VIEW',
  BusinessInsightDigitalReportPackageListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PACKAGE_LISTING____VIEW',
  BusinessInsightDigitalReportRoomStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS____VIEW',
  BusinessInsightDigitalReportInhouseGuestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_INHOUSE_GUEST____VIEW',
  BusinessInsightDigitalReportSecurityDepositView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SECURITY_DEPOSIT____VIEW',
  BusinessInsightDigitalReportRoomDiscrepancyView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_DISCREPANCY____VIEW',
  BusinessInsightDigitalReportTourismTaxView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TOURISM_TAX____VIEW',
  BusinessInsightDigitalReportTransactionCancelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_CANCEL____VIEW',
  BusinessInsightDigitalReportServiceRequestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SERVICE_REQUEST____VIEW',
  BusinessInsightDigitalReportGuestBalanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_BALANCE____VIEW',
  BusinessInsightDigitalReportOccupiedView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OCCUPIED____VIEW',
  BusinessInsightDigitalReportGroupReservationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION____VIEW',
  BusinessInsightDigitalReportRoomAvailabilityView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_AVAILABILITY____VIEW',
  BusinessInsightDigitalReportDebtorLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER____VIEW',
  BusinessInsightDigitalReportDebtorLedgerDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorAgingSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_SUMMARY____VIEW',
  BusinessInsightDigitalReportDebtorAgingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_DETAIL____VIEW',
  BusinessInsightDigitalReportStatementOfAccountView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT____VIEW',
  BusinessInsightDigitalReportHistoricalForecastView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_HISTORICAL_FORECAST____VIEW',
  BusinessInsightDigitalReportRoomMaintenanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_MAINTENANCE____VIEW',
  BusinessInsightDigitalDocumentView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_______VIEW',
  BusinessInsightDigitalDocumentRegistrationCardView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____VIEW',
  BusinessInsightDigitalDocumentRegistrationCardShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____SHARE',
  BusinessInsightDigitalDocumentRegistrationCardPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____PRINT',
  BusinessInsightDigitalDocumentFolioView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____VIEW',
  BusinessInsightDigitalDocumentFolioShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____SHARE',
  BusinessInsightDigitalDocumentFolioPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____PRINT',
  BusinessInsightDigitalDocumentReceiptView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____VIEW',
  BusinessInsightDigitalDocumentReceiptShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____SHARE',
  BusinessInsightDigitalDocumentReceiptPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____PRINT',
  BusinessInsightAnalyticsView = 'BUSINESS_INSIGHT_ANALYTICS_______VIEW',
  BusinessInsightAnalyticsHotelStatusView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATUS____VIEW',
  BusinessInsightAnalyticsRevenueAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsSourceAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SOURCE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsCountryAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_COUNTRY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsNationalityAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_NATIONALITY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_ANALYSIS____VIEW',
  BusinessInsightAnalyticsMarketingPersonnalView = 'BUSINESS_INSIGHT_ANALYTICS_MARKETING_PERSONNAL____VIEW',
  BusinessInsightAnalyticsRevenueByRoomtypeView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_ROOMTYPE____VIEW',
  BusinessInsightAnalyticsOnlineTravelAgentView = 'BUSINESS_INSIGHT_ANALYTICS_ONLINE_TRAVEL_AGENT____VIEW',
  BusinessInsightAnalyticsSegmentAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SEGMENT_ANALYSIS____VIEW',
  BusinessInsightAnalyticsRevenueByTransactionView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_TRANSACTION____VIEW',
  BusinessInsightAnalyticsServiceRequestView = 'BUSINESS_INSIGHT_ANALYTICS_SERVICE_REQUEST____VIEW',
  BusinessInsightAnalyticsMaidProductivityView = 'BUSINESS_INSIGHT_ANALYTICS_MAID_PRODUCTIVITY____VIEW',
  BusinessInsightAnalyticsRatingReviewAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_RATING_REVIEW_ANALYSIS____VIEW',
  BusinessInsightAnalyticsStateAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_STATE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorLedgerStatusView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_LEDGER_STATUS____VIEW',
  BusinessInsightAnalyticsTotalAgingView = 'BUSINESS_INSIGHT_ANALYTICS_TOTAL_AGING____VIEW',
  BusinessInsightAnalyticsAmountDueByAgingView = 'BUSINESS_INSIGHT_ANALYTICS_AMOUNT_DUE_BY_AGING____VIEW',
  BusinessInsightAnalyticsCustomerByRevenueView = 'BUSINESS_INSIGHT_ANALYTICS_CUSTOMER_BY_REVENUE____VIEW',
  BusinessInsightAnalyticsCollectionByDebtortypeView = 'BUSINESS_INSIGHT_ANALYTICS_COLLECTION_BY_DEBTORTYPE____VIEW',
  HotelSettingsIgnoreView = 'HOTEL_SETTINGS_IGNORE_______VIEW',
  HotelSettingsHotelSetupView = 'HOTEL_SETTINGS_HOTEL_SETUP_______VIEW',
  HotelSettingsHotelSetupProfileView = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____VIEW',
  HotelSettingsHotelSetupProfileEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____EDIT',
  HotelSettingsHotelSetupAboutView = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____VIEW',
  HotelSettingsHotelSetupAboutEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____EDIT',
  HotelSettingsHotelSetupGalleryView = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____VIEW',
  HotelSettingsHotelSetupGalleryEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____EDIT',
  HotelSettingsHotelSetupFacilitiesView = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____VIEW',
  HotelSettingsHotelSetupFacilitiesEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____EDIT',
  HotelSettingsHotelSetupFacilitiesQrCodePrint = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES_QR_CODE_PRINT',
  HotelSettingsDepartmentView = 'HOTEL_SETTINGS_DEPARTMENT_______VIEW',
  HotelSettingsDepartmentCreate = 'HOTEL_SETTINGS_DEPARTMENT_______CREATE',
  HotelSettingsDepartmentIncidentalChargesView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____VIEW',
  HotelSettingsDepartmentIncidentalChargesCreate = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____CREATE',
  HotelSettingsDepartmentIncidentalChargesActive = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____ACTIVE',
  HotelSettingsDepartmentIncidentalChargesDelete = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____DELETE',
  HotelSettingsDepartmentIncidentalChargesQrCodeView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_VIEW',
  HotelSettingsDepartmentIncidentalChargesQrCodePrint = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_PRINT',
  HotelSettingsDepartmentReasonView = 'HOTEL_SETTINGS_DEPARTMENT_REASON____VIEW',
  HotelSettingsDepartmentReasonCreate = 'HOTEL_SETTINGS_DEPARTMENT_REASON____CREATE',
  HotelSettingsDepartmentReasonEdit = 'HOTEL_SETTINGS_DEPARTMENT_REASON____EDIT',
  HotelSettingsDepartmentReasonDelete = 'HOTEL_SETTINGS_DEPARTMENT_REASON____DELETE',
  HotelSettingsDepartmentSalesChannelView = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____VIEW',
  HotelSettingsDepartmentSalesChannelCreate = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____CREATE',
  HotelSettingsDepartmentSalesChannelEdit = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____EDIT',
  HotelSettingsDepartmentSalesChannelDelete = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____DELETE',
  HotelSettingsLocationView = 'HOTEL_SETTINGS_LOCATION_______VIEW',
  HotelSettingsLocationCreate = 'HOTEL_SETTINGS_LOCATION_______CREATE',
  HotelSettingsLocationEdit = 'HOTEL_SETTINGS_LOCATION_______EDIT',
  HotelSettingsRoomTypeView = 'HOTEL_SETTINGS_ROOM_TYPE_______VIEW',
  HotelSettingsRoomTypeCreate = 'HOTEL_SETTINGS_ROOM_TYPE_______CREATE',
  HotelSettingsRoomTypeEdit = 'HOTEL_SETTINGS_ROOM_TYPE_______EDIT',
  HotelSettingsRoomTypeActive = 'HOTEL_SETTINGS_ROOM_TYPE_______ACTIVE',
  HotelSettingsRoomView = 'HOTEL_SETTINGS_ROOM_______VIEW',
  HotelSettingsRoomListView = 'HOTEL_SETTINGS_ROOM_LIST____VIEW',
  HotelSettingsRoomListCreate = 'HOTEL_SETTINGS_ROOM_LIST____CREATE',
  HotelSettingsRoomListEdit = 'HOTEL_SETTINGS_ROOM_LIST____EDIT',
  HotelSettingsRoomListActive = 'HOTEL_SETTINGS_ROOM_LIST____ACTIVE',
  HotelSettingsRoomListDelete = 'HOTEL_SETTINGS_ROOM_LIST____DELETE',
  HotelSettingsRoomStatusView = 'HOTEL_SETTINGS_ROOM_STATUS_______VIEW',
  HotelSettingsRoomStatusEdit = 'HOTEL_SETTINGS_ROOM_STATUS_______EDIT',
  HotelSettingsRatePolicyView = 'HOTEL_SETTINGS_RATE_POLICY_______VIEW',
  HotelSettingsRatePolicySeasonSetupView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____VIEW',
  HotelSettingsRatePolicySeasonSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____CREATE',
  HotelSettingsRatePolicySeasonSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____EDIT',
  HotelSettingsRatePolicySeasonSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____ACTIVE',
  HotelSettingsRatePolicySeasonCalendarView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____VIEW',
  HotelSettingsRatePolicySeasonCalendarEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____EDIT',
  HotelSettingsRatePolicyRateElementView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____VIEW',
  HotelSettingsRatePolicyRateElementCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____CREATE',
  HotelSettingsRatePolicyRateElementEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____EDIT',
  HotelSettingsRatePolicyRateElementActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____ACTIVE',
  HotelSettingsRatePolicyAddOnView = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____VIEW',
  HotelSettingsRatePolicyAddOnCreate = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____CREATE',
  HotelSettingsRatePolicyAddOnEdit = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____EDIT',
  HotelSettingsRatePolicyAddOnActive = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____ACTIVE',
  HotelSettingsRatePolicyRateTypeView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____VIEW',
  HotelSettingsRatePolicyRateTypeCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____CREATE',
  HotelSettingsRatePolicyRateTypeEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____EDIT',
  HotelSettingsRatePolicyRateTypeActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____ACTIVE',
  HotelSettingsRatePolicyRateSetupView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____VIEW',
  HotelSettingsRatePolicyRateSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____CREATE',
  HotelSettingsRatePolicyRateSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____EDIT',
  HotelSettingsRatePolicyRateSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____ACTIVE',
  HotelSettingsRatePolicyRateSetupValidityPeriodView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_VIEW',
  HotelSettingsRatePolicyRateSetupValidityPeriodCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_CREATE',
  HotelSettingsRatePolicyRateSetupValidityPeriodEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_EDIT',
  HotelSettingsRatePolicyRateSetupValidityPeriodActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_ACTIVE',
  HotelSettingsRoomSaleOptView = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______VIEW',
  HotelSettingsRoomSaleOptEdit = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______EDIT',
  HotelSettingsStandardPolicyView = 'HOTEL_SETTINGS_STANDARD_POLICY_______VIEW',
  HotelSettingsStandardPolicyHotelOperationalView = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____VIEW',
  HotelSettingsStandardPolicyHotelOperationalEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____EDIT',
  HotelSettingsStandardPolicySecurityDepositView = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____VIEW',
  HotelSettingsStandardPolicySecurityDepositEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE____VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_EDIT',
  HotelSettingsStandardPolicyRoomStatusView = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____VIEW',
  HotelSettingsStandardPolicyRoomStatusEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____EDIT',
  HotelSettingsStandardPolicyCeillingFloorPriceView = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____VIEW',
  HotelSettingsStandardPolicyCeillingFloorPriceEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____EDIT',
  HotelSettingsLatecheckoutChargeView = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______VIEW',
  HotelSettingsLatecheckoutChargeEdit = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______EDIT',
  HotelSettingsSegmentView = 'HOTEL_SETTINGS_SEGMENT_______VIEW',
  HotelSettingsSegmentCreate = 'HOTEL_SETTINGS_SEGMENT_______CREATE',
  HotelSettingsSegmentEdit = 'HOTEL_SETTINGS_SEGMENT_______EDIT',
  HotelSettingsSegmentActive = 'HOTEL_SETTINGS_SEGMENT_______ACTIVE',
  CityLedgerIgnoreView = 'CITY_LEDGER_IGNORE_______VIEW',
  CityLedgerScheduleBillingView = 'CITY_LEDGER_SCHEDULE_BILLING_______VIEW',
  CityLedgerScheduleBillingPost = 'CITY_LEDGER_SCHEDULE_BILLING_______POST',
  CityLedgerAccountTransactionView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_______VIEW',
  CityLedgerAccountTransactionInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____VIEW',
  CityLedgerAccountTransactionInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CREATE',
  CityLedgerAccountTransactionInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____EDIT',
  CityLedgerAccountTransactionInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____DRAFT',
  CityLedgerAccountTransactionInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____SUBMIT',
  CityLedgerAccountTransactionInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____PRINT',
  CityLedgerAccountTransactionDebitNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____VIEW',
  CityLedgerAccountTransactionDebitNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CREATE',
  CityLedgerAccountTransactionDebitNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____EDIT',
  CityLedgerAccountTransactionDebitNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____DRAFT',
  CityLedgerAccountTransactionDebitNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionDebitNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionDebitNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____PRINT',
  CityLedgerAccountTransactionCreditNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____VIEW',
  CityLedgerAccountTransactionCreditNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CREATE',
  CityLedgerAccountTransactionCreditNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____EDIT',
  CityLedgerAccountTransactionCreditNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____DRAFT',
  CityLedgerAccountTransactionCreditNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionCreditNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionCreditNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____PRINT',
  CityLedgerAccountTransactionOfficialReceiptView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____VIEW',
  CityLedgerAccountTransactionOfficialReceiptCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CREATE',
  CityLedgerAccountTransactionOfficialReceiptEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____EDIT',
  CityLedgerAccountTransactionOfficialReceiptDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____DRAFT',
  CityLedgerAccountTransactionOfficialReceiptSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____SUBMIT',
  CityLedgerAccountTransactionOfficialReceiptAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____ALLOCATE',
  CityLedgerAccountTransactionOfficialReceiptPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____PRINT',
  CityLedgerAccountTransactionRefundView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____VIEW',
  CityLedgerAccountTransactionRefundCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CREATE',
  CityLedgerAccountTransactionRefundEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____EDIT',
  CityLedgerAccountTransactionRefundDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____DRAFT',
  CityLedgerAccountTransactionRefundSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____SUBMIT',
  CityLedgerAccountTransactionRefundAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____ALLOCATE',
  CityLedgerAccountTransactionRefundPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____PRINT',
  CityLedgerSetupView = 'CITY_LEDGER_SETUP_______VIEW',
  CityLedgerSetupDebtorTypeView = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____VIEW',
  CityLedgerSetupDebtorTypeCreate = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____CREATE',
  CityLedgerSetupDebtorTypeEdit = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____EDIT',
  CityLedgerSetupDebtorTypeDelete = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____DELETE',
  CityLedgerSetupDebtorProfileView = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____VIEW',
  CityLedgerSetupDebtorProfileCreate = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____CREATE',
  CityLedgerSetupDebtorProfileEdit = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____EDIT',
  CityLedgerSetupDebtorProfileActive = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____ACTIVE',
  CityLedgerCreditControlView = 'CITY_LEDGER_CREDIT_CONTROL_______VIEW',
  CityLedgerStatementOfAccView = 'CITY_LEDGER_STATEMENT_OF_ACC_______VIEW',
  CityLedgerStatementOfAccShare = 'CITY_LEDGER_STATEMENT_OF_ACC_______SHARE',
  CityLedgerAccountTransactionInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CANCEL',
  CityLedgerAccountTransactionDebitNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CANCEL',
  CityLedgerAccountTransactionCreditNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CANCEL',
  CityLedgerAccountTransactionOfficialReceiptCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CANCEL',
  CityLedgerAccountTransactionRefundCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CANCEL',
  AcctJournalIgnoreView = 'ACCT_JOURNAL_IGNORE_______VIEW',
  AcctJournalAcctMappingHotelGlView = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____VIEW',
  AcctJournalAcctMappingHotelGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____EDIT',
  AcctJournalAcctMappingCityGlView = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____VIEW',
  AcctJournalAcctMappingCityGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____EDIT',
  AcctJournalAcctMappingBankAcctView = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____VIEW',
  AcctJournalAcctMappingBankAcctCreate = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____CREATE',
  AcctJournalAcctMappingBankAcctEdit = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____EDIT',
  AcctJournalAcctMappingBankAcctDelete = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____DELETE',
  AcctJournalGlJournalView = 'ACCT_JOURNAL_GL_JOURNAL_______VIEW',
  AcctJournalGlJournalCreate = 'ACCT_JOURNAL_GL_JOURNAL_______CREATE',
  AcctJournalGlJournalDownload = 'ACCT_JOURNAL_GL_JOURNAL_______DOWNLOAD',
  AcctJournalGlJournalShare = 'ACCT_JOURNAL_GL_JOURNAL_______SHARE',
  AcctJournalGlJournalDetailView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____VIEW',
  AcctJournalGlJournalDetailDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalGlJournalDetailShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____SHARE',
  AcctJournalGlJournalDetailIndividualView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalGlJournalDetailIndividualDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalGlJournalDetailIndividualShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalCbJournalView = 'ACCT_JOURNAL_CB_JOURNAL_______VIEW',
  AcctJournalCbJournalCreate = 'ACCT_JOURNAL_CB_JOURNAL_______CREATE',
  AcctJournalCbJournalDownload = 'ACCT_JOURNAL_CB_JOURNAL_______DOWNLOAD',
  AcctJournalCbJournalShare = 'ACCT_JOURNAL_CB_JOURNAL_______SHARE',
  AcctJournalCbJournalDetailView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____VIEW',
  AcctJournalCbJournalDetailDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalCbJournalDetailShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____SHARE',
  AcctJournalCbJournalDetailIndividualView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalCbJournalDetailIndividualDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalCbJournalDetailIndividualShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalAcctMappingView = 'ACCT_JOURNAL_ACCT_MAPPING_______VIEW',
  FrontDeskInhouseBillingInstructionView = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____VIEW',
  FrontDeskInhouseBillingInstructionCreate = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckOutBillingInstructionView = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckOutBillingInstructionCreate = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckInBillingInstructionView = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckInBillingInstructionCreate = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____CREATE'
}

export type EntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ListEntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<AddressInput>;
  superUser?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type Rates = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
};

export type CoreLedgerInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type DebitNoteEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type CreditNoteEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type RefundEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type InvoiceEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type OfficialReceiptEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type ReminderEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type InterestEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus?: Maybe<ArTransactionStatus>;
  IsWaived: Scalars['Boolean'];
};

export type CoreTrxLedgerInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type InvoiceTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitAllocationInput = {
  CreditID?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  CreditRefTable?: Maybe<Scalars['String']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type CreditAllocationInput = {
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type ScheduleBillingInput = {
  BookingID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  FolioAmount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
};

export type AssignRoomInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export type HouseLimitInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type DepositInput = {
  FolioType: FolioType;
  FolioAmount: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};

export type RefundDepositInput = {
  DepositID: Scalars['String'];
  FolioID: Scalars['String'];
  RefundAmount: Scalars['Float'];
  ForfeitureAmount?: Maybe<Scalars['Float']>;
  Remarks: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};

export type FrontDeskPaymentInput = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  PaymentType?: Maybe<PaymentType>;
  Amount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CheckOutID?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<PaymentClass>;
  TransactionType?: Maybe<TransactionType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  RoomLedgerID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  LedgerTypeID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ModuleName?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
};

export type RoomUpgradeInput = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  OldRoomID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  FreeUpgrade?: Maybe<Scalars['Boolean']>;
};

export type PaymentFolioInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
};

export type PromoCodeInput = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
};

export type EmailBodyInput = {
  Body: Scalars['String'];
  Header: Scalars['String'];
};

export type GlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  GL?: Maybe<GuestLedgerAcctJsonInput>;
};

export type AccountMappingGlInput = {
  Charges?: Maybe<Array<GlInput>>;
  Payment?: Maybe<Array<GlInput>>;
  Refund?: Maybe<Array<GlInput>>;
  Deposit?: Maybe<Array<GlInput>>;
  AdvancePayment?: Maybe<Array<GlInput>>;
  OfficialReceipt?: Maybe<Array<GlInput>>;
};

export type GuestLedgerAcctInput = {
  Div?: Maybe<Scalars['String']>;
  Dept?: Maybe<Scalars['String']>;
  Acct?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type DebtorPaymentInput = {
  InvoiceID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  EntRoleUsrAsgList: Array<EntRoleUsrAsgEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  HotelAuthorizationInfoLine: Scalars['JSON'];
  getHotelPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetMenuOption: RoleEntity;
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  RoundingAdjustment: Scalars['JSON'];
  SubscribedPackages: Scalars['JSON'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  updateLastestAccessHotel: UserEntity;
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  InterstTotal: InterestResponse;
  TestService: Scalars['String'];
  HLSInvetoryUpdate: Scalars['String'];
  ArrayChallenge: Scalars['JSON'];
  DebitNoteDetail: DebitNoteEntity;
  DebitNoteListings: Array<DebitNoteEntity>;
  CreditNoteListingsOld: Array<CreditNoteEntity>;
  CreditNoteListings: Scalars['JSON'];
  RefundDetail: RefundEntity;
  RefundListings: Array<RefundEntity>;
  InvoiceDetail: InvoiceEntity;
  PostedScheduleBilling: InvoiceEntity;
  InvoiceListings: Array<InvoiceEntity>;
  CityLedgerInvoiceListings: Scalars['JSON'];
  ARInvoiceListings: Array<InvoiceEntity>;
  StatementOfAccDocument: DocResponse;
  OfficialReceiptDetail: OfficialReceiptEntity;
  OfficialReceiptListingsOld: Array<OfficialReceiptEntity>;
  OfficialReceiptListings: Scalars['JSON'];
  ReminderListing: ReminderEntity;
  ReminderListings: Array<ReminderEntity>;
  InterestListing: InterestEntity;
  InterestListings: Array<ReminderEntity>;
  DebtorAgingListing: Scalars['JSON'];
  AgingByDebtor: Scalars['JSON'];
  MonthlyStatementListing: Scalars['JSON'];
  OpeningClosingAmount: OpenClosedResponse;
  OpeningClosingAmountV2: Scalars['JSON'];
  DebtorListing: Array<DebtorEntity>;
  DebtorDetail: DebtorEntity;
  InvoiceInfo: ArSummaryInfo;
  DebitNoteInfo: ArSummaryInfo;
  CreditNoteInfo: ArSummaryInfo;
  OfficialReceiptInfo: ArSummaryInfo;
  RefundInfo: ArSummaryInfo;
  ARAccountSummary: ArAccountSummary;
  ARTotalOutstanding: ArAccountOsLastPayment;
  ARTotalDue: Scalars['Float'];
  ARAgingAnalysis: ArAgingAnalysisRespose;
  TotalAgingBI: Scalars['String'];
  DocumentNumberHeaderListing: Array<DocumentNumberHeaderEntity>;
  DebtorTypeListing: Array<DebtorTypeEntity>;
  DebtorTypeInUse: Scalars['Boolean'];
  ScheduleBillingList: Array<ScheduleBillingResponse>;
  RoomSaleOptimizationListing: Array<RoomSaleOptimizationOutput>;
  FrontDeskListing: Array<BookingEntity>;
  AssignRoomFrontDeskListing: Array<BookingEntity>;
  RoomAssignListing: Array<RoomEntity>;
  AllRoomAsignListing: Array<RoomEntity>;
  GuestProfile: GuestProfileEntity;
  BookingProfile: BookingEntity;
  SelectFolio: FolioEntity;
  FolioDetails: FolioEntity;
  FolioDetailsWithIDs: RegistrationEntity;
  DepositListingByBooking: Array<DepositLedgerEntity>;
  UnutilizedDepositListing?: Maybe<DepositResponse>;
  UtilizedDepositListing?: Maybe<DepositResponse>;
  DepositRefundDetails: DepositLedgerEntity;
  FrontDeskPaymentListing: Array<FrontDeskResponse>;
  BillingByRegistration: Array<PaymentFoilioResponse>;
  IsRoomAvailable: Scalars['Boolean'];
  FrontDeskIncidentalChargeListing: Array<BillLedgerEntity>;
  FrontDeskServiceRequestListing: Array<ServiceRequestEntity>;
  ActiveBookingRoomListing: Array<RoomEntity>;
  PrincipalGuestListing: Array<RegistrationEntity>;
  FolioListingByRegistration: Array<FolioEntity>;
  FolioHistoryListing: Array<FolioHistoryResponse>;
  FolioListing: Array<FolioEntity>;
  NonGuestFolioListing: Array<FolioEntity>;
  NonGuestBillingListing: Array<NonGuestFolioResponse>;
  SelectedRegistration: RegistrationEntity;
  ReceiptListing: Array<PaymentFoilioResponse>;
  PaymentDetail: PaymentEntity;
  AdvancePaymentDetail: AdvancePaymentEntity;
  FrontDeskInfoLines: FrontDeskInfoLineResponse;
  ContactPagination: Array<ContactEntity>;
  RegistrationListing: Array<RegistrationEntity>;
  CurrentTaxDetails: CurrentTaxDetailsOutput;
  FrontDeskBookingInfo: BookingInfoResponse;
  HotelXCredentials: Scalars['JSON'];
  MenuXInhouseList: Scalars['JSON'];
  MenuXDebtorList: Scalars['JSON'];
  HotelsByCity: Array<HotelEntity>;
  HotelPricesByDateRange: HotelEntity;
  loggedInGuestProfile: GuestProfileEntity;
  GuestBookingListing: Array<BookingEntity>;
  DisCountByPromoCode: Array<PromoCodeResponse>;
  GuestRoomServiceListing: Array<BillLedgerEntity>;
  GAInHouseGuestListing: Array<RegistrationEntity>;
  HotelReviewListing: Array<HotelRatingEntity>;
  GAMarketingAdsListing: Array<EmailCampignEntity>;
  IsGuestAppUser: GuestProfileEntity;
  PromotionListing: Array<PromotionEntity>;
  PromotionDetailsInUseListing: Scalars['Boolean'];
  PromotionDetailListing: Array<PromotionDetailEntity>;
  EmailMarketingListing: Array<EmailCampignEntity>;
  EmailMarketingDetail: Array<EmailCampignEntity>;
  PromotionInUseListing: Scalars['Boolean'];
  GuestReviewListing: Array<HotelRatingEntity>;
  GuestReviewInfoLines: GuestReviewInfoLineResponse;
  TaxEffectiveDateListing: Array<TaxEffectiveDateEntity>;
  PlatformHeritageTaxListing: PlatformHeritageTaxEntity;
  TaxInUseListing: Scalars['Boolean'];
  ComputeTaxbyHotel: TaxOutput;
  ComputeTaxArrByHotel: Array<TaxOutput>;
  ComputeTaxbyTaxType: HotelTaxOutput;
  GetLevyTax: CurrentTaxOutput;
  GetServiceChargeTax: CurrentTaxOutput;
  GetCurrentTourismTax: CurrentTaxOutput;
  GetPlatformTax: CurrentTaxOutput;
  GetCurrentGovTax: CurrentTaxOutput;
  TaxPolicyListing: Array<HotelTaxPolicyEntity>;
  BookingListing: Array<BookingEntity>;
  BookingDetails: BookingEntity;
  AdvancePaymentListing: Array<AdvancePaymentEntity>;
  InhousePaymentListing: Array<PaymentEntity>;
  RoomServiceBookingListing: Array<BookingEntity>;
  RoomNumberForFoodListing: Array<BookingEntity>;
  AvailabilityListing: HotelAvailabilityOutput;
  PackagePriceListing: Array<PackagePriceOutput>;
  ChangeOfDateCheckAvailability: Scalars['Boolean'];
  ShowBookingConfirmationEmail: Scalars['String'];
  CurrentInhouseList: Array<CurrentInhouseOutput>;
  CurrentInhouseListV7: Scalars['JSON'];
  BillScheduleListByBooking: Scalars['JSON'];
  BillScheduleListing: Scalars['JSON'];
  OAuthHotelX: Scalars['JSON'];
  InventoryRatePlansHotelX: HotelEntity;
  GuestReviewsHotelX: Array<HotelRatingEntity>;
  PromotionListHotelX: Array<PromotionEntity>;
  PromoDiscHotelX: Array<PromoCodeResponse>;
  HotelTaxDetails: CurrentTaxDetailsOutput;
  ComputeTaxHotelX: TaxOutput;
  BIBillLedgerListing: Array<BillLedgerEntity>;
  BITransactionCancellation: Array<BillLedgerEntity>;
  BIDepositLedgerListing: Array<BookingEntity>;
  BIHotelListing: HotelEntity;
  BIAnalysis: Array<BookingEntity>;
  BISource: Array<BookingEntity>;
  BISourceV2: Scalars['JSON'];
  BITop10Country: Array<BookingEntity>;
  BITop10CountryV2: Scalars['JSON'];
  BITop10Nationality: Array<BookingEntity>;
  BITop10NationalityV2: Scalars['JSON'];
  BICorpGovTa: Array<BookingEntity>;
  BICorpGovTaV2: Scalars['JSON'];
  BIMarketingPersonnal: Array<BookingEntity>;
  BIMarketingPersonnalV2: Scalars['JSON'];
  BIRoomTypeRevenueDetail: Array<BookingEntity>;
  BIRoomTypeRevenueDetailV2: Scalars['JSON'];
  BITop5OTA: Array<BookingEntity>;
  BITop5OTAV2: Scalars['JSON'];
  BISegment: Array<BookingEntity>;
  BISegmentV2: Scalars['JSON'];
  BITop10Revenue: Array<BookingEntity>;
  BITop10RevenueV2: Scalars['JSON'];
  TotalOverdueByDebtorType: Scalars['JSON'];
  DebtorAging: Scalars['JSON'];
  TotalDueAging: Scalars['JSON'];
  Top10DebtorRevenue: Scalars['JSON'];
  CollectionByDebtorTypeVSOverdue: Scalars['JSON'];
  BusinessAnalyticInfoLine: Scalars['JSON'];
  CountItem: RegAndBooking;
  BIRoomRateListing: Array<BookingEntity>;
  BIUsersLisiting: UserEntity;
  HotelStateAnalysis: Scalars['JSON'];
  StateAnalysisRateAndRoom: Array<BookingEntity>;
  BIInHouse: Array<RegistrationEntity>;
  BiAdvPaymentListing: Array<AdvancePaymentEntity>;
  BIPaymentListing: Array<PaymentTestClass>;
  PaymentDetails: PaymentEntity;
  DepositClass: DepositLedgerEntity;
  AdvancePaymentDetails: AdvancePaymentEntity;
  RegistrationDetails: RegistrationEntity;
  BIPaymentListingTest: Array<PaymentListing123>;
  BIRegistrationListing: Array<BookingEntity>;
  BIHotelDetails: HotelEntity;
  BIRoomListing: Array<RoomEntity>;
  BIServiceRequestListing: Array<ServiceRequestEntity>;
  BIRevenueListing: Array<RevenueAnalysis>;
  BIYTDRevenue: RevenueAnalysis;
  BICorpGovTAYTDRevenue: RevenueAnalysis;
  BISourceYTDRevenue: RevenueAnalysis;
  BITopCountry: Array<RevenueAnalysis>;
  BITopNationality: Array<RevenueAnalysis>;
  BIMarketingPersonnalTopSales: RevenueAnalysis;
  BIRoomTypeRevenue: RevenueAnalysis;
  BITravelAgent: Array<RevenueAnalysis>;
  BISegmentRevenue: RevenueAnalysis;
  BITopTransaction: RevenueAnalysis;
  BIServiceRequest: Array<RevenueAnalysis>;
  BIForeignListing: Array<BookingEntity>;
  BISecurityKeyCardDeposit: Array<DepositLedgerEntity>;
  BIRoomDiscrepancyLog: Array<RoomDiscrepancyLogEntity>;
  BIRoomStatus: Array<RoomEntity>;
  BIBookingStatus: Array<BookingEntity>;
  BIFolioListing: Array<FolioEntity>;
  BIAdvancePaymentListing: Array<AdvancePaymentEntity>;
  BITestListing: Array<FolioOutput>;
  BITransactionLedger: Array<BookingEntity>;
  BIPackage: Array<BillScheduleEntity>;
  ManagerReport: ManagerReportOutput;
  ManagerReportDepartment: ManagerReportOutput;
  CorporateGovernmentListing: Array<DebtorEntity>;
  GovernmentListing: Array<DebtorEntity>;
  CorporateBookingListing: Array<BookingEntity>;
  CorporateGovernmentDetail: DebtorEntity;
  CorporateContractListing: Array<ContractEntity>;
  ContractDetails: ContractEntity;
  CorporateInUseListing: Scalars['Boolean'];
  CoporateInUseListing: Scalars['Boolean'];
  ActivitiesListing: Array<ActivitiesEntity>;
  DashBoardAllListing: DashBoardRespond;
  DashBoardListing: Array<BookingEntity>;
  AdvancePaymentFullListing: Array<AdvancePaymentEntity>;
  PaymentListing: Array<PaymentEntity>;
  RoomServicesCount: Scalars['Float'];
  HotelStatisticListing: HotelStatisticRespond;
  HotelStatisticListingV2: Scalars['JSON'];
  DocumentTemplateList: DocumentTemplateEntity;
  GuestProfileListing: Array<BookingEntity>;
  GuestProfileListingByID: Array<RegistrationEntity>;
  SelectedGuestListing: Array<GuestProfileEntity>;
  GuestHistoryListing: Array<RegistrationEntity>;
  GuestProfileHistoryListing: Array<RegistrationEntity>;
  GuestProfileListbyRoom: Array<GuestProfileEntity>;
  SearchGuest?: Maybe<Array<SearchGuestResponse>>;
  GuestMovementList?: Maybe<Scalars['JSON']>;
  GuestMovementListing: Array<GuestMovementEntity>;
  GuestListingByLocationTime: Array<GuestMovementEntity>;
  CBExport: Scalars['JSON'];
  ExportedCBListing: Scalars['JSON'];
  ExportedCBDetails: Array<Scalars['JSON']>;
  CBExportDetailsByTrxType: Scalars['JSON'];
  ExportedGLListing: Scalars['JSON'];
  ExportedGLDetails: Array<Scalars['JSON']>;
  GLExport: Scalars['JSON'];
  GLExportDetailsByTrxType: Scalars['JSON'];
  RoomServiceFolioListing: Array<FolioEntity>;
  PaymentRoomServiceDetail: PaymentEntity;
  BillLedgerFullListing: Array<BillLedgerEntity>;
  PaymentBillLedgerListing: Array<BillLedgerEntity>;
  PaymentRegistrationListing: RegistrationEntity;
  checkoutfoliodeposiit: Array<FolioEntity>;
  CheckedOutFolioListing: Scalars['JSON'];
  AdjustedFolioDetails: Array<FolioEntity>;
  CheckoutAdjustmentListing: Array<FolioEntity>;
  SearchFolio?: Maybe<FolioEntity>;
  RoomServiceListing: Array<BillLedgerEntity>;
  DeliveredRoomServiceListing: Array<BillLedgerEntity>;
  RoomServiceRegistrationListing: Array<RegistrationEntity>;
  DeliveredRoomServiceRegistrationListing: Array<RegistrationEntity>;
  FolioChargeToList: Scalars['JSON'];
  PackageRedemptionListing: Array<PackageRedemptionListOutput>;
  PackageRedemptionByItem: Array<PackageRedemptionListOutput>;
  HousekeepingListing: Array<RoomEntity>;
  TodayBookingListing: Array<BookingEntity>;
  HousekeepingDetail: RoomEntity;
  HousekeepingServiceRequestListing: Array<ServiceRequestEntity>;
  HousekeepingRoomStatusListing: Array<RoomStatusEntity>;
  BillLedgerListing: Array<BillLedgerEntity>;
  HousekeepingIncidentalChargeListing: Array<IncidentalChargeEntity>;
  HKMasterListing: Scalars['JSON'];
  ServiceRequestDetail: Array<ServiceRequestEntity>;
  ServiceRequestFullListing: Array<ServiceRequestEntity>;
  ServiceRequestListing: Array<ServiceRequestEntity>;
  ServiceRequestv2Listing: Array<ServiceRequestEntity>;
  SrLocationListing: Array<LocationEntity>;
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentInUseListing: Scalars['Boolean'];
  IncidentalDepartmentListing: Array<DepartmentEntity>;
  EDocTemplateListing: Array<EDocTemplateEntity>;
  EDocParamsListing: Array<EDocParamsEntity>;
  AccountMappingChargesList: Scalars['JSON'];
  AccountMappingPaymentList: Scalars['JSON'];
  AccountMappingAdvPaymentList: Scalars['JSON'];
  AccountMappingRefundList: Scalars['JSON'];
  AccountMappingDepositList: Scalars['JSON'];
  CityLedgerChargesList: Scalars['JSON'];
  CityLedgerDebtorList: Scalars['JSON'];
  BankAccountListing: Array<BankAccountEntity>;
  HotelListing: Array<HotelEntity>;
  MenuXHotelListing: Array<HotelEntity>;
  HotelDetails: HotelEntity;
  IsPaymentGateWayHotel: Scalars['Boolean'];
  CustomizedDocListing: Array<CustomizedDocumentEntity>;
  IncidentalChargeListing: Array<IncidentalChargeEntity>;
  IncidentalChargeInUseListing: Scalars['Boolean'];
  IncidentalChargeListingByHotel: Array<IncidentalChargeEntity>;
  LateCheckOutChargeListing: Array<LateCheckOutChargeEntity>;
  LocationListing: Array<LocationEntity>;
  LocationInUseListing: Scalars['Boolean'];
  RoomInUseListing: Scalars['Boolean'];
  RoomInHouseListing: Scalars['Boolean'];
  RoomBlockRoomListing: Scalars['Boolean'];
  HKLocationMasterListing: Scalars['JSON'];
  MeasurementListing: Array<MeasurementEntity>;
  PlatformAmenitiesListing: Array<PlatformAmenitiesEntity>;
  PlatformDepartmentListing: Array<PlatformDepartmentEntity>;
  RateElementListing: Array<RateElementEntity>;
  RateAddOnListing: Array<RateAddOnEntity>;
  RateTypeListing: Array<RateTypeEntity>;
  RateListing: Array<RateEntity>;
  RateInHouseListing: Scalars['Boolean'];
  RateEffectiveListing: Array<RateEffectiveEntity>;
  RatePolicyInfo: RatePolicyResponse;
  RateAdjustmentAuditListing: Array<RateAdjustmentAuditEntity>;
  IsRateInUse: Scalars['Boolean'];
  SeasonListing: Array<SeasonEntity>;
  SeasonCalendarListing: Array<SeasonCalendarEntity>;
  RatePolicyListing: Array<RateEntity>;
  RatePolicyRoomTypeListing: Array<RoomTypeEntity>;
  RatePolicyDetails: RatePolicyEntity;
  RatePolicyInUseListing: Scalars['Boolean'];
  ReasonListing: Array<ReasonEntity>;
  CancelReasonListing: Array<ReasonEntity>;
  ReaonInUseListing: Scalars['Boolean'];
  RoomListing: Array<RoomEntity>;
  BlockRoomLogListing: Scalars['Boolean'];
  HotelRoomNumberList: Array<RoomEntity>;
  RoomStatusListing: Array<RoomStatusEntity>;
  RoomTypeAmenitiesListing: Array<RoomTypeAmenitiesEntity>;
  RoomTypeListing: Array<RoomTypeEntity>;
  RegistrationRoomTypeListing: Scalars['Boolean'];
  SalesChannelListing: Array<SalesChannelEntity>;
  HotelSalesChannelListing: Array<SalesChannelEntity>;
  SalesChannelinUseListing: Scalars['Boolean'];
  SegmentListing: Array<SegmentEntity>;
  SegmentInUseListing: Scalars['Boolean'];
  TravelAgentHotelListing: Array<HotelEntity>;
  TravelAgentListing: Array<DebtorEntity>;
  AgentInUseLisiting: Scalars['Boolean'];
  TravelAgentDetail: DebtorEntity;
  PMSTravelAgentDetail: DebtorEntity;
  TravelAgentContractListing: Array<ContractEntity>;
  TravelAgBookingListing: Array<BookingEntity>;
  TravelAgentInUseListing: Scalars['Boolean'];
  AllotmentListing: Array<ContractAllotmentEntity>;
  loggedInDebtorProfile: DebtorProfileEntity;
  DebtorAnalysis: Array<ContractEntity>;
  TravelAgentRoomRevenue: Array<RegistrationEntity>;
};


export type QueryEntRoleUsrAsgListArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  hotelID: Scalars['String'];
};


export type QueryHotelAuthorizationInfoLineArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryRoundingAdjustmentArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QuerySubscribedPackagesArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetUsersByAccountArgs = {
  status?: Maybe<CommonStatus>;
  superUser?: Maybe<Scalars['Boolean']>;
  LatetestUser?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryUpdateLastestAccessHotelArgs = {
  hotelID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryHlsInvetoryUpdateArgs = {
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  RateIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArrayChallengeArgs = {
  arr: Array<Scalars['Float']>;
};


export type QueryDebitNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebitNoteListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsOldArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundDetailArgs = {
  ID: Scalars['String'];
};


export type QueryRefundListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPostedScheduleBillingArgs = {
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryInvoiceListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCityLedgerInvoiceListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArInvoiceListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryStatementOfAccDocumentArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptDetailArgs = {
  ID: Scalars['String'];
};


export type QueryOfficialReceiptListingsOldArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptListingsArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryReminderListingArgs = {
  ID: Scalars['String'];
};


export type QueryInterestListingArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorAgingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAgingByDebtorArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryMonthlyStatementListingArgs = {
  ID: Scalars['String'];
};


export type QueryOpeningClosingAmountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryOpeningClosingAmountV2Args = {
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryDebtorListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorDetailArgs = {
  ID: Scalars['String'];
};


export type QueryInvoiceInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryDebitNoteInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCreditNoteInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryOfficialReceiptInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryRefundInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAccountSummaryArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryArTotalOutstandingArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArTotalDueArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAgingAnalysisArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryTotalAgingBiArgs = {
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryDocumentNumberHeaderListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDebtorTypeInUseArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryScheduleBillingListArgs = {
  DebtorTypeID?: Maybe<Scalars['String']>;
  DocEndDate?: Maybe<Scalars['DateTime']>;
  DocStartDate?: Maybe<Scalars['DateTime']>;
  DebtorAcc?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRoomSaleOptimizationListingArgs = {
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingArgs = {
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryAssignRoomFrontDeskListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomAssignListingArgs = {
  IsBooking?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAllRoomAsignListingArgs = {
  IsBooking?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileArgs = {
  GuestID: Scalars['String'];
};


export type QueryBookingProfileArgs = {
  ID: Scalars['String'];
};


export type QuerySelectFolioArgs = {
  ID: Scalars['String'];
};


export type QueryFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryFolioDetailsWithIDsArgs = {
  LedgerIDs?: Maybe<LedgerIDsInput>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingByBookingArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepositRefundDetailsArgs = {
  HotelID: Scalars['String'];
  DepositID: Scalars['String'];
};


export type QueryFrontDeskPaymentListingArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBillingByRegistrationArgs = {
  IsHMS?: Maybe<Scalars['Boolean']>;
  RegistrationID: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryIsRoomAvailableArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryFrontDeskIncidentalChargeListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryFrontDeskServiceRequestListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryActiveBookingRoomListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPrincipalGuestListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryFolioListingByRegistrationArgs = {
  AccountID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryFolioHistoryListingArgs = {
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryFolioListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestFolioListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestBillingListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type QuerySelectedRegistrationArgs = {
  ID: Scalars['String'];
};


export type QueryReceiptListingArgs = {
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
};


export type QueryPaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']>>;
};


export type QueryAdvancePaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryContactPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryRegistrationListingArgs = {
  RoomID: Scalars['String'];
};


export type QueryCurrentTaxDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskBookingInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelXCredentialsArgs = {
  isHotel?: Maybe<Scalars['Boolean']>;
  Authorization: Scalars['String'];
};


export type QueryMenuXInhouseListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryMenuXDebtorListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryHotelsByCityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DebtorType?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  NoOfGuests?: Maybe<Scalars['Float']>;
  NoOfRooms?: Maybe<Scalars['Float']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  City: Scalars['String'];
};


export type QueryHotelPricesByDateRangeArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDisCountByPromoCodeArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomServiceListingArgs = {
  ServiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryGaInHouseGuestListingArgs = {
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryHotelReviewListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryGaMarketingAdsListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsGuestAppUserArgs = {
  Login?: Maybe<Scalars['String']>;
};


export type QueryPromotionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionDetailsInUseListingArgs = {
  PromoCode: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryPromotionDetailListingArgs = {
  PromotionID: Scalars['String'];
};


export type QueryEmailMarketingListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryEmailMarketingDetailArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionInUseListingArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGuestReviewListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  Rating?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestReviewInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryTaxInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryComputeTaxbyHotelArgs = {
  input: TaxInput;
};


export type QueryComputeTaxArrByHotelArgs = {
  TaxInput: Array<TaxInput>;
};


export type QueryComputeTaxbyTaxTypeArgs = {
  HotelID: Scalars['String'];
  input: TaxType;
};


export type QueryGetLevyTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetServiceChargeTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentTourismTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetPlatformTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentGovTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryTaxPolicyListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
};


export type QueryBookingDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryInhousePaymentListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryRoomServiceBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomNumberForFoodListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAvailabilityListingArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  Adddate?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPackagePriceListingArgs = {
  NoOfRoom?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityArgs = {
  Reinstate?: Maybe<Scalars['Boolean']>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryShowBookingConfirmationEmailArgs = {
  BookingID: Scalars['String'];
};


export type QueryCurrentInhouseListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCurrentInhouseListV7Args = {
  HotelID: Scalars['String'];
};


export type QueryBillScheduleListByBookingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryBillScheduleListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryOAuthHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
  Secret: Scalars['String'];
};


export type QueryInventoryRatePlansHotelXArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ClientID: Scalars['String'];
};


export type QueryGuestReviewsHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
};


export type QueryPromotionListHotelXArgs = {
  ClientID: Scalars['String'];
};


export type QueryPromoDiscHotelXArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  ClientID: Scalars['String'];
};


export type QueryHotelTaxDetailsArgs = {
  ClientID: Scalars['String'];
};


export type QueryComputeTaxHotelXArgs = {
  input: TaxInput;
};


export type QueryBiBillLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionCancellationArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiDepositLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelListingArgs = {
  ID: Scalars['String'];
};


export type QueryBiAnalysisArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailV2Args = {
  Mode?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop5OtaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop5Otav2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSegmentArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSegmentV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalOverdueByDebtorTypeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalDueAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTop10DebtorRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCollectionByDebtorTypeVsOverdueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBusinessAnalyticInfoLineArgs = {
  HotelID: Scalars['String'];
};


export type QueryCountItemArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomRateListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiUsersLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryHotelStateAnalysisArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryStateAnalysisRateAndRoomArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiInHouseArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiAdvPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDepositClassArgs = {
  ReversedID: Scalars['String'];
};


export type QueryAdvancePaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryRegistrationDetailsArgs = {
  GuestID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBiPaymentListingTestArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRegistrationListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestListingArgs = {
  Department?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRevenueListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSourceYtdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopCountryArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopNationalityArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalTopSalesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTravelAgentArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSegmentRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopTransactionArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiForeignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSecurityKeyCardDepositArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomDiscrepancyLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomStatusArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryBiBookingStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiFolioListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiAdvancePaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTestListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionLedgerArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiPackageArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryManagerReportArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryManagerReportDepartmentArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGovernmentListingArgs = {
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCorporateBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryContractDetailsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCorporateInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCoporateInUseListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryActivitiesListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDashBoardAllListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDashBoardListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAdvancePaymentFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServicesCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingArgs = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingV2Args = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDocumentTemplateListArgs = {
  ID: Scalars['String'];
};


export type QueryGuestProfileListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingByIdArgs = {
  RegistrationIDs: Array<Scalars['String']>;
};


export type QuerySelectedGuestListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestProfileHistoryListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListbyRoomArgs = {
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QuerySearchGuestArgs = {
  GuestName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestMovementListArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
};


export type QueryGuestMovementListingArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  HotelID: Scalars['String'];
};


export type QueryGuestListingByLocationTimeArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  CheckInDate: Scalars['DateTime'];
  GuestID: Scalars['String'];
  RefID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCbExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedCbListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedCbDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryCbExportDetailsByTrxTypeArgs = {
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedGlListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedGlDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryGlExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGlExportDetailsByTrxTypeArgs = {
  TrxDate: Scalars['DateTime'];
  TrxDesc: Scalars['String'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryRoomServiceFolioListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentRoomServiceDetailArgs = {
  RegistrationID: Scalars['String'];
  OrderID: Scalars['String'];
};


export type QueryBillLedgerFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentBillLedgerListingArgs = {
  FolioID: Scalars['String'];
};


export type QueryPaymentRegistrationListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryCheckoutfoliodeposiitArgs = {
  HotelID: Scalars['String'];
};


export type QueryCheckedOutFolioListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  FullName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryAdjustedFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryCheckoutAdjustmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySearchFolioArgs = {
  FolioNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryFolioChargeToListArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPackageRedemptionListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPackageRedemptionByItemArgs = {
  ComputationRule: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHousekeepingListingArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryTodayBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHousekeepingServiceRequestListingArgs = {
  RoomID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBillLedgerListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryHkMasterListingArgs = {
  IsBlocked?: Maybe<Scalars['Boolean']>;
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryServiceRequestDetailArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestListingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryServiceRequestv2ListingArgs = {
  Status?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySrLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepartmentInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryEDocTemplateListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryEDocParamsListingArgs = {
  DocType: Scalars['String'];
};


export type QueryAccountMappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryBankAccountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryMenuXHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryHotelDetailsArgs = {
  CorporateURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsPaymentGateWayHotelArgs = {
  HotelID: Scalars['String'];
};


export type QueryCustomizedDocListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeListingByHotelArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryLateCheckOutChargeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationInUseListingArgs = {
  LocationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomInHouseListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomBlockRoomListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHkLocationMasterListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRateElementListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAddOnListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateListingArgs = {
  Category?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRateInHouseListingArgs = {
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateEffectiveListingArgs = {
  RateID: Scalars['String'];
};


export type QueryRatePolicyInfoArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAdjustmentAuditListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsRateInUseArgs = {
  RateEffectiveID: Scalars['String'];
  RateID: Scalars['String'];
};


export type QuerySeasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonCalendarListingArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRatePolicyListingArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyRoomTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRatePolicyDetailsArgs = {
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInUseListingArgs = {
  DebtorCategory: Scalars['String'];
  RatePolicyID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryReasonListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryReaonInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelRoomNumberListArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomTypeListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRegistrationRoomTypeListingArgs = {
  RoomTypeID: Scalars['String'];
};


export type QuerySalesChannelListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  DepartmentID: Scalars['String'];
};


export type QueryHotelSalesChannelListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySalesChannelinUseListingArgs = {
  ID: Scalars['String'];
};


export type QuerySegmentListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QuerySegmentInUseListingArgs = {
  SegmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentHotelListingArgs = {
  DebtorCategory: Scalars['String'];
  Login: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryTravelAgentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAgentInUseLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryTravelAgentDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPmsTravelAgentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgBookingListingArgs = {
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryAllotmentListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  ContractID: Scalars['String'];
};


export type QueryDebtorAnalysisArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentRoomRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  HotelID: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  MenuOptionUpdate: Scalars['Boolean'];
  NotificationInsert: Scalars['Boolean'];
  OnlinePayment?: Maybe<CheckOut>;
  RefundPayment: Scalars['Boolean'];
  sendRegisterOTP: Scalars['Boolean'];
  verifyOTP: Scalars['Boolean'];
  UploadImage: Array<Scalars['String']>;
  DeleteImage: Scalars['Boolean'];
  createUser: UserEntity;
  createPassword: Scalars['Boolean'];
  updateUser: UserEntity;
  updateProfile: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: UserEntity;
  logout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  unLockUser: Scalars['Boolean'];
  reSendActivationEmail: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createSuperUser: UserEntity;
  HLSBookingNotification: Scalars['Boolean'];
  InsertInterestAmt: Scalars['Boolean'];
  UpdateInterestAmt: Scalars['Float'];
  ManualNightAuditforAllHotel: Scalars['Boolean'];
  genHashPassword: Scalars['JSON'];
  RegenerateBillSchedule: Scalars['Boolean'];
  ManualHotelNA: Scalars['Boolean'];
  ManualAvailabilityUpdate: Scalars['String'];
  KeyCardInsert: Scalars['Boolean'];
  DebitNoteInsert: DebitNoteEntity;
  DebitNoteUpdate: Scalars['Boolean'];
  DebitNoteCancel: Scalars['Boolean'];
  AllocateDebitNote: Scalars['Boolean'];
  DebitNoteDelete: Scalars['Boolean'];
  CreditNoteInsert: CreditNoteEntity;
  CreditNoteUpdate: Scalars['Boolean'];
  CreditNoteCancel: Scalars['Boolean'];
  AllocateCreditNote: Scalars['Boolean'];
  CreditNoteDelete: Scalars['Boolean'];
  RefundInsert: RefundEntity;
  RefundUpdate: Scalars['Boolean'];
  RefundCancel: Scalars['Boolean'];
  AllocateRefund: Scalars['Boolean'];
  RefundDelete: Scalars['Boolean'];
  InvoiceInsert: InvoiceEntity;
  InvoiceUpdate: Scalars['Boolean'];
  InvoiceCancel: Scalars['Boolean'];
  AllocateInvoice: Scalars['Boolean'];
  InvoiceDelete: Scalars['Boolean'];
  OfficialReceiptInsert: OfficialReceiptEntity;
  OfficialReceiptUpdate: Scalars['Boolean'];
  OfficialReceiptCancel: Scalars['Boolean'];
  AllocateOfficialReceipt: Scalars['Boolean'];
  OfficialReceiptDelete: Scalars['Boolean'];
  ReminderInsert: ReminderEntity;
  ReminderUpdate: Scalars['Boolean'];
  ReminderDelete: Scalars['Boolean'];
  asyncInterestInsert: InterestEntity;
  InterestUpdate: Scalars['Boolean'];
  InterestDelete: Scalars['Boolean'];
  DocumentNumberingSetupInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DebtorTypeInsert: DebtorTypeEntity;
  DebtorTypeUpdate: Scalars['Boolean'];
  DebtorTypeDelete: Scalars['Boolean'];
  PostScheduleBilling: Scalars['Boolean'];
  RoomSaleOptimizationUpdate: Scalars['Boolean'];
  MigrationAPI: Scalars['Boolean'];
  SpecialReqUpdate: Scalars['Boolean'];
  AssignRoom: Scalars['Boolean'];
  CheckIn: Scalars['Boolean'];
  TransferRoom: Scalars['Boolean'];
  GuestInsert: Scalars['Boolean'];
  DepositInsert: Scalars['Boolean'];
  RefundDeposit: Scalars['Boolean'];
  CloseFolio: Scalars['Boolean'];
  VoidLedger: Scalars['Boolean'];
  VoidBillSchedule: Scalars['Boolean'];
  RegistrationSignatureUpdate: RegistrationEntity;
  VoidBillLedger: Scalars['Boolean'];
  PosV7VoidBillLedger: Scalars['Boolean'];
  FrontDeskPaymentInsert: PaymentEntity;
  CheckOut: Scalars['Boolean'];
  WalkIngBookingInsert: Scalars['JSON'];
  LateCheckOutInsert: Scalars['String'];
  ExtendStayInsert: Scalars['JSON'];
  ShortenStayInsert: Scalars['String'];
  RoomUpGradeInsert: Scalars['String'];
  NonGuestFolioInsert: Scalars['Boolean'];
  NonGuestFolioUpdate: Scalars['Boolean'];
  NonGuestVoidLedger: Scalars['Boolean'];
  NonGuestPaymentInsert: PaymentEntity;
  NonGuestCloseFolio: Scalars['Boolean'];
  SplitPayment: Scalars['Boolean'];
  SplitPaymentV2: Scalars['Boolean'];
  TransferPayment: Scalars['Boolean'];
  RegistrationSign: Scalars['Boolean'];
  PaymentUpdate: Scalars['Boolean'];
  CancelCheckIn: Scalars['Boolean'];
  FolioBeneficiaryNameUpdate: Scalars['Boolean'];
  loadTestUAT: Scalars['Boolean'];
  GuestRegister: Scalars['Boolean'];
  GuestLogin: GuestProfileEntity;
  GuestChangePassword: GuestProfileEntity;
  GuestForgotPassword: Scalars['Boolean'];
  GuestResetPassword: Scalars['Boolean'];
  OnlineBookingInsert: Scalars['String'];
  GuestRoomServiceInsert: Scalars['String'];
  HotelRatingInsert: HotelRatingEntity;
  PromotionUpdate: Scalars['Boolean'];
  PromotionInsert: PromotionEntity;
  PromotionActiveUpdate: Scalars['Boolean'];
  PromotionDetailUpdate: Scalars['Boolean'];
  PromotionDetailInsert: PromotionDetailEntity;
  EmailCampignUpdate: Scalars['Boolean'];
  EmailCampignInsert: EmailCampignEntity;
  EmailCampaign: Scalars['Boolean'];
  PromotionDelete: Scalars['Boolean'];
  EmailDelete: Scalars['Boolean'];
  GuestReviewActiveUpdate: Scalars['Boolean'];
  HotelTaxPolicyInsert: Scalars['Boolean'];
  HotelTaxPolicyUpdate: Scalars['Boolean'];
  HotelTaxPolicyDelete: Scalars['Boolean'];
  BookingInsert: Scalars['String'];
  GuestAppRoomTypeDelete: Scalars['Boolean'];
  BookingRoomTypeUpdate: Scalars['Boolean'];
  HouseLimitUpdate: Scalars['Boolean'];
  BookingUpdate: Scalars['Boolean'];
  CancelBooking: Scalars['Boolean'];
  AdvancePaymentInsert: AdvancePaymentEntity;
  AdvancePaymentRefund: Scalars['Boolean'];
  AdvancePaymentUpdate: Scalars['Boolean'];
  ReinstateBooking: Scalars['Boolean'];
  ImageUpload: GalleryEntity;
  ManualNightAudit: Scalars['Boolean'];
  AvailabilityUpdate: Scalars['Boolean'];
  AtestInsert: Scalars['Boolean'];
  BookingRoomAssignment: Scalars['Boolean'];
  BillScheduleAdjustment: Scalars['Boolean'];
  BookingUpdateV2: Scalars['Boolean'];
  CancelRoom: Scalars['Boolean'];
  ReinstateRoom: Scalars['Boolean'];
  EmailSend: Scalars['Boolean'];
  BISharedGAlleryInsert: SharedGalleryEntity;
  CorporateInsert: DebtorEntity;
  GovermentTestingUpdate: Scalars['Boolean'];
  CorporateUpdate: Scalars['Boolean'];
  CorporateContractUpdate: Scalars['Boolean'];
  CorporateContractInsert: ContractEntity;
  CorporateDelete: Scalars['Boolean'];
  ActivityInsert: Scalars['Boolean'];
  ActivityUpdate: Scalars['Boolean'];
  GuestUpdate: Scalars['Boolean'];
  GuestProfileInsert: RegistrationEntity;
  GuestContactUpdate: Scalars['Boolean'];
  GuestProfileDelete: Scalars['Boolean'];
  NoOfPaxUpdate: Scalars['Boolean'];
  GuestMovementInsert: Scalars['Boolean'];
  CBPost: Scalars['Boolean'];
  GLPost: Scalars['Boolean'];
  BillLedgerUpdate: Scalars['Boolean'];
  BillLedgerCancel: Scalars['Boolean'];
  BillLedgerInsert: Scalars['Boolean'];
  PosV7BillLedgerInsert: Scalars['Boolean'];
  FolioInsert: Scalars['Boolean'];
  RoomServicePaymentUpdate: Scalars['Boolean'];
  OnlinePaymentRoomServices: OnlineItemEntity;
  FolioAdjustmentInsert: PaymentEntity;
  ChangePayment: Scalars['Boolean'];
  POSChargeToCityLedger: Scalars['Boolean'];
  POSV7ScheduleBilling: Scalars['Boolean'];
  HuaweiOCR?: Maybe<Scalars['JSON']>;
  Innov8OCR?: Maybe<Scalars['JSON']>;
  PackageRedemptionInsert: Scalars['Boolean'];
  BlockRoomLogInsert: BlockRoomLogEntity;
  ReleaseRoomLogUpdate: Scalars['Boolean'];
  BlockRoomLogUpdate: Scalars['Boolean'];
  HouseKeepingLogInsert: Scalars['Boolean'];
  HousekeepingServiceRequestInsert: ServiceRequestEntity;
  HousekeepingServiceRequestUpdate: Scalars['Boolean'];
  HousekeepingBillLedgerInsert: BillLedgerEntity;
  HousekeepingBillLedgerUpdate: Scalars['Boolean'];
  DepartmentInsert: Scalars['Boolean'];
  DepartmentUpdate: Scalars['Boolean'];
  IncidentalChargesUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  EDocInsert: Scalars['Boolean'];
  EDocTemplateUpdate: Scalars['Boolean'];
  AccountMappingGLInsert: Scalars['Boolean'];
  CityLedgerGLInsert: Scalars['Boolean'];
  BankAccountUpdate: Scalars['Boolean'];
  BankAccountInsert: BankAccountEntity;
  BankAccountDelete: Scalars['Boolean'];
  HotelUpdate: Scalars['Boolean'];
  HotelInsert: HotelEntity;
  HotelContactUpdate: Scalars['Boolean'];
  HotelContactInsert: ContactEntity;
  HotelFacilityUpdate: Scalars['Boolean'];
  HotelFacilityInsert: FacilityEntity;
  HotelNewsUpdate: Scalars['Boolean'];
  HotelNewsInsert: NewsEntity;
  IncidentalChargeInsert: Scalars['Boolean'];
  IncidentalChargeUpdate: Scalars['Boolean'];
  IncidentalChargeDelete: Scalars['Boolean'];
  LateCheckOutChargeInsert: Scalars['Boolean'];
  LocationInsert: LocationEntity;
  LocationUpdate: Scalars['Boolean'];
  DisplaySequenceUpdate: Scalars['Boolean'];
  LocationDelete: Scalars['Boolean'];
  RateElementInsert: RateElementEntity;
  RateElementUpdate: Scalars['Boolean'];
  RateAddOnInsert: RateAddOnEntity;
  RateAddOnUpdate: Scalars['Boolean'];
  RateTypeInsert: RateTypeEntity;
  RateTypeUpdate: Scalars['Boolean'];
  RateInsert: RateEntity;
  RateUpdate: Scalars['Boolean'];
  RateEffectiveInsert: Scalars['Boolean'];
  RateEffectiveUpdate: Scalars['Boolean'];
  CMSRateSync: Scalars['Boolean'];
  RateEffectiveStatusUpdate: Scalars['Boolean'];
  RateAdjustmentAuditInsert: Scalars['Boolean'];
  SeasonInsert: SeasonEntity;
  SeasonUpdate: Scalars['Boolean'];
  SeasonCalendarUpdate: Scalars['Boolean'];
  RatePolicyInsert: RatePolicyEntity;
  RatePolicyUpdate: Scalars['Boolean'];
  PackageInsert: Scalars['Boolean'];
  PackageUpdate: Scalars['Boolean'];
  RatePolicyDelete: Scalars['Boolean'];
  ReasonInsert: Scalars['Boolean'];
  ReasonUpdate: Scalars['Boolean'];
  ReasonDelete: Scalars['Boolean'];
  RoomInsert: RoomEntity;
  RoomUpdate: Scalars['Boolean'];
  RoomDelete: Scalars['Boolean'];
  RoomStatusInsert: Scalars['Boolean'];
  RoomTypeUpdate: Scalars['Boolean'];
  RoomTypeInsert: RoomTypeEntity;
  RoomTypeDelete: Scalars['Boolean'];
  SalesChannelInsert: Scalars['Boolean'];
  SalesChannelUpdate: Scalars['Boolean'];
  SalesChannelDelete: Scalars['Boolean'];
  SegmentUpdate: Scalars['Boolean'];
  SegmentInsert: SegmentEntity;
  SegmentDelete: Scalars['Boolean'];
  TravelAgentInsert: DebtorEntity;
  TravelAgentMainUpdate: Scalars['Boolean'];
  TravelAgentUpdate: Scalars['Boolean'];
  TravelAgentContractUpdate: Scalars['Boolean'];
  TravelAgentContractInsert: ContractEntity;
  UpdateAllotment: Scalars['Boolean'];
  TravelAgentDelete: Scalars['Boolean'];
  DebtorLogin: DebtorProfileEntity;
  DebtorLastAccessedHotelUpdate: Scalars['Boolean'];
  DebtorChangePassword: DebtorProfileEntity;
  DebtorForgotPassword: Scalars['Boolean'];
  AgentDelete: Scalars['Boolean'];
  DebtorResetPassword: Scalars['Boolean'];
  DebtorPaymentInsert: Scalars['Boolean'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntRoleUsrAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  hotelID: Scalars['String'];
};


export type MutationMenuOptionUpdateArgs = {
  input: RoleInput;
};


export type MutationOnlinePaymentArgs = {
  ReceiptNo?: Maybe<Scalars['String']>;
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationRefundPaymentArgs = {
  RefundAmount: Scalars['Float'];
  HotelID: Scalars['String'];
  input: PaymentInput;
};


export type MutationSendRegisterOtpArgs = {
  OtpType: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationVerifyOtpArgs = {
  OtpType: Scalars['String'];
  OtpCode: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationUploadImageArgs = {
  FileInput: Array<Scalars['Upload']>;
};


export type MutationDeleteImageArgs = {
  OldGalleryID: Array<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateProfileArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUnLockUserArgs = {
  password: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationReSendActivationEmailArgs = {
  userID: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationHlsBookingNotificationArgs = {
  BookingId: Scalars['String'];
  HotelId: Scalars['String'];
};


export type MutationUpdateInterestAmtArgs = {
  InterestRate: Scalars['Float'];
};


export type MutationManualNightAuditforAllHotelArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationRegenerateBillScheduleArgs = {
  HotelID: Scalars['String'];
};


export type MutationManualHotelNaArgs = {
  Action: Scalars['String'];
  EndDate: Scalars['DateTime'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualAvailabilityUpdateArgs = {
  RoomTypeID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  days: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type MutationKeyCardInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  input: KeyCardInput;
};


export type MutationDebitNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
};


export type MutationDebitNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationDebitNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateDebitNoteArgs = {
  allocInput: Array<DebitAllocationInput>;
  DebitNoteID: Scalars['String'];
};


export type MutationDebitNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreditNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
};


export type MutationCreditNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationCreditNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateCreditNoteArgs = {
  allocInput: Array<CreditAllocationInput>;
  CreditNoteID: Scalars['String'];
};


export type MutationCreditNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRefundInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
};


export type MutationRefundUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
  ID: Scalars['String'];
};


export type MutationRefundCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateRefundArgs = {
  allocInput: Array<DebitAllocationInput>;
  RefundID: Scalars['String'];
};


export type MutationRefundDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationInvoiceInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
};


export type MutationInvoiceUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
  ID: Scalars['String'];
};


export type MutationInvoiceCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateInvoiceArgs = {
  allocInput: Array<DebitAllocationInput>;
  InvoiceID: Scalars['String'];
};


export type MutationInvoiceDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationOfficialReceiptInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
};


export type MutationOfficialReceiptUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
};


export type MutationOfficialReceiptCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateOfficialReceiptArgs = {
  allocInput: Array<CreditAllocationInput>;
  ReceiptID: Scalars['String'];
};


export type MutationOfficialReceiptDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationReminderInsertArgs = {
  input: ReminderEntityInput;
};


export type MutationReminderUpdateArgs = {
  input: ReminderEntityInput;
  ID: Scalars['String'];
};


export type MutationReminderDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAsyncInterestInsertArgs = {
  input: InterestEntityInput;
};


export type MutationInterestUpdateArgs = {
  input: InterestEntityInput;
  ID: Scalars['String'];
};


export type MutationInterestDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDocumentNumberingSetupInsertArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderinput: DocumentNumberHeaderInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  SampleOutput: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDebtorTypeInsertArgs = {
  input: DebtorTypeInput;
};


export type MutationDebtorTypeUpdateArgs = {
  input: DebtorTypeInput;
  ID: Scalars['String'];
};


export type MutationDebtorTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationPostScheduleBillingArgs = {
  input: Array<ScheduleBillingInput>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationRoomSaleOptimizationUpdateArgs = {
  input: Array<AvailabilityInput>;
};


export type MutationSpecialReqUpdateArgs = {
  SpecialReq: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type MutationAssignRoomArgs = {
  Mode?: Maybe<Scalars['Boolean']>;
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ContactInput?: Maybe<ContactInput>;
  RegistrationIDs: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type MutationTransferRoomArgs = {
  NewRoomID: Scalars['String'];
  OldRoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationGuestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  ContactInput: Array<ContactInput>;
};


export type MutationDepositInsertArgs = {
  input: DepositInput;
};


export type MutationRefundDepositArgs = {
  input: RefundDepositInput;
};


export type MutationCloseFolioArgs = {
  PaymentID: Scalars['String'];
  RegistrationID: Array<Scalars['String']>;
  FolioInput: FolioInput;
};


export type MutationVoidLedgerArgs = {
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
};


export type MutationVoidBillScheduleArgs = {
  input: BillScheduleInput;
};


export type MutationRegistrationSignatureUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  RegistrationID: Scalars['String'];
};


export type MutationVoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationPosV7VoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationFrontDeskPaymentInsertArgs = {
  AdjustedAmount?: Maybe<Scalars['Float']>;
  IsGroupFolio?: Maybe<Scalars['Boolean']>;
  DebtorID?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  TotalPaymentAmount: Scalars['Float'];
  input: Array<FrontDeskPaymentInput>;
};


export type MutationCheckOutArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationWalkIngBookingInsertArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RegistrationInput: RegistrationInput;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationLateCheckOutInsertArgs = {
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
};


export type MutationExtendStayInsertArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
};


export type MutationShortenStayInsertArgs = {
  RoomTypeID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
};


export type MutationRoomUpGradeInsertArgs = {
  RoomUpgradeInput: RoomUpgradeInput;
  PaymentInput: FrontDeskPaymentInput;
};


export type MutationNonGuestFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestFolioUpdateArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestVoidLedgerArgs = {
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
};


export type MutationNonGuestPaymentInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentInput: PaymentInput;
};


export type MutationNonGuestCloseFolioArgs = {
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationSplitPaymentArgs = {
  SplitBy: Scalars['Float'];
  RecordToSplit: Array<PaymentFolioInput>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationSplitPaymentV2Args = {
  SplitValue: Scalars['Float'];
  SplitType: Scalars['String'];
  SplitBy: Scalars['Float'];
  RecordToSplit: Array<PaymentFolioInput>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferPaymentArgs = {
  RecordIDs: Array<Scalars['String']>;
  NewRegistrationID: Scalars['String'];
};


export type MutationRegistrationSignArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  ID: Scalars['String'];
};


export type MutationPaymentUpdateArgs = {
  IsGuestApp?: Maybe<Scalars['Boolean']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationCancelCheckInArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationFolioBeneficiaryNameUpdateArgs = {
  BeneficiaryName: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationGuestRegisterArgs = {
  Password: Scalars['String'];
  Login: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationGuestLoginArgs = {
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationGuestChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationGuestForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGuestResetPasswordArgs = {
  password: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationOnlineBookingInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput>;
  BookingInput: BookingInput;
};


export type MutationGuestRoomServiceInsertArgs = {
  AccountID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  PaymentMode: Scalars['String'];
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationHotelRatingInsertArgs = {
  input: HotelRatingInput;
};


export type MutationPromotionUpdateArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionInsertArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionActiveUpdateArgs = {
  PromotionInput: PromotionInput;
};


export type MutationPromotionDetailUpdateArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionDetailInsertArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationEmailCampignUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampignInsertArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampaignArgs = {
  id: Scalars['String'];
  email: Scalars['String'];
};


export type MutationPromotionDeleteArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationEmailDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationGuestReviewActiveUpdateArgs = {
  HotelRatingInput: HotelRatingInput;
};


export type MutationHotelTaxPolicyInsertArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyUpdateArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationBookingInsertArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  RegistrationInput: Array<RegistrationInput>;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationGuestAppRoomTypeDeleteArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID: Scalars['String'];
};


export type MutationBookingRoomTypeUpdateArgs = {
  EditRegInput: EditRegInput;
};


export type MutationHouseLimitUpdateArgs = {
  HouseLimitInput?: Maybe<Array<HouseLimitInput>>;
};


export type MutationBookingUpdateArgs = {
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
};


export type MutationCancelBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationAdvancePaymentInsertArgs = {
  AdvancePaymentInput: AdvancePaymentInput;
};


export type MutationAdvancePaymentRefundArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
};


export type MutationAdvancePaymentUpdateArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationReinstateBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type MutationImageUploadArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationManualNightAuditArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationAvailabilityUpdateArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationAtestInsertArgs = {
  BookingID: Scalars['String'];
};


export type MutationBookingRoomAssignmentArgs = {
  RoomTypeID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  RoomIDs: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type MutationBillScheduleAdjustmentArgs = {
  Reason: Scalars['String'];
  Discount: Scalars['Float'];
  RateID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  TrxDates: Array<Scalars['DateTime']>;
};


export type MutationBookingUpdateV2Args = {
  EditRegInput?: Maybe<Array<EditRegInput>>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
  BookingID: Scalars['String'];
};


export type MutationCancelRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationReinstateRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationEmailSendArgs = {
  Email?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Body: EmailBodyInput;
};


export type MutationBiSharedGAlleryInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  HotelID: Scalars['String'];
};


export type MutationCorporateInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationGovermentTestingUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationCorporateUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationCorporateContractUpdateArgs = {
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationCorporateContractInsertArgs = {
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationCorporateDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationActivityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationActivityUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationGuestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  ProfilePictureAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  GuestProfileInput: GuestProfileInput;
  ContactInput: ContactInput;
};


export type MutationGuestProfileInsertArgs = {
  ContactInput: ContactInput;
  RegistrationID: Scalars['String'];
};


export type MutationGuestContactUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
};


export type MutationGuestProfileDeleteArgs = {
  PrincipalRegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};


export type MutationNoOfPaxUpdateArgs = {
  BookingID: Scalars['String'];
  NoOfAdult: Scalars['Float'];
  SharerNo: Scalars['Float'];
  RoomTypeID: Scalars['String'];
};


export type MutationGuestMovementInsertArgs = {
  GuestMovementInput: GuestMovementInput;
};


export type MutationCbPostArgs = {
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGlPostArgs = {
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationBillLedgerUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillLedgerCancelArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationPosV7BillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioInsertArgs = {
  RefFolioID?: Maybe<Scalars['String']>;
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRoomServicePaymentUpdateArgs = {
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationOnlinePaymentRoomServicesArgs = {
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioAdjustmentInsertArgs = {
  RefFolioID?: Maybe<Scalars['String']>;
  PaymentInput: PaymentInput;
  TaxLedgerInput: Array<TaxLedgerInput>;
  RoomLedgerInput: Array<RoomLedgerInput>;
  BillLedgerInput: Array<BillLedgerInput>;
  HotelID: Scalars['String'];
};


export type MutationChangePaymentArgs = {
  PaymentInput: PaymentInput;
  ReversedPaymentID: Scalars['String'];
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationPosChargeToCityLedgerArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7ScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationHuaweiOcrArgs = {
  File: Scalars['String'];
};


export type MutationInnov8OcrArgs = {
  File: Scalars['String'];
};


export type MutationPackageRedemptionInsertArgs = {
  PackageRedemptionInput: PackageRedemptionInput;
};


export type MutationBlockRoomLogInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationReleaseRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationHouseKeepingLogInsertArgs = {
  RoomIDs?: Maybe<Array<Scalars['String']>>;
  HouseKeepingLogInput: HouseKeepingLogInput;
};


export type MutationHousekeepingServiceRequestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingServiceRequestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingBillLedgerInsertArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationHousekeepingBillLedgerUpdateArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationDepartmentInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationIncidentalChargesUpdateArgs = {
  status: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDepartmentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationEDocInsertArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationEDocTemplateUpdateArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationAccountMappingGlInsertArgs = {
  GuestLedgerAcctInput: GuestLedgerAcctInput;
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationCityLedgerGlInsertArgs = {
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationBankAccountUpdateArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountInsertArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationHotelUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelInsertArgs = {
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelContactUpdateArgs = {
  ContactInput: ContactInput;
};


export type MutationHotelContactInsertArgs = {
  HotelID: Scalars['String'];
  ContactInput: ContactInput;
};


export type MutationHotelFacilityUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelFacilityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelNewsUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationHotelNewsInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationIncidentalChargeInsertArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationLateCheckOutChargeInsertArgs = {
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput>;
};


export type MutationLocationInsertArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationUpdateArgs = {
  LocationInput: LocationInput;
};


export type MutationDisplaySequenceUpdateArgs = {
  Type: Scalars['String'];
  Input: Array<DisplaySequenceInput>;
};


export type MutationLocationDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRateElementInsertArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateElementUpdateArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateAddOnInsertArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateAddOnUpdateArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateTypeInsertArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateTypeUpdateArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateInsertArgs = {
  RateInput: RateInput;
};


export type MutationRateUpdateArgs = {
  RateInput: RateInput;
};


export type MutationRateEffectiveInsertArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationRateEffectiveUpdateArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationCmsRateSyncArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationRateEffectiveStatusUpdateArgs = {
  RateEffectiveID: Scalars['String'];
  IsActive: Scalars['Boolean'];
};


export type MutationRateAdjustmentAuditInsertArgs = {
  RateAdjustmentAuditInput: RateAdjustmentAuditInput;
};


export type MutationSeasonInsertArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonUpdateArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonCalendarUpdateArgs = {
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  SeasonCalendarInput: Array<SeasonCalendarInput>;
};


export type MutationRatePolicyInsertArgs = {
  PackagesInput: Array<PackagesInput>;
  PricingInput: Array<PricingInput>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRatePolicyUpdateArgs = {
  PricingInput?: Maybe<Array<PricingInput>>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationPackageInsertArgs = {
  PackagesInput: Array<PackagesInput>;
};


export type MutationPackageUpdateArgs = {
  PackagesInput: PackagesInput;
};


export type MutationRatePolicyDeleteArgs = {
  DebtorCategory: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationReasonInsertArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonUpdateArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRoomInsertArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomUpdateArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomDeleteArgs = {
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRoomStatusInsertArgs = {
  RoomStatusInput: Array<RoomStatusInput>;
};


export type MutationRoomTypeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput?: Maybe<Array<RoomTypeAmenitiesInput>>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSalesChannelInsertArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelUpdateArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSegmentUpdateArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentInsertArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTravelAgentInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentMainUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentContractUpdateArgs = {
  HotelID: Scalars['String'];
  RoomTypeList?: Maybe<Array<ContractAllotmentInput>>;
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationTravelAgentContractInsertArgs = {
  HotelID: Scalars['String'];
  RoomTypeList: Array<ContractAllotmentInput>;
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationUpdateAllotmentArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  DebtorID: Scalars['String'];
  AllotmentInput: Array<ContractAllotmentInput>;
};


export type MutationTravelAgentDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationDebtorLoginArgs = {
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationDebtorLastAccessedHotelUpdateArgs = {
  HotelID: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationDebtorChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationDebtorForgotPasswordArgs = {
  Email: Scalars['String'];
};


export type MutationAgentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDebtorPaymentInsertArgs = {
  DebtorPaymentInput: Array<DebtorPaymentInput>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  NewNotification: NotificationEntity;
};


export type SubscriptionNewNotificationArgs = {
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type DebtorLoginMutationVariables = Exact<{
  Password: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
}>;


export type DebtorLoginMutation = (
  { __typename?: 'Mutation' }
  & { DebtorLogin: (
    { __typename?: 'DebtorProfileEntity' }
    & Pick<DebtorProfileEntity, 'accessToken' | 'ID' | 'Email'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email' | 'MobileNo' | 'BirthDate'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<Array<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'DebtorAccount' | 'HotelID'>
    )>> }
  ) }
);

export type DebtorChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type DebtorChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { DebtorChangePassword: (
    { __typename?: 'DebtorProfileEntity' }
    & Pick<DebtorProfileEntity, 'ID'>
  ) }
);

export type DebtorForgotPasswordMutationVariables = Exact<{
  Email: Scalars['String'];
}>;


export type DebtorForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorForgotPassword'>
);

export type DebtorResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type DebtorResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorResetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type LoggedInDebtorProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInDebtorProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInDebtorProfile: (
    { __typename?: 'DebtorProfileEntity' }
    & Pick<DebtorProfileEntity, 'accessToken' | 'ID' | 'Email'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email' | 'MobileNo' | 'BirthDate' | 'Nationality'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<Array<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'DebtorAccount' | 'CreditTerm'>
    )>> }
  ) }
);

export type DebtorDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebtorDetailQuery = (
  { __typename?: 'Query' }
  & { DebtorDetail: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'ARTotalOutstanding'>
  ) }
);

export type DebtorInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebtorInfoLineQuery = (
  { __typename?: 'Query' }
  & { ARTotalOutstanding: (
    { __typename?: 'ARAccountOSLastPayment' }
    & Pick<ArAccountOsLastPayment, 'LastPaymentDT' | 'TotalOstandingAmount'>
  ) }
);

export type LedgerDocumentInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type LedgerDocumentInfoLineQuery = (
  { __typename?: 'Query' }
  & { InvoiceInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), DebitNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), CreditNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), OfficialReceiptInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), RefundInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type FolioDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type FolioDetailsQuery = (
  { __typename?: 'Query' }
  & { FolioDetails: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'FolioAmount' | 'CreatedBy'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
            )> }
          )> }
        )> }
      )>> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo'>
        & { Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'DebtorAccount'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxAmount' | 'Reason' | 'Description'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, RoomCharges?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'Description' | 'TaxAmount' | 'ServiceCharge' | 'RegistrationID'>
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
    )>> }
  ) }
);

export type GetHotelLatestTaxQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GetHotelLatestTaxQuery = (
  { __typename?: 'Query' }
  & { GetLevyTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetPlatformTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetCurrentGovTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetCurrentTourismTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetServiceChargeTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ) }
);

export type InvoiceListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type InvoiceListingsQuery = (
  { __typename?: 'Query' }
  & { InvoiceListings: Array<(
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'InvoiceTrxEntity' }
      & Pick<InvoiceTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'ModifiedDT' | 'CreditID' | 'AllocationAmount'>
      & { CreditDetail: Array<(
        { __typename?: 'DebitNoteEntity' }
        & Pick<DebitNoteEntity, 'ID' | 'DocumentNo'>
      )> }
    )>, CityLedgerAttachment?: Maybe<Array<(
      { __typename?: 'CityLedgerAttachmentEntity' }
      & Pick<CityLedgerAttachmentEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type DebitNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebitNoteListingsQuery = (
  { __typename?: 'Query' }
  & { DebitNoteListings: Array<(
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'ModifiedDT' | 'CreditID' | 'AllocationAmount'>
      & { CreditDetail: Array<(
        { __typename?: 'DebitNoteEntity' }
        & Pick<DebitNoteEntity, 'ID' | 'DocumentNo'>
      )> }
    )>, CityLedgerAttachment?: Maybe<Array<(
      { __typename?: 'CityLedgerAttachmentEntity' }
      & Pick<CityLedgerAttachmentEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type CreditNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type CreditNoteListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditNoteListings'>
);

export type OfficialReceiptListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type OfficialReceiptListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OfficialReceiptListings'>
);

export type RefundListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type RefundListingsQuery = (
  { __typename?: 'Query' }
  & { RefundListings: Array<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission' | 'CreatedBy' | 'ModifiedBy'>
    & { bankDetails: (
      { __typename?: 'BankDetails' }
      & Pick<BankDetails, 'Name'>
    ), Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'ModifiedDT' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
      & { CreditDetail: Array<(
        { __typename?: 'DebitNoteEntity' }
        & Pick<DebitNoteEntity, 'ID' | 'DocumentNo'>
      )> }
    )>, CityLedgerAttachment?: Maybe<Array<(
      { __typename?: 'CityLedgerAttachmentEntity' }
      & Pick<CityLedgerAttachmentEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type BiUsersLisitingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type BiUsersLisitingQuery = (
  { __typename?: 'Query' }
  & { BIUsersLisiting: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'name' | 'userName'>
  ) }
);

export type BookFormQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type BookFormQuery = (
  { __typename?: 'Query' }
  & { SegmentListing: Array<(
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'IsActive' | 'Description'>
  )>, RoomTypeListing: Array<(
    { __typename?: 'RoomTypeEntity' }
    & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description'>
  )>, PromotionListing: Array<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'ID' | 'Code' | 'Description' | 'DiscountType' | 'DiscountValue'>
    & { PromotionDetail?: Maybe<Array<(
      { __typename?: 'PromotionDetailEntity' }
      & Pick<PromotionDetailEntity, 'ID' | 'RatePolicyID' | 'Value'>
    )>> }
  )>, HotelSalesChannelListing: Array<(
    { __typename?: 'SalesChannelEntity' }
    & Pick<SalesChannelEntity, 'ID' | 'Description' | 'IsActive'>
  )>, DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'SalesChannel' | 'Login' | 'Password'>
  )> }
);

export type CancelReasonListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CancelReasonListingQuery = (
  { __typename?: 'Query' }
  & { CancelReasonListing: Array<(
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'Description'>
  )> }
);

export type GuestProfileListingByIdQueryVariables = Exact<{
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GuestProfileListingByIdQuery = (
  { __typename?: 'Query' }
  & { GuestProfileListingByID: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'RoomTypeID' | 'RatePolicyID' | 'RoomID' | 'BookingID' | 'IsPrincipal' | 'SharerNo'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'MaxGuest'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'VehicleNo' | 'NewsLetter' | 'IsVIP'>
      & { ICGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )>, GuestHistoryList: Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'ID' | 'Code'>
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'ID' | 'RoomNo'>
        )>, RatePolicy?: Maybe<(
          { __typename?: 'RateEntity' }
          & Pick<RateEntity, 'ID' | 'RateCode'>
        )> }
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
        )> }
      )> }
    )> }
  )> }
);

export type BookingListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  DebtorProfileID?: Maybe<Scalars['String']>;
}>;


export type BookingListingQuery = (
  { __typename?: 'Query' }
  & { BookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'ChargeToCityLedger' | 'TotalAdvPayment' | 'IsGroupBooking' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'GroupName' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'Reason' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
        )> }
      )> }
    )>> }
  )> }
);

export type BookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type BookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'GroupName' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'Reason' | 'Remark' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'IncidentalChargesCount' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalOutStandingAmount' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'IdPicture' | 'VehicleNo'>
        & { ICGallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>>, AllRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'IncidentalChargesCount' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalOutStandingAmount' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type AvailabilityListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: Maybe<Scalars['DateTime']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  TravelAgentID?: Maybe<Scalars['String']>;
}>;


export type AvailabilityListingQuery = (
  { __typename?: 'Query' }
  & { AvailabilityListing: (
    { __typename?: 'HotelAvailabilityOutput' }
    & { RoomTypesList?: Maybe<Array<(
      { __typename?: 'RoomTypesListOutput' }
      & Pick<RoomTypesListOutput, 'ID' | 'Code' | 'Description' | 'TotalRoom'>
      & { RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'BookedRoom' | 'BlockedRoom' | 'AllottedRoom' | 'TotalOccupied' | 'TotalVacant' | 'SeasonType'>
      )>> }
    )>>, Occupancy?: Maybe<Array<(
      { __typename?: 'Occupancy' }
      & Pick<Occupancy, 'EffectiveDate' | 'Season' | 'Occupancy' | 'TotalOccupied' | 'TotalRoom'>
    )>> }
  ) }
);

export type PackagePriceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
}>;


export type PackagePriceListingQuery = (
  { __typename?: 'Query' }
  & { PackagePriceListing: Array<(
    { __typename?: 'PackagePriceOutput' }
    & Pick<PackagePriceOutput, 'RoomTypeID' | 'RoomTypeCode' | 'RoomTypeName' | 'RatePolicyID' | 'Code' | 'TotalVacant'>
    & { Pricing?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Date' | 'Type' | 'Price' | 'BasePrice'>
      & { Packages?: Maybe<Array<(
        { __typename?: 'PackagesEntity' }
        & Pick<PackagesEntity, 'ID' | 'IncidentalChargeID' | 'Description' | 'IncludeRate' | 'ComputationRules' | 'ServiceCharge' | 'Tax' | 'Amount'>
      )>> }
    )>> }
  )> }
);

export type AdvancePaymentListingQueryVariables = Exact<{
  BookingID: Scalars['String'];
}>;


export type AdvancePaymentListingQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentListing: Array<(
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'PaymentDate' | 'HotelID' | 'BookingID' | 'RegistrationID' | 'PaymentClass' | 'ReceiptNo' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'PaymentStatus' | 'IsPosted' | 'ReversedAdvancePaymentID'>
  )> }
);

export type ChangeOfDateCheckAvailabilityQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
}>;


export type ChangeOfDateCheckAvailabilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ChangeOfDateCheckAvailability'>
);

export type AdvancePaymentDetailQueryVariables = Exact<{
  BookingID: Scalars['String'];
  OrderID: Scalars['String'];
}>;


export type AdvancePaymentDetailQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentDetail: (
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type BookingInsertMutationVariables = Exact<{
  BookingInput: BookingInput;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput> | RegistrationInput;
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BookingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingInsert'>
);

export type BookingUpdateMutationVariables = Exact<{
  BookingInput: BookingInput;
  ContactInput?: Maybe<ContactInput>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput> | SpecialRequestInput>;
}>;


export type BookingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingUpdate'>
);

export type ReinstateBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
}>;


export type ReinstateBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReinstateBooking'>
);

export type CancelBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelBooking'>
);

export type BookingRoomTypeUpdateMutationVariables = Exact<{
  EditRegInput: EditRegInput;
}>;


export type BookingRoomTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingRoomTypeUpdate'>
);

export type GuestProfileInsertMutationVariables = Exact<{
  RegistrationID: Scalars['String'];
  ContactInput: ContactInput;
}>;


export type GuestProfileInsertMutation = (
  { __typename?: 'Mutation' }
  & { GuestProfileInsert: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
  ) }
);

export type GuestContactUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  VehicleNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GuestContactUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestContactUpdate'>
);

export type GuestProfileDeleteMutationVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
}>;


export type GuestProfileDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestProfileDelete'>
);

export type AdvancePaymentInsertMutationVariables = Exact<{
  AdvancePaymentInput: AdvancePaymentInput;
}>;


export type AdvancePaymentInsertMutation = (
  { __typename?: 'Mutation' }
  & { AdvancePaymentInsert: (
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type AdvancePaymentRefundMutationVariables = Exact<{
  AdvancePaymentInput: Array<AdvancePaymentInput> | AdvancePaymentInput;
}>;


export type AdvancePaymentRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AdvancePaymentRefund'>
);

export type ImageUploadMutationVariables = Exact<{
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type ImageUploadMutation = (
  { __typename?: 'Mutation' }
  & { ImageUpload: (
    { __typename?: 'GalleryEntity' }
    & Pick<GalleryEntity, 'ImageURL'>
  ) }
);

export type ContractDetailsQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
}>;


export type ContractDetailsQuery = (
  { __typename?: 'Query' }
  & { ContractDetails: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'ContractNo' | 'Description' | 'StartDate' | 'EndDate' | 'CutOffDays' | 'PaymentTerms' | 'Remarks'>
    & { ContractRate?: Maybe<Array<(
      { __typename?: 'ContractRateEntity' }
      & Pick<ContractRateEntity, 'ID'>
      & { RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
      )> }
    )>> }
  ) }
);

export type RatePolicyListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type RatePolicyListingQuery = (
  { __typename?: 'Query' }
  & { RatePolicyListing: Array<(
    { __typename?: 'RateEntity' }
    & Pick<RateEntity, 'ID' | 'HotelID' | 'IsActive' | 'Category' | 'RateCode' | 'Description' | 'OnlineRate'>
  )> }
);

export type BookingUpdateV2MutationVariables = Exact<{
  EditRegInput?: Maybe<Array<EditRegInput> | EditRegInput>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput> | SpecialRequestInput>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
  BookingID: Scalars['String'];
}>;


export type BookingUpdateV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingUpdateV2'>
);

export type NoOfPaxUpdateMutationVariables = Exact<{
  BookingID: Scalars['String'];
  NoOfAdult: Scalars['Float'];
  SharerNo: Scalars['Float'];
  RoomTypeID: Scalars['String'];
}>;


export type NoOfPaxUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NoOfPaxUpdate'>
);

export type GuestInsertMutationVariables = Exact<{
  ContactInput: Array<ContactInput> | ContactInput;
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type GuestInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestInsert'>
);

export type SegmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type SegmentListingQuery = (
  { __typename?: 'Query' }
  & { SegmentListing: Array<(
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'Description' | 'IsActive' | 'DisplaySequence'>
  )> }
);

export type TravelAgentContractListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
}>;


export type TravelAgentContractListingQuery = (
  { __typename?: 'Query' }
  & { TravelAgentContractListing: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'DebtorID' | 'ContractNo' | 'Description' | 'StartDate' | 'EndDate' | 'RoomUpgrade' | 'Elastic' | 'Allotment' | 'CutOffDays' | 'PaymentTerms' | 'AllowDeleteContract' | 'Remarks' | 'IsActive' | 'IsCMS' | 'ChannelManager'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name'>
    )>, ContractRate?: Maybe<Array<(
      { __typename?: 'ContractRateEntity' }
      & Pick<ContractRateEntity, 'ID'>
      & { RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'Description' | 'RateCode'>
        & { RateEffective?: Maybe<Array<(
          { __typename?: 'RateEffectiveEntity' }
          & Pick<RateEffectiveEntity, 'ID'>
          & { SeasonalRate?: Maybe<Array<(
            { __typename?: 'SeasonalRateEntity' }
            & Pick<SeasonalRateEntity, 'ID' | 'StandardRate'>
            & { RoomType?: Maybe<(
              { __typename?: 'RoomTypeEntity' }
              & Pick<RoomTypeEntity, 'Description' | 'ID'>
            )> }
          )>> }
        )>> }
      )> }
    )>>, ContractAllotmentByRoomType?: Maybe<Array<(
      { __typename?: 'ContractAllotmentEntity' }
      & Pick<ContractAllotmentEntity, 'ID' | 'AllowDeleteAllotment' | 'RoomTypeID' | 'InitialAllot' | 'Adjust' | 'SysAdjust' | 'PickUp' | 'SysRelease' | 'AllotDate'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type AllotmentListingQueryVariables = Exact<{
  ContractID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type AllotmentListingQuery = (
  { __typename?: 'Query' }
  & { AllotmentListing: Array<(
    { __typename?: 'ContractAllotmentEntity' }
    & Pick<ContractAllotmentEntity, 'ID' | 'RoomTypeID' | 'InitialAllot' | 'Adjust' | 'SysAdjust' | 'PickUp' | 'SysRelease' | 'AllotDate' | 'IsActive'>
  )> }
);

export type HotelsByCityQueryVariables = Exact<{
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  City: Scalars['String'];
  NoOfRooms?: Maybe<Scalars['Float']>;
  NoOfGuests?: Maybe<Scalars['Float']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type HotelsByCityQuery = (
  { __typename?: 'Query' }
  & { HotelsByCity: Array<(
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'Logo' | 'HotelName'>
    & { HotelDebtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID'>
    )>, HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, HotelGallery?: Maybe<Array<(
      { __typename?: 'HotelGalleryEntity' }
      & Pick<HotelGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL' | 'SequenceNo'>
      )> }
    )>>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )>, Price?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Price' | 'BasePrice' | 'Date' | 'Type'>
    )>> }
  )> }
);

export type InterstTotalQueryVariables = Exact<{ [key: string]: never; }>;


export type InterstTotalQuery = (
  { __typename?: 'Query' }
  & { InterstTotal: (
    { __typename?: 'InterestResponse' }
    & Pick<InterestResponse, 'TotalTrxAmt' | 'TotalInterestAmt'>
  ) }
);

export type UpdateInterestAmtMutationVariables = Exact<{
  InterestRate: Scalars['Float'];
}>;


export type UpdateInterestAmtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateInterestAmt'>
);

export type HotelDetailsQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
}>;


export type HotelDetailsQuery = (
  { __typename?: 'Query' }
  & { HotelDetails: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'SSTRegNo'>
    & { HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'MobileNo' | 'GeneralEmail' | 'ReservationEmail' | 'BusinessEmail' | 'WebUrl' | 'NotificationMobileNo'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'HotelID' | 'IsActive' | 'Title' | 'Description'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, RoomType?: Maybe<Array<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'PropertyType' | 'MeasureType' | 'RoomSize' | 'TotalRoom' | 'MaxGuest'>
      & { Room?: Maybe<Array<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
        )> }
      )>>, RoomTypeGallery?: Maybe<Array<(
        { __typename?: 'RoomTypeGalleryEntity' }
        & Pick<RoomTypeGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, RoomTypeAmenities?: Maybe<Array<(
        { __typename?: 'RoomTypeAmenitiesEntity' }
        & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>> }
    )>> }
  ) }
);

export type HotelPricesByDateRangeQueryVariables = Exact<{
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type HotelPricesByDateRangeQuery = (
  { __typename?: 'Query' }
  & { HotelPricesByDateRange: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'IsPostPaid' | 'OnlineTnC' | 'AboutHotel'>
    & { Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'HotelID' | 'IsActive' | 'Title' | 'Description'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, HotelGallery?: Maybe<Array<(
      { __typename?: 'HotelGalleryEntity' }
      & Pick<HotelGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL' | 'SequenceNo'>
      )> }
    )>>, HotelRate?: Maybe<Array<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'PropertyType' | 'MeasureType' | 'RoomSize' | 'TotalRoom' | 'MaxGuest'>
      & { Room?: Maybe<Array<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
        )> }
      )>>, RoomTypeGallery?: Maybe<Array<(
        { __typename?: 'RoomTypeGalleryEntity' }
        & Pick<RoomTypeGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, RoomTypeAmenities?: Maybe<Array<(
        { __typename?: 'RoomTypeAmenitiesEntity' }
        & Pick<RoomTypeAmenitiesEntity, 'ID' | 'IsSelected' | 'Name'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, TotalPackages?: Maybe<Array<(
        { __typename?: 'TotalPackagesResponse' }
        & Pick<TotalPackagesResponse, 'PackageName' | 'Price' | 'RatePolicyID' | 'PriceID'>
      )>>, RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'TotalVacant'>
        & { Pricing?: Maybe<Array<(
          { __typename?: 'PricingEntity' }
          & Pick<PricingEntity, 'ID' | 'PackageAmount' | 'SellingPrice'>
          & { RatePolicy?: Maybe<(
            { __typename?: 'RatePolicyEntity' }
            & Pick<RatePolicyEntity, 'ID' | 'Description'>
          )> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type NewNotificationSubscriptionVariables = Exact<{
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
}>;


export type NewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { NewNotification: (
    { __typename?: 'NotificationEntity' }
    & Pick<NotificationEntity, 'ID' | 'HotelID' | 'Message' | 'Date'>
  ) }
);

export type CurrentTaxDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CurrentTaxDetailsQuery = (
  { __typename?: 'Query' }
  & { CurrentTaxDetails: (
    { __typename?: 'CurrentTaxDetailsOutput' }
    & Pick<CurrentTaxDetailsOutput, 'HotelID' | 'GovTax' | 'ServiceCharge'>
  ) }
);

export type TravelAgentHotelListingQueryVariables = Exact<{
  Login: Scalars['String'];
  DebtorCategory: Scalars['String'];
}>;


export type TravelAgentHotelListingQuery = (
  { __typename?: 'Query' }
  & { TravelAgentHotelListing: Array<(
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'Encoders'>
    & { HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )> }
  )> }
);

export type OnlinePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
  ReceiptNo: Scalars['String'];
}>;


export type OnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { OnlinePayment?: Maybe<(
    { __typename?: 'CheckOut' }
    & Pick<CheckOut, 'code' | 'PaySessionNo'>
    & { item?: Maybe<(
      { __typename?: 'item' }
      & Pick<Item, 'checkoutId' | 'url' | 'qrCodeUrl' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'code' | 'message' | 'debug'>
    )> }
  )> }
);

export type OnlineBookingInsertMutationVariables = Exact<{
  AppName: Scalars['String'];
  RegistrationInput: Array<RegistrationInput> | RegistrationInput;
  BookingInput: BookingInput;
  ContactInput: ContactInput;
}>;


export type OnlineBookingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OnlineBookingInsert'>
);

export type PaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type PaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PaymentUpdate'>
);

export type AdvancePaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type AdvancePaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AdvancePaymentUpdate'>
);

export type PaymentDetailQueryVariables = Exact<{
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PaymentDetailQuery = (
  { __typename?: 'Query' }
  & { PaymentDetail: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'CardNo' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type ReceiptListingQueryVariables = Exact<{
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
}>;


export type ReceiptListingQuery = (
  { __typename?: 'Query' }
  & { ReceiptListing: Array<(
    { __typename?: 'PaymentFoilioResponse' }
    & Pick<PaymentFoilioResponse, 'RegistrationID' | 'TrxDate' | 'ChargeType' | 'Description' | 'ReferenceNo' | 'FullName' | 'RoomNo' | 'BaseAmount' | 'TrxAmount' | 'ID'>
  )> }
);

export type DisCountByPromoCodeQueryVariables = Exact<{
  PromoCodeInput: Array<PromoCodeInput> | PromoCodeInput;
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DisCountByPromoCodeQuery = (
  { __typename?: 'Query' }
  & { DisCountByPromoCode: Array<(
    { __typename?: 'PromoCodeResponse' }
    & Pick<PromoCodeResponse, 'RoomTypeID' | 'RatePolicyID' | 'DiscountAmt'>
  )> }
);

export type ComputeTaxArrByHotelQueryVariables = Exact<{
  TaxInput: Array<TaxInput> | TaxInput;
}>;


export type ComputeTaxArrByHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeTaxArrByHotel: Array<(
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
  )> }
);

export type DocumentTemplateListQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DocumentTemplateListQuery = (
  { __typename?: 'Query' }
  & { DocumentTemplateList: (
    { __typename?: 'DocumentTemplateEntity' }
    & Pick<DocumentTemplateEntity, 'RegCard' | 'Folio' | 'Receipt' | 'Invoice'>
  ) }
);

export type EmailSendMutationVariables = Exact<{
  Body: EmailBodyInput;
  HotelID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
}>;


export type EmailSendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmailSend'>
);

export type BiSharedGAlleryInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type BiSharedGAlleryInsertMutation = (
  { __typename?: 'Mutation' }
  & { BISharedGAlleryInsert: (
    { __typename?: 'SharedGalleryEntity' }
    & Pick<SharedGalleryEntity, 'ID'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL'>
    )> }
  ) }
);

export type SelectFolioQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SelectFolioQuery = (
  { __typename?: 'Query' }
  & { SelectFolio: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'FolioNo'>
  ) }
);

export type IsPaymentGateWayHotelQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type IsPaymentGateWayHotelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPaymentGateWayHotel'>
);

export type DebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DebtorListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'SalesChannel' | 'Login' | 'Password' | 'ARTotalDue' | 'ARTotalOutstanding' | 'LastPaymentDate'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
      )> }
    )>> }
  )> }
);


export const DebtorLoginDocument = gql`
    mutation DebtorLogin($Password: String!, $Email: String, $MobileNo: String, $Login: String) {
  DebtorLogin(Email: $Email, Password: $Password, MobileNo: $MobileNo, Login: $Login) {
    accessToken
    ID
    Email
    Contact {
      ID
      FullName
      PhoneNo
      Email
      MobileNo
      BirthDate
      Address {
        address
        city
        state
        country
        postCode
      }
    }
    Debtor {
      ID
      DebtorAccount
      HotelID
    }
  }
}
    `;
export type DebtorLoginMutationFn = ApolloReactCommon.MutationFunction<DebtorLoginMutation, DebtorLoginMutationVariables>;

/**
 * __useDebtorLoginMutation__
 *
 * To run a mutation, you first call `useDebtorLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorLoginMutation, { data, loading, error }] = useDebtorLoginMutation({
 *   variables: {
 *      Password: // value for 'Password'
 *      Email: // value for 'Email'
 *      MobileNo: // value for 'MobileNo'
 *      Login: // value for 'Login'
 *   },
 * });
 */
export function useDebtorLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorLoginMutation, DebtorLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorLoginMutation, DebtorLoginMutationVariables>(DebtorLoginDocument, baseOptions);
      }
export type DebtorLoginMutationHookResult = ReturnType<typeof useDebtorLoginMutation>;
export type DebtorLoginMutationResult = ApolloReactCommon.MutationResult<DebtorLoginMutation>;
export type DebtorLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorLoginMutation, DebtorLoginMutationVariables>;
export const DebtorChangePasswordDocument = gql`
    mutation DebtorChangePassword($newPassword: String!, $currentPassword: String!) {
  DebtorChangePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    ID
  }
}
    `;
export type DebtorChangePasswordMutationFn = ApolloReactCommon.MutationFunction<DebtorChangePasswordMutation, DebtorChangePasswordMutationVariables>;

/**
 * __useDebtorChangePasswordMutation__
 *
 * To run a mutation, you first call `useDebtorChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorChangePasswordMutation, { data, loading, error }] = useDebtorChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useDebtorChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorChangePasswordMutation, DebtorChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorChangePasswordMutation, DebtorChangePasswordMutationVariables>(DebtorChangePasswordDocument, baseOptions);
      }
export type DebtorChangePasswordMutationHookResult = ReturnType<typeof useDebtorChangePasswordMutation>;
export type DebtorChangePasswordMutationResult = ApolloReactCommon.MutationResult<DebtorChangePasswordMutation>;
export type DebtorChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorChangePasswordMutation, DebtorChangePasswordMutationVariables>;
export const DebtorForgotPasswordDocument = gql`
    mutation DebtorForgotPassword($Email: String!) {
  DebtorForgotPassword(Email: $Email)
}
    `;
export type DebtorForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<DebtorForgotPasswordMutation, DebtorForgotPasswordMutationVariables>;

/**
 * __useDebtorForgotPasswordMutation__
 *
 * To run a mutation, you first call `useDebtorForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorForgotPasswordMutation, { data, loading, error }] = useDebtorForgotPasswordMutation({
 *   variables: {
 *      Email: // value for 'Email'
 *   },
 * });
 */
export function useDebtorForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorForgotPasswordMutation, DebtorForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorForgotPasswordMutation, DebtorForgotPasswordMutationVariables>(DebtorForgotPasswordDocument, baseOptions);
      }
export type DebtorForgotPasswordMutationHookResult = ReturnType<typeof useDebtorForgotPasswordMutation>;
export type DebtorForgotPasswordMutationResult = ApolloReactCommon.MutationResult<DebtorForgotPasswordMutation>;
export type DebtorForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorForgotPasswordMutation, DebtorForgotPasswordMutationVariables>;
export const DebtorResetPasswordDocument = gql`
    mutation DebtorResetPassword($password: String!, $token: String!) {
  DebtorResetPassword(password: $password, token: $token)
}
    `;
export type DebtorResetPasswordMutationFn = ApolloReactCommon.MutationFunction<DebtorResetPasswordMutation, DebtorResetPasswordMutationVariables>;

/**
 * __useDebtorResetPasswordMutation__
 *
 * To run a mutation, you first call `useDebtorResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorResetPasswordMutation, { data, loading, error }] = useDebtorResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDebtorResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorResetPasswordMutation, DebtorResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorResetPasswordMutation, DebtorResetPasswordMutationVariables>(DebtorResetPasswordDocument, baseOptions);
      }
export type DebtorResetPasswordMutationHookResult = ReturnType<typeof useDebtorResetPasswordMutation>;
export type DebtorResetPasswordMutationResult = ApolloReactCommon.MutationResult<DebtorResetPasswordMutation>;
export type DebtorResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorResetPasswordMutation, DebtorResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LoggedInDebtorProfileDocument = gql`
    query loggedInDebtorProfile {
  loggedInDebtorProfile {
    accessToken
    ID
    Email
    Contact {
      ID
      FullName
      PhoneNo
      Email
      MobileNo
      BirthDate
      Nationality
      Address {
        address
        city
        state
        country
        postCode
      }
    }
    Debtor {
      ID
      DebtorAccount
      CreditTerm
    }
  }
}
    `;

/**
 * __useLoggedInDebtorProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInDebtorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInDebtorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInDebtorProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInDebtorProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInDebtorProfileQuery, LoggedInDebtorProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInDebtorProfileQuery, LoggedInDebtorProfileQueryVariables>(LoggedInDebtorProfileDocument, baseOptions);
      }
export function useLoggedInDebtorProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInDebtorProfileQuery, LoggedInDebtorProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInDebtorProfileQuery, LoggedInDebtorProfileQueryVariables>(LoggedInDebtorProfileDocument, baseOptions);
        }
export type LoggedInDebtorProfileQueryHookResult = ReturnType<typeof useLoggedInDebtorProfileQuery>;
export type LoggedInDebtorProfileLazyQueryHookResult = ReturnType<typeof useLoggedInDebtorProfileLazyQuery>;
export type LoggedInDebtorProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInDebtorProfileQuery, LoggedInDebtorProfileQueryVariables>;
export const DebtorDetailDocument = gql`
    query DebtorDetail($ID: String!) {
  DebtorDetail(ID: $ID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    ARTotalDue
    ARTotalOutstanding
  }
}
    `;

/**
 * __useDebtorDetailQuery__
 *
 * To run a query within a React component, call `useDebtorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
      }
export function useDebtorDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
        }
export type DebtorDetailQueryHookResult = ReturnType<typeof useDebtorDetailQuery>;
export type DebtorDetailLazyQueryHookResult = ReturnType<typeof useDebtorDetailLazyQuery>;
export type DebtorDetailQueryResult = ApolloReactCommon.QueryResult<DebtorDetailQuery, DebtorDetailQueryVariables>;
export const DebtorInfoLineDocument = gql`
    query DebtorInfoLine($HotelID: String!, $DebtorID: String!) {
  ARTotalOutstanding(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastPaymentDT
    TotalOstandingAmount
  }
}
    `;

/**
 * __useDebtorInfoLineQuery__
 *
 * To run a query within a React component, call `useDebtorInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebtorInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
      }
export function useDebtorInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
        }
export type DebtorInfoLineQueryHookResult = ReturnType<typeof useDebtorInfoLineQuery>;
export type DebtorInfoLineLazyQueryHookResult = ReturnType<typeof useDebtorInfoLineLazyQuery>;
export type DebtorInfoLineQueryResult = ApolloReactCommon.QueryResult<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>;
export const LedgerDocumentInfoLineDocument = gql`
    query LedgerDocumentInfoLine($HotelID: String!, $DebtorID: String!) {
  InvoiceInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  DebitNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  CreditNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  OfficialReceiptInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  RefundInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useLedgerDocumentInfoLineQuery__
 *
 * To run a query within a React component, call `useLedgerDocumentInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerDocumentInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerDocumentInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useLedgerDocumentInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
      }
export function useLedgerDocumentInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
        }
export type LedgerDocumentInfoLineQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineQuery>;
export type LedgerDocumentInfoLineLazyQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineLazyQuery>;
export type LedgerDocumentInfoLineQueryResult = ApolloReactCommon.QueryResult<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>;
export const FolioDetailsDocument = gql`
    query FolioDetails($ID: String!, $HotelID: String!) {
  FolioDetails(ID: $ID, HotelID: $HotelID) {
    ID
    FolioNo
    FolioType
    FolioDate
    FolioAmount
    CreatedBy
    Booking {
      ID
      BookingNo
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        IsPrincipal
        Room {
          RoomNo
        }
        Guest {
          Contact {
            ID
            FullName
            PhoneNo
            MobileNo
            Email
            Address {
              address
              address2
              city
              country
              postCode
              state
            }
          }
        }
      }
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
      Booking {
        BookingNo
        Debtor {
          DebtorAccount
        }
      }
      Room {
        RoomNo
      }
    }
    AdvancePayment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    Payment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxAmount
      Reason
      Description
    }
    BillLedger {
      ID
      TrxDate
      BaseAmount
      ServiceCharge
      TaxAmount
      TrxAmount
      ReferenceNo
      IncidentalCharge {
        Description
      }
    }
    RoomCharges {
      TrxDate
      BaseAmount
      Description
      TaxAmount
      ServiceCharge
      RegistrationID
    }
    RoomLedger {
      ID
      TrxAmount
      TrxDate
      TaxAmount
      BaseAmount
      TransactionType
      TransactionID
      Transaction {
        Description
      }
      ServiceCharge
      ReferenceNo
      Description
    }
    DepositLedger {
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
    }
  }
}
    `;

/**
 * __useFolioDetailsQuery__
 *
 * To run a query within a React component, call `useFolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useFolioDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, baseOptions);
      }
export function useFolioDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, baseOptions);
        }
export type FolioDetailsQueryHookResult = ReturnType<typeof useFolioDetailsQuery>;
export type FolioDetailsLazyQueryHookResult = ReturnType<typeof useFolioDetailsLazyQuery>;
export type FolioDetailsQueryResult = ApolloReactCommon.QueryResult<FolioDetailsQuery, FolioDetailsQueryVariables>;
export const GetHotelLatestTaxDocument = gql`
    query GetHotelLatestTax($HotelID: String!) {
  GetLevyTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetPlatformTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetCurrentGovTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetCurrentTourismTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetServiceChargeTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
}
    `;

/**
 * __useGetHotelLatestTaxQuery__
 *
 * To run a query within a React component, call `useGetHotelLatestTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelLatestTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelLatestTaxQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGetHotelLatestTaxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>(GetHotelLatestTaxDocument, baseOptions);
      }
export function useGetHotelLatestTaxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>(GetHotelLatestTaxDocument, baseOptions);
        }
export type GetHotelLatestTaxQueryHookResult = ReturnType<typeof useGetHotelLatestTaxQuery>;
export type GetHotelLatestTaxLazyQueryHookResult = ReturnType<typeof useGetHotelLatestTaxLazyQuery>;
export type GetHotelLatestTaxQueryResult = ApolloReactCommon.QueryResult<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>;
export const InvoiceListingsDocument = gql`
    query InvoiceListings($HotelID: String!, $DebtorID: String!) {
  InvoiceListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
      BaseAmount
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      ModifiedDT
      CreditID
      AllocationAmount
      CreditDetail {
        ID
        DocumentNo
      }
    }
    CityLedgerAttachment {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useInvoiceListingsQuery__
 *
 * To run a query within a React component, call `useInvoiceListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useInvoiceListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
      }
export function useInvoiceListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
        }
export type InvoiceListingsQueryHookResult = ReturnType<typeof useInvoiceListingsQuery>;
export type InvoiceListingsLazyQueryHookResult = ReturnType<typeof useInvoiceListingsLazyQuery>;
export type InvoiceListingsQueryResult = ApolloReactCommon.QueryResult<InvoiceListingsQuery, InvoiceListingsQueryVariables>;
export const DebitNoteListingsDocument = gql`
    query DebitNoteListings($HotelID: String!, $DebtorID: String!) {
  DebitNoteListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      ModifiedDT
      CreditID
      CreditDetail {
        ID
        DocumentNo
      }
      AllocationAmount
    }
    CityLedgerAttachment {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useDebitNoteListingsQuery__
 *
 * To run a query within a React component, call `useDebitNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebitNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
      }
export function useDebitNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
        }
export type DebitNoteListingsQueryHookResult = ReturnType<typeof useDebitNoteListingsQuery>;
export type DebitNoteListingsLazyQueryHookResult = ReturnType<typeof useDebitNoteListingsLazyQuery>;
export type DebitNoteListingsQueryResult = ApolloReactCommon.QueryResult<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>;
export const CreditNoteListingsDocument = gql`
    query CreditNoteListings($HotelID: String!, $DebtorID: String!) {
  CreditNoteListings(HotelID: $HotelID, DebtorID: $DebtorID)
}
    `;

/**
 * __useCreditNoteListingsQuery__
 *
 * To run a query within a React component, call `useCreditNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCreditNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
      }
export function useCreditNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
        }
export type CreditNoteListingsQueryHookResult = ReturnType<typeof useCreditNoteListingsQuery>;
export type CreditNoteListingsLazyQueryHookResult = ReturnType<typeof useCreditNoteListingsLazyQuery>;
export type CreditNoteListingsQueryResult = ApolloReactCommon.QueryResult<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>;
export const OfficialReceiptListingsDocument = gql`
    query OfficialReceiptListings($HotelID: String!, $DebtorID: String, $Status: String, $StartDate: DateTime, $EndDate: DateTime) {
  OfficialReceiptListings(HotelID: $HotelID, DebtorID: $DebtorID, Status: $Status, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useOfficialReceiptListingsQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      Status: // value for 'Status'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useOfficialReceiptListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
      }
export function useOfficialReceiptListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
        }
export type OfficialReceiptListingsQueryHookResult = ReturnType<typeof useOfficialReceiptListingsQuery>;
export type OfficialReceiptListingsLazyQueryHookResult = ReturnType<typeof useOfficialReceiptListingsLazyQuery>;
export type OfficialReceiptListingsQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>;
export const RefundListingsDocument = gql`
    query RefundListings($HotelID: String!, $DebtorID: String!) {
  RefundListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    CreatedBy
    ModifiedBy
    bankDetails {
      Name
    }
    Allocation {
      ID
      HotelID
      DebtorID
      ModifiedDT
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      CreditDetail {
        ID
        DocumentNo
      }
      AllocationAmount
    }
    CityLedgerAttachment {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useRefundListingsQuery__
 *
 * To run a query within a React component, call `useRefundListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useRefundListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
      }
export function useRefundListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
        }
export type RefundListingsQueryHookResult = ReturnType<typeof useRefundListingsQuery>;
export type RefundListingsLazyQueryHookResult = ReturnType<typeof useRefundListingsLazyQuery>;
export type RefundListingsQueryResult = ApolloReactCommon.QueryResult<RefundListingsQuery, RefundListingsQueryVariables>;
export const BiUsersLisitingDocument = gql`
    query BIUsersLisiting($ID: String!) {
  BIUsersLisiting(ID: $ID) {
    name
    userName
  }
}
    `;

/**
 * __useBiUsersLisitingQuery__
 *
 * To run a query within a React component, call `useBiUsersLisitingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiUsersLisitingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiUsersLisitingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBiUsersLisitingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>(BiUsersLisitingDocument, baseOptions);
      }
export function useBiUsersLisitingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>(BiUsersLisitingDocument, baseOptions);
        }
export type BiUsersLisitingQueryHookResult = ReturnType<typeof useBiUsersLisitingQuery>;
export type BiUsersLisitingLazyQueryHookResult = ReturnType<typeof useBiUsersLisitingLazyQuery>;
export type BiUsersLisitingQueryResult = ApolloReactCommon.QueryResult<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>;
export const BookFormDocument = gql`
    query BookForm($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  SegmentListing(HotelID: $HotelID) {
    ID
    IsActive
    Description
  }
  RoomTypeListing(HotelID: $HotelID) {
    ID
    Code
    Description
  }
  PromotionListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Code
    Description
    DiscountType
    DiscountValue
    PromotionDetail {
      ID
      RatePolicyID
      Value
    }
  }
  HotelSalesChannelListing(HotelID: $HotelID) {
    ID
    Description
    IsActive
  }
  DebtorListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    SalesChannel
    Login
    Password
  }
}
    `;

/**
 * __useBookFormQuery__
 *
 * To run a query within a React component, call `useBookFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookFormQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBookFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookFormQuery, BookFormQueryVariables>) {
        return ApolloReactHooks.useQuery<BookFormQuery, BookFormQueryVariables>(BookFormDocument, baseOptions);
      }
export function useBookFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookFormQuery, BookFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookFormQuery, BookFormQueryVariables>(BookFormDocument, baseOptions);
        }
export type BookFormQueryHookResult = ReturnType<typeof useBookFormQuery>;
export type BookFormLazyQueryHookResult = ReturnType<typeof useBookFormLazyQuery>;
export type BookFormQueryResult = ApolloReactCommon.QueryResult<BookFormQuery, BookFormQueryVariables>;
export const CancelReasonListingDocument = gql`
    query CancelReasonListing($HotelID: String!) {
  CancelReasonListing(HotelID: $HotelID) {
    ID
    Description
  }
}
    `;

/**
 * __useCancelReasonListingQuery__
 *
 * To run a query within a React component, call `useCancelReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelReasonListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCancelReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CancelReasonListingQuery, CancelReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CancelReasonListingQuery, CancelReasonListingQueryVariables>(CancelReasonListingDocument, baseOptions);
      }
export function useCancelReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CancelReasonListingQuery, CancelReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CancelReasonListingQuery, CancelReasonListingQueryVariables>(CancelReasonListingDocument, baseOptions);
        }
export type CancelReasonListingQueryHookResult = ReturnType<typeof useCancelReasonListingQuery>;
export type CancelReasonListingLazyQueryHookResult = ReturnType<typeof useCancelReasonListingLazyQuery>;
export type CancelReasonListingQueryResult = ApolloReactCommon.QueryResult<CancelReasonListingQuery, CancelReasonListingQueryVariables>;
export const GuestProfileListingByIdDocument = gql`
    query GuestProfileListingByID($RegistrationIDs: [String!]!) {
  GuestProfileListingByID(RegistrationIDs: $RegistrationIDs) {
    ID
    RoomTypeID
    RatePolicyID
    RoomID
    RoomType {
      MaxGuest
    }
    BookingID
    IsPrincipal
    SharerNo
    Guest {
      ID
      ICGallery {
        ID
        BucketFileName
        ImageURL
      }
      GuestHistoryList {
        ID
        Booking {
          ArrivalDate
          DepartureDate
        }
        RoomType {
          ID
          Code
        }
        Room {
          ID
          RoomNo
        }
        RatePolicy {
          ID
          RateCode
        }
        RoomRate
        TotalRoomPrice
        TotalRoomTax
        TotalPackagePrice
        TotalPrice
      }
      VehicleNo
      NewsLetter
      IsVIP
      Contact {
        ID
        FullName
        Email
        MobileNo
        NRIC
        PassportNo
        Nationality
        Address {
          address
          country
          city
          state
          postCode
        }
      }
    }
  }
}
    `;

/**
 * __useGuestProfileListingByIdQuery__
 *
 * To run a query within a React component, call `useGuestProfileListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileListingByIdQuery({
 *   variables: {
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useGuestProfileListingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>(GuestProfileListingByIdDocument, baseOptions);
      }
export function useGuestProfileListingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>(GuestProfileListingByIdDocument, baseOptions);
        }
export type GuestProfileListingByIdQueryHookResult = ReturnType<typeof useGuestProfileListingByIdQuery>;
export type GuestProfileListingByIdLazyQueryHookResult = ReturnType<typeof useGuestProfileListingByIdLazyQuery>;
export type GuestProfileListingByIdQueryResult = ApolloReactCommon.QueryResult<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>;
export const BookingListingDocument = gql`
    query BookingListing($HotelID: String, $DebtorType: String!, $DebtorProfileID: String) {
  BookingListing(HotelID: $HotelID, DebtorType: $DebtorType, DebtorProfileID: $DebtorProfileID) {
    ID
    AccountID
    HotelID
    ChargeToCityLedger
    TotalAdvPayment
    Hotel {
      HotelName
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    IsGroupBooking
    Contact {
      FullName
      PhoneNo
      Email
      Nationality
      Address {
        address
        country
        city
        state
        postCode
      }
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
    }
    CreatedDT
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    GroupName
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    Reason
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
        }
      }
      RoomRate
      TotalRoomPrice
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useBookingListingQuery__
 *
 * To run a query within a React component, call `useBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorProfileID: // value for 'DebtorProfileID'
 *   },
 * });
 */
export function useBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingListingQuery, BookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingListingQuery, BookingListingQueryVariables>(BookingListingDocument, baseOptions);
      }
export function useBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingListingQuery, BookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingListingQuery, BookingListingQueryVariables>(BookingListingDocument, baseOptions);
        }
export type BookingListingQueryHookResult = ReturnType<typeof useBookingListingQuery>;
export type BookingListingLazyQueryHookResult = ReturnType<typeof useBookingListingLazyQuery>;
export type BookingListingQueryResult = ApolloReactCommon.QueryResult<BookingListingQuery, BookingListingQueryVariables>;
export const BookingDetailsDocument = gql`
    query BookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Hotel {
      HotelName
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    CreatedDT
    Contact {
      FullName
      PhoneNo
      Email
      Nationality
      Address {
        address
        country
        city
        state
        postCode
      }
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
    }
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    GroupName
    IsGroupBooking
    ChargeToCityLedger
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    Reason
    Remark
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      IncidentalChargesCount
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        IdPicture
        ICGallery {
          ID
          ImageURL
        }
        VehicleNo
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      RoomRate
      TotalRoomPrice
      TotalOutStandingAmount
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
    }
    AllRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      IncidentalChargesCount
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      RoomRate
      TotalRoomPrice
      TotalOutStandingAmount
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
      }
export function useBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
        }
export type BookingDetailsQueryHookResult = ReturnType<typeof useBookingDetailsQuery>;
export type BookingDetailsLazyQueryHookResult = ReturnType<typeof useBookingDetailsLazyQuery>;
export type BookingDetailsQueryResult = ApolloReactCommon.QueryResult<BookingDetailsQuery, BookingDetailsQueryVariables>;
export const AvailabilityListingDocument = gql`
    query AvailabilityListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime, $RoomTypeIDs: [String!], $TravelAgentID: String) {
  AvailabilityListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, RoomTypeIDs: $RoomTypeIDs, TravelAgentID: $TravelAgentID) {
    RoomTypesList {
      ID
      Code
      Description
      TotalRoom
      RoomTypeAvailability {
        ID
        EffectiveDate
        BookedRoom
        BlockedRoom
        AllottedRoom
        TotalOccupied
        TotalVacant
        SeasonType
      }
    }
    Occupancy {
      EffectiveDate
      Season
      Occupancy
      TotalOccupied
      TotalRoom
    }
  }
}
    `;

/**
 * __useAvailabilityListingQuery__
 *
 * To run a query within a React component, call `useAvailabilityListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      RoomTypeIDs: // value for 'RoomTypeIDs'
 *      TravelAgentID: // value for 'TravelAgentID'
 *   },
 * });
 */
export function useAvailabilityListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, baseOptions);
      }
export function useAvailabilityListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, baseOptions);
        }
export type AvailabilityListingQueryHookResult = ReturnType<typeof useAvailabilityListingQuery>;
export type AvailabilityListingLazyQueryHookResult = ReturnType<typeof useAvailabilityListingLazyQuery>;
export type AvailabilityListingQueryResult = ApolloReactCommon.QueryResult<AvailabilityListingQuery, AvailabilityListingQueryVariables>;
export const PackagePriceListingDocument = gql`
    query PackagePriceListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String, $DebtorID: String, $RoomTypeID: String, $RatePolicyID: String, $NoOfRoom: Float) {
  PackagePriceListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, RatePolicyID: $RatePolicyID, NoOfRoom: $NoOfRoom) {
    RoomTypeID
    RoomTypeCode
    RoomTypeName
    RatePolicyID
    Code
    TotalVacant
    Pricing {
      Date
      Type
      Price
      BasePrice
      Packages {
        ID
        IncidentalChargeID
        Description
        IncludeRate
        ComputationRules
        ServiceCharge
        Tax
        Amount
      }
    }
  }
}
    `;

/**
 * __usePackagePriceListingQuery__
 *
 * To run a query within a React component, call `usePackagePriceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagePriceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagePriceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *      NoOfRoom: // value for 'NoOfRoom'
 *   },
 * });
 */
export function usePackagePriceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, baseOptions);
      }
export function usePackagePriceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, baseOptions);
        }
export type PackagePriceListingQueryHookResult = ReturnType<typeof usePackagePriceListingQuery>;
export type PackagePriceListingLazyQueryHookResult = ReturnType<typeof usePackagePriceListingLazyQuery>;
export type PackagePriceListingQueryResult = ApolloReactCommon.QueryResult<PackagePriceListingQuery, PackagePriceListingQueryVariables>;
export const AdvancePaymentListingDocument = gql`
    query AdvancePaymentListing($BookingID: String!) {
  AdvancePaymentListing(BookingID: $BookingID) {
    ID
    PaymentDate
    HotelID
    BookingID
    RegistrationID
    PaymentClass
    ReceiptNo
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    PaymentStatus
    IsPosted
    ReversedAdvancePaymentID
  }
}
    `;

/**
 * __useAdvancePaymentListingQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentListingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useAdvancePaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>(AdvancePaymentListingDocument, baseOptions);
      }
export function useAdvancePaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>(AdvancePaymentListingDocument, baseOptions);
        }
export type AdvancePaymentListingQueryHookResult = ReturnType<typeof useAdvancePaymentListingQuery>;
export type AdvancePaymentListingLazyQueryHookResult = ReturnType<typeof useAdvancePaymentListingLazyQuery>;
export type AdvancePaymentListingQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>;
export const ChangeOfDateCheckAvailabilityDocument = gql`
    query ChangeOfDateCheckAvailability($BookingID: String!, $HotelID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!) {
  ChangeOfDateCheckAvailability(BookingID: $BookingID, HotelID: $HotelID, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate)
}
    `;

/**
 * __useChangeOfDateCheckAvailabilityQuery__
 *
 * To run a query within a React component, call `useChangeOfDateCheckAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOfDateCheckAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOfDateCheckAvailabilityQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *   },
 * });
 */
export function useChangeOfDateCheckAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>(ChangeOfDateCheckAvailabilityDocument, baseOptions);
      }
export function useChangeOfDateCheckAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>(ChangeOfDateCheckAvailabilityDocument, baseOptions);
        }
export type ChangeOfDateCheckAvailabilityQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityQuery>;
export type ChangeOfDateCheckAvailabilityLazyQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityLazyQuery>;
export type ChangeOfDateCheckAvailabilityQueryResult = ApolloReactCommon.QueryResult<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>;
export const AdvancePaymentDetailDocument = gql`
    query AdvancePaymentDetail($BookingID: String!, $OrderID: String!) {
  AdvancePaymentDetail(BookingID: $BookingID, OrderID: $OrderID) {
    ID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;

/**
 * __useAdvancePaymentDetailQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentDetailQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      OrderID: // value for 'OrderID'
 *   },
 * });
 */
export function useAdvancePaymentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>(AdvancePaymentDetailDocument, baseOptions);
      }
export function useAdvancePaymentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>(AdvancePaymentDetailDocument, baseOptions);
        }
export type AdvancePaymentDetailQueryHookResult = ReturnType<typeof useAdvancePaymentDetailQuery>;
export type AdvancePaymentDetailLazyQueryHookResult = ReturnType<typeof useAdvancePaymentDetailLazyQuery>;
export type AdvancePaymentDetailQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>;
export const BookingInsertDocument = gql`
    mutation BookingInsert($BookingInput: BookingInput!, $ContactInput: ContactInput!, $RegistrationInput: [RegistrationInput!]!, $DebtorType: String) {
  BookingInsert(BookingInput: $BookingInput, ContactInput: $ContactInput, RegistrationInput: $RegistrationInput, DebtorType: $DebtorType)
}
    `;
export type BookingInsertMutationFn = ApolloReactCommon.MutationFunction<BookingInsertMutation, BookingInsertMutationVariables>;

/**
 * __useBookingInsertMutation__
 *
 * To run a mutation, you first call `useBookingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingInsertMutation, { data, loading, error }] = useBookingInsertMutation({
 *   variables: {
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *      RegistrationInput: // value for 'RegistrationInput'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBookingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingInsertMutation, BookingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingInsertMutation, BookingInsertMutationVariables>(BookingInsertDocument, baseOptions);
      }
export type BookingInsertMutationHookResult = ReturnType<typeof useBookingInsertMutation>;
export type BookingInsertMutationResult = ApolloReactCommon.MutationResult<BookingInsertMutation>;
export type BookingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingInsertMutation, BookingInsertMutationVariables>;
export const BookingUpdateDocument = gql`
    mutation BookingUpdate($BookingInput: BookingInput!, $ContactInput: ContactInput, $SpecialRequestInput: [SpecialRequestInput!]) {
  BookingUpdate(BookingInput: $BookingInput, ContactInput: $ContactInput, SpecialRequestInput: $SpecialRequestInput)
}
    `;
export type BookingUpdateMutationFn = ApolloReactCommon.MutationFunction<BookingUpdateMutation, BookingUpdateMutationVariables>;

/**
 * __useBookingUpdateMutation__
 *
 * To run a mutation, you first call `useBookingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingUpdateMutation, { data, loading, error }] = useBookingUpdateMutation({
 *   variables: {
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *      SpecialRequestInput: // value for 'SpecialRequestInput'
 *   },
 * });
 */
export function useBookingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingUpdateMutation, BookingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingUpdateMutation, BookingUpdateMutationVariables>(BookingUpdateDocument, baseOptions);
      }
export type BookingUpdateMutationHookResult = ReturnType<typeof useBookingUpdateMutation>;
export type BookingUpdateMutationResult = ApolloReactCommon.MutationResult<BookingUpdateMutation>;
export type BookingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingUpdateMutation, BookingUpdateMutationVariables>;
export const ReinstateBookingDocument = gql`
    mutation ReinstateBooking($BookingID: String!, $HotelID: String!, $Remark: String, $ReasonID: String) {
  ReinstateBooking(BookingID: $BookingID, HotelID: $HotelID, Remark: $Remark, ReasonID: $ReasonID)
}
    `;
export type ReinstateBookingMutationFn = ApolloReactCommon.MutationFunction<ReinstateBookingMutation, ReinstateBookingMutationVariables>;

/**
 * __useReinstateBookingMutation__
 *
 * To run a mutation, you first call `useReinstateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateBookingMutation, { data, loading, error }] = useReinstateBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      Remark: // value for 'Remark'
 *      ReasonID: // value for 'ReasonID'
 *   },
 * });
 */
export function useReinstateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReinstateBookingMutation, ReinstateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<ReinstateBookingMutation, ReinstateBookingMutationVariables>(ReinstateBookingDocument, baseOptions);
      }
export type ReinstateBookingMutationHookResult = ReturnType<typeof useReinstateBookingMutation>;
export type ReinstateBookingMutationResult = ApolloReactCommon.MutationResult<ReinstateBookingMutation>;
export type ReinstateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<ReinstateBookingMutation, ReinstateBookingMutationVariables>;
export const CancelBookingDocument = gql`
    mutation CancelBooking($BookingID: String!, $HotelID: String!, $ReasonID: String, $Remark: String) {
  CancelBooking(BookingID: $BookingID, HotelID: $HotelID, ReasonID: $ReasonID, Remark: $Remark)
}
    `;
export type CancelBookingMutationFn = ApolloReactCommon.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, baseOptions);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = ApolloReactCommon.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const BookingRoomTypeUpdateDocument = gql`
    mutation BookingRoomTypeUpdate($EditRegInput: EditRegInput!) {
  BookingRoomTypeUpdate(EditRegInput: $EditRegInput)
}
    `;
export type BookingRoomTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;

/**
 * __useBookingRoomTypeUpdateMutation__
 *
 * To run a mutation, you first call `useBookingRoomTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingRoomTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingRoomTypeUpdateMutation, { data, loading, error }] = useBookingRoomTypeUpdateMutation({
 *   variables: {
 *      EditRegInput: // value for 'EditRegInput'
 *   },
 * });
 */
export function useBookingRoomTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>(BookingRoomTypeUpdateDocument, baseOptions);
      }
export type BookingRoomTypeUpdateMutationHookResult = ReturnType<typeof useBookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationResult = ApolloReactCommon.MutationResult<BookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;
export const GuestProfileInsertDocument = gql`
    mutation GuestProfileInsert($RegistrationID: String!, $ContactInput: ContactInput!) {
  GuestProfileInsert(RegistrationID: $RegistrationID, ContactInput: $ContactInput) {
    ID
  }
}
    `;
export type GuestProfileInsertMutationFn = ApolloReactCommon.MutationFunction<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>;

/**
 * __useGuestProfileInsertMutation__
 *
 * To run a mutation, you first call `useGuestProfileInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestProfileInsertMutation, { data, loading, error }] = useGuestProfileInsertMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useGuestProfileInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>(GuestProfileInsertDocument, baseOptions);
      }
export type GuestProfileInsertMutationHookResult = ReturnType<typeof useGuestProfileInsertMutation>;
export type GuestProfileInsertMutationResult = ApolloReactCommon.MutationResult<GuestProfileInsertMutation>;
export type GuestProfileInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>;
export const GuestContactUpdateDocument = gql`
    mutation GuestContactUpdate($ContactInput: ContactInput!, $VehicleNo: String, $ArrivalDate: DateTime, $DepartureDate: DateTime, $RegistrationID: String, $BookingID: String, $HotelID: String, $IsPrincipal: Boolean, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  GuestContactUpdate(ContactInput: $ContactInput, VehicleNo: $VehicleNo, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate, RegistrationID: $RegistrationID, BookingID: $BookingID, HotelID: $HotelID, IsPrincipal: $IsPrincipal, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type GuestContactUpdateMutationFn = ApolloReactCommon.MutationFunction<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;

/**
 * __useGuestContactUpdateMutation__
 *
 * To run a mutation, you first call `useGuestContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestContactUpdateMutation, { data, loading, error }] = useGuestContactUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      VehicleNo: // value for 'VehicleNo'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      IsPrincipal: // value for 'IsPrincipal'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useGuestContactUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>(GuestContactUpdateDocument, baseOptions);
      }
export type GuestContactUpdateMutationHookResult = ReturnType<typeof useGuestContactUpdateMutation>;
export type GuestContactUpdateMutationResult = ApolloReactCommon.MutationResult<GuestContactUpdateMutation>;
export type GuestContactUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;
export const GuestProfileDeleteDocument = gql`
    mutation GuestProfileDelete($RegistrationID: String, $BookingID: String, $GuestID: String) {
  GuestProfileDelete(RegistrationID: $RegistrationID, BookingID: $BookingID, GuestID: $GuestID)
}
    `;
export type GuestProfileDeleteMutationFn = ApolloReactCommon.MutationFunction<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;

/**
 * __useGuestProfileDeleteMutation__
 *
 * To run a mutation, you first call `useGuestProfileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestProfileDeleteMutation, { data, loading, error }] = useGuestProfileDeleteMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      GuestID: // value for 'GuestID'
 *   },
 * });
 */
export function useGuestProfileDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>(GuestProfileDeleteDocument, baseOptions);
      }
export type GuestProfileDeleteMutationHookResult = ReturnType<typeof useGuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationResult = ApolloReactCommon.MutationResult<GuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;
export const AdvancePaymentInsertDocument = gql`
    mutation AdvancePaymentInsert($AdvancePaymentInput: AdvancePaymentInput!) {
  AdvancePaymentInsert(AdvancePaymentInput: $AdvancePaymentInput) {
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;
export type AdvancePaymentInsertMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>;

/**
 * __useAdvancePaymentInsertMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentInsertMutation, { data, loading, error }] = useAdvancePaymentInsertMutation({
 *   variables: {
 *      AdvancePaymentInput: // value for 'AdvancePaymentInput'
 *   },
 * });
 */
export function useAdvancePaymentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>(AdvancePaymentInsertDocument, baseOptions);
      }
export type AdvancePaymentInsertMutationHookResult = ReturnType<typeof useAdvancePaymentInsertMutation>;
export type AdvancePaymentInsertMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentInsertMutation>;
export type AdvancePaymentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>;
export const AdvancePaymentRefundDocument = gql`
    mutation AdvancePaymentRefund($AdvancePaymentInput: [AdvancePaymentInput!]!) {
  AdvancePaymentRefund(AdvancePaymentInput: $AdvancePaymentInput)
}
    `;
export type AdvancePaymentRefundMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>;

/**
 * __useAdvancePaymentRefundMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentRefundMutation, { data, loading, error }] = useAdvancePaymentRefundMutation({
 *   variables: {
 *      AdvancePaymentInput: // value for 'AdvancePaymentInput'
 *   },
 * });
 */
export function useAdvancePaymentRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>(AdvancePaymentRefundDocument, baseOptions);
      }
export type AdvancePaymentRefundMutationHookResult = ReturnType<typeof useAdvancePaymentRefundMutation>;
export type AdvancePaymentRefundMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentRefundMutation>;
export type AdvancePaymentRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>;
export const ImageUploadDocument = gql`
    mutation ImageUpload($AttachmentInput: [Upload!]!) {
  ImageUpload(AttachmentInput: $AttachmentInput) {
    ImageURL
  }
}
    `;
export type ImageUploadMutationFn = ApolloReactCommon.MutationFunction<ImageUploadMutation, ImageUploadMutationVariables>;

/**
 * __useImageUploadMutation__
 *
 * To run a mutation, you first call `useImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadMutation, { data, loading, error }] = useImageUploadMutation({
 *   variables: {
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useImageUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImageUploadMutation, ImageUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<ImageUploadMutation, ImageUploadMutationVariables>(ImageUploadDocument, baseOptions);
      }
export type ImageUploadMutationHookResult = ReturnType<typeof useImageUploadMutation>;
export type ImageUploadMutationResult = ApolloReactCommon.MutationResult<ImageUploadMutation>;
export type ImageUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<ImageUploadMutation, ImageUploadMutationVariables>;
export const ContractDetailsDocument = gql`
    query ContractDetails($DebtorID: String!, $StartDate: String!, $EndDate: String!) {
  ContractDetails(DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ContractNo
    Description
    StartDate
    EndDate
    CutOffDays
    PaymentTerms
    Remarks
    ContractRate {
      ID
      RatePolicy {
        ID
        RateCode
        Description
      }
    }
  }
}
    `;

/**
 * __useContractDetailsQuery__
 *
 * To run a query within a React component, call `useContractDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDetailsQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useContractDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractDetailsQuery, ContractDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractDetailsQuery, ContractDetailsQueryVariables>(ContractDetailsDocument, baseOptions);
      }
export function useContractDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractDetailsQuery, ContractDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractDetailsQuery, ContractDetailsQueryVariables>(ContractDetailsDocument, baseOptions);
        }
export type ContractDetailsQueryHookResult = ReturnType<typeof useContractDetailsQuery>;
export type ContractDetailsLazyQueryHookResult = ReturnType<typeof useContractDetailsLazyQuery>;
export type ContractDetailsQueryResult = ApolloReactCommon.QueryResult<ContractDetailsQuery, ContractDetailsQueryVariables>;
export const RatePolicyListingDocument = gql`
    query RatePolicyListing($HotelID: String!, $DebtorType: String) {
  RatePolicyListing(HotelID: $HotelID, DebtorType: $DebtorType) {
    ID
    HotelID
    IsActive
    Category
    RateCode
    Description
    OnlineRate
  }
}
    `;

/**
 * __useRatePolicyListingQuery__
 *
 * To run a query within a React component, call `useRatePolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useRatePolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyListingQuery, RatePolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyListingQuery, RatePolicyListingQueryVariables>(RatePolicyListingDocument, baseOptions);
      }
export function useRatePolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyListingQuery, RatePolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyListingQuery, RatePolicyListingQueryVariables>(RatePolicyListingDocument, baseOptions);
        }
export type RatePolicyListingQueryHookResult = ReturnType<typeof useRatePolicyListingQuery>;
export type RatePolicyListingLazyQueryHookResult = ReturnType<typeof useRatePolicyListingLazyQuery>;
export type RatePolicyListingQueryResult = ApolloReactCommon.QueryResult<RatePolicyListingQuery, RatePolicyListingQueryVariables>;
export const BookingUpdateV2Document = gql`
    mutation BookingUpdateV2($EditRegInput: [EditRegInput!], $SpecialRequestInput: [SpecialRequestInput!], $ContactInput: ContactInput, $BookingInput: BookingInput!, $BookingID: String!) {
  BookingUpdateV2(EditRegInput: $EditRegInput, SpecialRequestInput: $SpecialRequestInput, ContactInput: $ContactInput, BookingInput: $BookingInput, BookingID: $BookingID)
}
    `;
export type BookingUpdateV2MutationFn = ApolloReactCommon.MutationFunction<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>;

/**
 * __useBookingUpdateV2Mutation__
 *
 * To run a mutation, you first call `useBookingUpdateV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingUpdateV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingUpdateV2Mutation, { data, loading, error }] = useBookingUpdateV2Mutation({
 *   variables: {
 *      EditRegInput: // value for 'EditRegInput'
 *      SpecialRequestInput: // value for 'SpecialRequestInput'
 *      ContactInput: // value for 'ContactInput'
 *      BookingInput: // value for 'BookingInput'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useBookingUpdateV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>) {
        return ApolloReactHooks.useMutation<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>(BookingUpdateV2Document, baseOptions);
      }
export type BookingUpdateV2MutationHookResult = ReturnType<typeof useBookingUpdateV2Mutation>;
export type BookingUpdateV2MutationResult = ApolloReactCommon.MutationResult<BookingUpdateV2Mutation>;
export type BookingUpdateV2MutationOptions = ApolloReactCommon.BaseMutationOptions<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>;
export const NoOfPaxUpdateDocument = gql`
    mutation NoOfPaxUpdate($BookingID: String!, $NoOfAdult: Float!, $SharerNo: Float!, $RoomTypeID: String!) {
  NoOfPaxUpdate(BookingID: $BookingID, NoOfAdult: $NoOfAdult, SharerNo: $SharerNo, RoomTypeID: $RoomTypeID)
}
    `;
export type NoOfPaxUpdateMutationFn = ApolloReactCommon.MutationFunction<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>;

/**
 * __useNoOfPaxUpdateMutation__
 *
 * To run a mutation, you first call `useNoOfPaxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoOfPaxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noOfPaxUpdateMutation, { data, loading, error }] = useNoOfPaxUpdateMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      NoOfAdult: // value for 'NoOfAdult'
 *      SharerNo: // value for 'SharerNo'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useNoOfPaxUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>(NoOfPaxUpdateDocument, baseOptions);
      }
export type NoOfPaxUpdateMutationHookResult = ReturnType<typeof useNoOfPaxUpdateMutation>;
export type NoOfPaxUpdateMutationResult = ApolloReactCommon.MutationResult<NoOfPaxUpdateMutation>;
export type NoOfPaxUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>;
export const GuestInsertDocument = gql`
    mutation GuestInsert($ContactInput: [ContactInput!]!, $BookingID: String!, $RegistrationID: String!, $AttachmentInput: [Upload!]) {
  GuestInsert(ContactInput: $ContactInput, BookingID: $BookingID, RegistrationID: $RegistrationID, AttachmentInput: $AttachmentInput)
}
    `;
export type GuestInsertMutationFn = ApolloReactCommon.MutationFunction<GuestInsertMutation, GuestInsertMutationVariables>;

/**
 * __useGuestInsertMutation__
 *
 * To run a mutation, you first call `useGuestInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestInsertMutation, { data, loading, error }] = useGuestInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useGuestInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestInsertMutation, GuestInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestInsertMutation, GuestInsertMutationVariables>(GuestInsertDocument, baseOptions);
      }
export type GuestInsertMutationHookResult = ReturnType<typeof useGuestInsertMutation>;
export type GuestInsertMutationResult = ApolloReactCommon.MutationResult<GuestInsertMutation>;
export type GuestInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestInsertMutation, GuestInsertMutationVariables>;
export const SegmentListingDocument = gql`
    query SegmentListing($HotelID: String!, $IsActive: Boolean) {
  SegmentListing(HotelID: $HotelID, IsActive: $IsActive) {
    ID
    Description
    IsActive
    DisplaySequence
  }
}
    `;

/**
 * __useSegmentListingQuery__
 *
 * To run a query within a React component, call `useSegmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useSegmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, baseOptions);
      }
export function useSegmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, baseOptions);
        }
export type SegmentListingQueryHookResult = ReturnType<typeof useSegmentListingQuery>;
export type SegmentListingLazyQueryHookResult = ReturnType<typeof useSegmentListingLazyQuery>;
export type SegmentListingQueryResult = ApolloReactCommon.QueryResult<SegmentListingQuery, SegmentListingQueryVariables>;
export const TravelAgentContractListingDocument = gql`
    query TravelAgentContractListing($DebtorID: String!) {
  TravelAgentContractListing(DebtorID: $DebtorID) {
    ID
    DebtorID
    ContractNo
    Description
    StartDate
    EndDate
    RoomUpgrade
    Elastic
    Allotment
    CutOffDays
    PaymentTerms
    AllowDeleteContract
    Remarks
    IsActive
    IsCMS
    ChannelManager
    Debtor {
      ID
      Name
    }
    ContractRate {
      ID
      RatePolicy {
        ID
        Description
        RateCode
        RateEffective {
          ID
          SeasonalRate {
            ID
            StandardRate
            RoomType {
              Description
              ID
            }
          }
        }
      }
    }
    ContractAllotmentByRoomType {
      ID
      AllowDeleteAllotment
      ID
      RoomTypeID
      InitialAllot
      Adjust
      SysAdjust
      PickUp
      SysRelease
      AllotDate
      AllowDeleteAllotment
      RoomType {
        ID
        Code
        Description
      }
    }
  }
}
    `;

/**
 * __useTravelAgentContractListingQuery__
 *
 * To run a query within a React component, call `useTravelAgentContractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentContractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentContractListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useTravelAgentContractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>(TravelAgentContractListingDocument, baseOptions);
      }
export function useTravelAgentContractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>(TravelAgentContractListingDocument, baseOptions);
        }
export type TravelAgentContractListingQueryHookResult = ReturnType<typeof useTravelAgentContractListingQuery>;
export type TravelAgentContractListingLazyQueryHookResult = ReturnType<typeof useTravelAgentContractListingLazyQuery>;
export type TravelAgentContractListingQueryResult = ApolloReactCommon.QueryResult<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>;
export const AllotmentListingDocument = gql`
    query AllotmentListing($ContractID: String!, $RoomTypeID: String, $StartDate: DateTime!, $EndDate: DateTime!) {
  AllotmentListing(ContractID: $ContractID, RoomTypeID: $RoomTypeID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    RoomTypeID
    InitialAllot
    Adjust
    SysAdjust
    PickUp
    SysRelease
    AllotDate
    IsActive
  }
}
    `;

/**
 * __useAllotmentListingQuery__
 *
 * To run a query within a React component, call `useAllotmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentListingQuery({
 *   variables: {
 *      ContractID: // value for 'ContractID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useAllotmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentListingQuery, AllotmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentListingQuery, AllotmentListingQueryVariables>(AllotmentListingDocument, baseOptions);
      }
export function useAllotmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentListingQuery, AllotmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentListingQuery, AllotmentListingQueryVariables>(AllotmentListingDocument, baseOptions);
        }
export type AllotmentListingQueryHookResult = ReturnType<typeof useAllotmentListingQuery>;
export type AllotmentListingLazyQueryHookResult = ReturnType<typeof useAllotmentListingLazyQuery>;
export type AllotmentListingQueryResult = ApolloReactCommon.QueryResult<AllotmentListingQuery, AllotmentListingQueryVariables>;
export const HotelsByCityDocument = gql`
    query HotelsByCity($CheckOut: DateTime, $CheckIn: DateTime, $City: String!, $NoOfRooms: Float, $NoOfGuests: Float, $DebtorProfileID: String, $DebtorType: String) {
  HotelsByCity(CheckOut: $CheckOut, CheckIn: $CheckIn, City: $City, NoOfRooms: $NoOfRooms, NoOfGuests: $NoOfGuests, DebtorProfileID: $DebtorProfileID, DebtorType: $DebtorType) {
    ID
    Logo
    HotelDebtor {
      ID
    }
    HotelName
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    HotelGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
        SequenceNo
      }
    }
    AboutHotelImage {
      ID
      BucketFileName
      ImageURL
    }
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
    Price {
      Price
      BasePrice
      Date
      Type
    }
  }
}
    `;

/**
 * __useHotelsByCityQuery__
 *
 * To run a query within a React component, call `useHotelsByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelsByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelsByCityQuery({
 *   variables: {
 *      CheckOut: // value for 'CheckOut'
 *      CheckIn: // value for 'CheckIn'
 *      City: // value for 'City'
 *      NoOfRooms: // value for 'NoOfRooms'
 *      NoOfGuests: // value for 'NoOfGuests'
 *      DebtorProfileID: // value for 'DebtorProfileID'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useHotelsByCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelsByCityQuery, HotelsByCityQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelsByCityQuery, HotelsByCityQueryVariables>(HotelsByCityDocument, baseOptions);
      }
export function useHotelsByCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelsByCityQuery, HotelsByCityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelsByCityQuery, HotelsByCityQueryVariables>(HotelsByCityDocument, baseOptions);
        }
export type HotelsByCityQueryHookResult = ReturnType<typeof useHotelsByCityQuery>;
export type HotelsByCityLazyQueryHookResult = ReturnType<typeof useHotelsByCityLazyQuery>;
export type HotelsByCityQueryResult = ApolloReactCommon.QueryResult<HotelsByCityQuery, HotelsByCityQueryVariables>;
export const InterstTotalDocument = gql`
    query InterstTotal {
  InterstTotal {
    TotalTrxAmt
    TotalInterestAmt
  }
}
    `;

/**
 * __useInterstTotalQuery__
 *
 * To run a query within a React component, call `useInterstTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterstTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterstTotalQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterstTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InterstTotalQuery, InterstTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<InterstTotalQuery, InterstTotalQueryVariables>(InterstTotalDocument, baseOptions);
      }
export function useInterstTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InterstTotalQuery, InterstTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InterstTotalQuery, InterstTotalQueryVariables>(InterstTotalDocument, baseOptions);
        }
export type InterstTotalQueryHookResult = ReturnType<typeof useInterstTotalQuery>;
export type InterstTotalLazyQueryHookResult = ReturnType<typeof useInterstTotalLazyQuery>;
export type InterstTotalQueryResult = ApolloReactCommon.QueryResult<InterstTotalQuery, InterstTotalQueryVariables>;
export const UpdateInterestAmtDocument = gql`
    mutation UpdateInterestAmt($InterestRate: Float!) {
  UpdateInterestAmt(InterestRate: $InterestRate)
}
    `;
export type UpdateInterestAmtMutationFn = ApolloReactCommon.MutationFunction<UpdateInterestAmtMutation, UpdateInterestAmtMutationVariables>;

/**
 * __useUpdateInterestAmtMutation__
 *
 * To run a mutation, you first call `useUpdateInterestAmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterestAmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterestAmtMutation, { data, loading, error }] = useUpdateInterestAmtMutation({
 *   variables: {
 *      InterestRate: // value for 'InterestRate'
 *   },
 * });
 */
export function useUpdateInterestAmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInterestAmtMutation, UpdateInterestAmtMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInterestAmtMutation, UpdateInterestAmtMutationVariables>(UpdateInterestAmtDocument, baseOptions);
      }
export type UpdateInterestAmtMutationHookResult = ReturnType<typeof useUpdateInterestAmtMutation>;
export type UpdateInterestAmtMutationResult = ApolloReactCommon.MutationResult<UpdateInterestAmtMutation>;
export type UpdateInterestAmtMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInterestAmtMutation, UpdateInterestAmtMutationVariables>;
export const HotelDetailsDocument = gql`
    query HotelDetails($HotelID: String, $OnlineBookingURL: String) {
  HotelDetails(HotelID: $HotelID, OnlineBookingURL: $OnlineBookingURL) {
    ID
    HotelName
    AccountID
    SSTRegNo
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    Contact {
      FullName
      PhoneNo
      MobileNo
      GeneralEmail
      ReservationEmail
      BusinessEmail
      WebUrl
      NotificationMobileNo
    }
    LogoImage {
      ID
      ImageURL
    }
    AboutHotelImage {
      ID
      ImageURL
    }
    Facility {
      ID
      HotelID
      IsActive
      Title
      Description
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    RoomType {
      ID
      Code
      Description
      PropertyType
      MeasureType
      RoomSize
      TotalRoom
      MaxGuest
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          Code
          VacantOrOccupied
          CleanOrDirty
          Code
        }
      }
      RoomTypeGallery {
        ID
        Gallery {
          ID
          ImageURL
        }
      }
      RoomTypeAmenities {
        ID
        Name
        Gallery {
          ID
          ImageURL
        }
      }
    }
  }
}
    `;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      OnlineBookingURL: // value for 'OnlineBookingURL'
 *   },
 * });
 */
export function useHotelDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
      }
export function useHotelDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
        }
export type HotelDetailsQueryHookResult = ReturnType<typeof useHotelDetailsQuery>;
export type HotelDetailsLazyQueryHookResult = ReturnType<typeof useHotelDetailsLazyQuery>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<HotelDetailsQuery, HotelDetailsQueryVariables>;
export const HotelPricesByDateRangeDocument = gql`
    query HotelPricesByDateRange($CheckOut: DateTime, $CheckIn: DateTime, $HotelID: String!, $DebtorID: String, $DebtorType: String) {
  HotelPricesByDateRange(CheckOut: $CheckOut, CheckIn: $CheckIn, HotelID: $HotelID, DebtorID: $DebtorID, DebtorType: $DebtorType) {
    ID
    HotelName
    AccountID
    IsPostPaid
    OnlineTnC
    IsPostPaid
    AboutHotel
    Facility {
      ID
      HotelID
      IsActive
      Title
      Description
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    AboutHotelImage {
      ID
      ImageURL
    }
    HotelGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
        SequenceNo
      }
    }
    HotelRate {
      ID
      Code
      Description
      PropertyType
      MeasureType
      RoomSize
      TotalRoom
      MaxGuest
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          Code
          VacantOrOccupied
          CleanOrDirty
          Code
        }
      }
      RoomTypeGallery {
        ID
        Gallery {
          ID
          ImageURL
        }
      }
      RoomTypeAmenities {
        ID
        IsSelected
        Name
        Gallery {
          ID
          ImageURL
        }
      }
      TotalPackages {
        PackageName
        Price
        RatePolicyID
        PriceID
      }
      RoomTypeAvailability {
        ID
        EffectiveDate
        TotalVacant
        Pricing {
          ID
          PackageAmount
          SellingPrice
          RatePolicy {
            ID
            Description
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHotelPricesByDateRangeQuery__
 *
 * To run a query within a React component, call `useHotelPricesByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelPricesByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelPricesByDateRangeQuery({
 *   variables: {
 *      CheckOut: // value for 'CheckOut'
 *      CheckIn: // value for 'CheckIn'
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useHotelPricesByDateRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>(HotelPricesByDateRangeDocument, baseOptions);
      }
export function useHotelPricesByDateRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>(HotelPricesByDateRangeDocument, baseOptions);
        }
export type HotelPricesByDateRangeQueryHookResult = ReturnType<typeof useHotelPricesByDateRangeQuery>;
export type HotelPricesByDateRangeLazyQueryHookResult = ReturnType<typeof useHotelPricesByDateRangeLazyQuery>;
export type HotelPricesByDateRangeQueryResult = ApolloReactCommon.QueryResult<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>;
export const NewNotificationDocument = gql`
    subscription NewNotification($HotelID: String!, $registrationToken: String!) {
  NewNotification(HotelID: $HotelID, registrationToken: $registrationToken) {
    ID
    HotelID
    Message
    Date
  }
}
    `;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      registrationToken: // value for 'registrationToken'
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, baseOptions);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewNotificationSubscription>;
export const CurrentTaxDetailsDocument = gql`
    query CurrentTaxDetails($HotelID: String!) {
  CurrentTaxDetails(HotelID: $HotelID) {
    HotelID
    GovTax
    ServiceCharge
  }
}
    `;

/**
 * __useCurrentTaxDetailsQuery__
 *
 * To run a query within a React component, call `useCurrentTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTaxDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCurrentTaxDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, baseOptions);
      }
export function useCurrentTaxDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, baseOptions);
        }
export type CurrentTaxDetailsQueryHookResult = ReturnType<typeof useCurrentTaxDetailsQuery>;
export type CurrentTaxDetailsLazyQueryHookResult = ReturnType<typeof useCurrentTaxDetailsLazyQuery>;
export type CurrentTaxDetailsQueryResult = ApolloReactCommon.QueryResult<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>;
export const TravelAgentHotelListingDocument = gql`
    query TravelAgentHotelListing($Login: String!, $DebtorCategory: String!) {
  TravelAgentHotelListing(Login: $Login, DebtorCategory: $DebtorCategory) {
    ID
    HotelName
    AccountID
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    Encoders
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
  }
}
    `;

/**
 * __useTravelAgentHotelListingQuery__
 *
 * To run a query within a React component, call `useTravelAgentHotelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentHotelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentHotelListingQuery({
 *   variables: {
 *      Login: // value for 'Login'
 *      DebtorCategory: // value for 'DebtorCategory'
 *   },
 * });
 */
export function useTravelAgentHotelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentHotelListingQuery, TravelAgentHotelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentHotelListingQuery, TravelAgentHotelListingQueryVariables>(TravelAgentHotelListingDocument, baseOptions);
      }
export function useTravelAgentHotelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentHotelListingQuery, TravelAgentHotelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentHotelListingQuery, TravelAgentHotelListingQueryVariables>(TravelAgentHotelListingDocument, baseOptions);
        }
export type TravelAgentHotelListingQueryHookResult = ReturnType<typeof useTravelAgentHotelListingQuery>;
export type TravelAgentHotelListingLazyQueryHookResult = ReturnType<typeof useTravelAgentHotelListingLazyQuery>;
export type TravelAgentHotelListingQueryResult = ApolloReactCommon.QueryResult<TravelAgentHotelListingQuery, TravelAgentHotelListingQueryVariables>;
export const OnlinePaymentDocument = gql`
    mutation OnlinePayment($redirectUrl: String!, $PayAmount: Float!, $Detail: String!, $Title: String!, $HotelID: String!, $ReceiptNo: String!) {
  OnlinePayment(redirectUrl: $redirectUrl, PayAmount: $PayAmount, Detail: $Detail, Title: $Title, HotelID: $HotelID, ReceiptNo: $ReceiptNo) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    PaySessionNo
  }
}
    `;
export type OnlinePaymentMutationFn = ApolloReactCommon.MutationFunction<OnlinePaymentMutation, OnlinePaymentMutationVariables>;

/**
 * __useOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlinePaymentMutation, { data, loading, error }] = useOnlinePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      PayAmount: // value for 'PayAmount'
 *      Detail: // value for 'Detail'
 *      Title: // value for 'Title'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *   },
 * });
 */
export function useOnlinePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<OnlinePaymentMutation, OnlinePaymentMutationVariables>(OnlinePaymentDocument, baseOptions);
      }
export type OnlinePaymentMutationHookResult = ReturnType<typeof useOnlinePaymentMutation>;
export type OnlinePaymentMutationResult = ApolloReactCommon.MutationResult<OnlinePaymentMutation>;
export type OnlinePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>;
export const OnlineBookingInsertDocument = gql`
    mutation OnlineBookingInsert($AppName: String!, $RegistrationInput: [RegistrationInput!]!, $BookingInput: BookingInput!, $ContactInput: ContactInput!) {
  OnlineBookingInsert(AppName: $AppName, RegistrationInput: $RegistrationInput, BookingInput: $BookingInput, ContactInput: $ContactInput)
}
    `;
export type OnlineBookingInsertMutationFn = ApolloReactCommon.MutationFunction<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>;

/**
 * __useOnlineBookingInsertMutation__
 *
 * To run a mutation, you first call `useOnlineBookingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlineBookingInsertMutation, { data, loading, error }] = useOnlineBookingInsertMutation({
 *   variables: {
 *      AppName: // value for 'AppName'
 *      RegistrationInput: // value for 'RegistrationInput'
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useOnlineBookingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>(OnlineBookingInsertDocument, baseOptions);
      }
export type OnlineBookingInsertMutationHookResult = ReturnType<typeof useOnlineBookingInsertMutation>;
export type OnlineBookingInsertMutationResult = ApolloReactCommon.MutationResult<OnlineBookingInsertMutation>;
export type OnlineBookingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>;
export const PaymentUpdateDocument = gql`
    mutation PaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $HotelID: String, $ReceiptNo: String, $RegistrationID: String) {
  PaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, HotelID: $HotelID, ReceiptNo: $ReceiptNo, RegistrationID: $RegistrationID)
}
    `;
export type PaymentUpdateMutationFn = ApolloReactCommon.MutationFunction<PaymentUpdateMutation, PaymentUpdateMutationVariables>;

/**
 * __usePaymentUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentUpdateMutation, { data, loading, error }] = usePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PaymentUpdateMutation, PaymentUpdateMutationVariables>(PaymentUpdateDocument, baseOptions);
      }
export type PaymentUpdateMutationHookResult = ReturnType<typeof usePaymentUpdateMutation>;
export type PaymentUpdateMutationResult = ApolloReactCommon.MutationResult<PaymentUpdateMutation>;
export type PaymentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>;
export const AdvancePaymentUpdateDocument = gql`
    mutation AdvancePaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $HotelID: String, $ReceiptNo: String, $RegistrationID: String) {
  AdvancePaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, HotelID: $HotelID, ReceiptNo: $ReceiptNo, RegistrationID: $RegistrationID)
}
    `;
export type AdvancePaymentUpdateMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;

/**
 * __useAdvancePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentUpdateMutation, { data, loading, error }] = useAdvancePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useAdvancePaymentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>(AdvancePaymentUpdateDocument, baseOptions);
      }
export type AdvancePaymentUpdateMutationHookResult = ReturnType<typeof useAdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;
export const PaymentDetailDocument = gql`
    query PaymentDetail($OrderID: String, $RegistrationID: [String!]) {
  PaymentDetail(OrderID: $OrderID, RegistrationID: $RegistrationID) {
    ID
    FolioID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    CardNo
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;

/**
 * __usePaymentDetailQuery__
 *
 * To run a query within a React component, call `usePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailQuery({
 *   variables: {
 *      OrderID: // value for 'OrderID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, baseOptions);
      }
export function usePaymentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, baseOptions);
        }
export type PaymentDetailQueryHookResult = ReturnType<typeof usePaymentDetailQuery>;
export type PaymentDetailLazyQueryHookResult = ReturnType<typeof usePaymentDetailLazyQuery>;
export type PaymentDetailQueryResult = ApolloReactCommon.QueryResult<PaymentDetailQuery, PaymentDetailQueryVariables>;
export const ReceiptListingDocument = gql`
    query ReceiptListing($PaymentClass: PaymentClass!, $ReceiptNo: String!) {
  ReceiptListing(PaymentClass: $PaymentClass, ReceiptNo: $ReceiptNo) {
    RegistrationID
    TrxDate
    ChargeType
    Description
    ReferenceNo
    FullName
    RoomNo
    BaseAmount
    TrxAmount
    ID
  }
}
    `;

/**
 * __useReceiptListingQuery__
 *
 * To run a query within a React component, call `useReceiptListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceiptListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiptListingQuery({
 *   variables: {
 *      PaymentClass: // value for 'PaymentClass'
 *      ReceiptNo: // value for 'ReceiptNo'
 *   },
 * });
 */
export function useReceiptListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReceiptListingQuery, ReceiptListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReceiptListingQuery, ReceiptListingQueryVariables>(ReceiptListingDocument, baseOptions);
      }
export function useReceiptListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReceiptListingQuery, ReceiptListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReceiptListingQuery, ReceiptListingQueryVariables>(ReceiptListingDocument, baseOptions);
        }
export type ReceiptListingQueryHookResult = ReturnType<typeof useReceiptListingQuery>;
export type ReceiptListingLazyQueryHookResult = ReturnType<typeof useReceiptListingLazyQuery>;
export type ReceiptListingQueryResult = ApolloReactCommon.QueryResult<ReceiptListingQuery, ReceiptListingQueryVariables>;
export const DisCountByPromoCodeDocument = gql`
    query DisCountByPromoCode($PromoCodeInput: [PromoCodeInput!]!, $PromoCode: String!, $HotelID: String!) {
  DisCountByPromoCode(PromoCodeInput: $PromoCodeInput, PromoCode: $PromoCode, HotelID: $HotelID) {
    RoomTypeID
    RatePolicyID
    DiscountAmt
  }
}
    `;

/**
 * __useDisCountByPromoCodeQuery__
 *
 * To run a query within a React component, call `useDisCountByPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisCountByPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisCountByPromoCodeQuery({
 *   variables: {
 *      PromoCodeInput: // value for 'PromoCodeInput'
 *      PromoCode: // value for 'PromoCode'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDisCountByPromoCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>(DisCountByPromoCodeDocument, baseOptions);
      }
export function useDisCountByPromoCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>(DisCountByPromoCodeDocument, baseOptions);
        }
export type DisCountByPromoCodeQueryHookResult = ReturnType<typeof useDisCountByPromoCodeQuery>;
export type DisCountByPromoCodeLazyQueryHookResult = ReturnType<typeof useDisCountByPromoCodeLazyQuery>;
export type DisCountByPromoCodeQueryResult = ApolloReactCommon.QueryResult<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>;
export const ComputeTaxArrByHotelDocument = gql`
    query ComputeTaxArrByHotel($TaxInput: [TaxInput!]!) {
  ComputeTaxArrByHotel(TaxInput: $TaxInput) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
  }
}
    `;

/**
 * __useComputeTaxArrByHotelQuery__
 *
 * To run a query within a React component, call `useComputeTaxArrByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxArrByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxArrByHotelQuery({
 *   variables: {
 *      TaxInput: // value for 'TaxInput'
 *   },
 * });
 */
export function useComputeTaxArrByHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, baseOptions);
      }
export function useComputeTaxArrByHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, baseOptions);
        }
export type ComputeTaxArrByHotelQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelQuery>;
export type ComputeTaxArrByHotelLazyQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelLazyQuery>;
export type ComputeTaxArrByHotelQueryResult = ApolloReactCommon.QueryResult<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>;
export const DocumentTemplateListDocument = gql`
    query DocumentTemplateList($ID: String!) {
  DocumentTemplateList(ID: $ID) {
    RegCard
    Folio
    Receipt
    Invoice
  }
}
    `;

/**
 * __useDocumentTemplateListQuery__
 *
 * To run a query within a React component, call `useDocumentTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTemplateListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDocumentTemplateListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>(DocumentTemplateListDocument, baseOptions);
      }
export function useDocumentTemplateListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>(DocumentTemplateListDocument, baseOptions);
        }
export type DocumentTemplateListQueryHookResult = ReturnType<typeof useDocumentTemplateListQuery>;
export type DocumentTemplateListLazyQueryHookResult = ReturnType<typeof useDocumentTemplateListLazyQuery>;
export type DocumentTemplateListQueryResult = ApolloReactCommon.QueryResult<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>;
export const EmailSendDocument = gql`
    mutation EmailSend($Body: EmailBodyInput!, $HotelID: String!, $Email: String) {
  EmailSend(Body: $Body, HotelID: $HotelID, Email: $Email)
}
    `;
export type EmailSendMutationFn = ApolloReactCommon.MutationFunction<EmailSendMutation, EmailSendMutationVariables>;

/**
 * __useEmailSendMutation__
 *
 * To run a mutation, you first call `useEmailSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSendMutation, { data, loading, error }] = useEmailSendMutation({
 *   variables: {
 *      Body: // value for 'Body'
 *      HotelID: // value for 'HotelID'
 *      Email: // value for 'Email'
 *   },
 * });
 */
export function useEmailSendMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailSendMutation, EmailSendMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailSendMutation, EmailSendMutationVariables>(EmailSendDocument, baseOptions);
      }
export type EmailSendMutationHookResult = ReturnType<typeof useEmailSendMutation>;
export type EmailSendMutationResult = ApolloReactCommon.MutationResult<EmailSendMutation>;
export type EmailSendMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailSendMutation, EmailSendMutationVariables>;
export const BiSharedGAlleryInsertDocument = gql`
    mutation BISharedGAlleryInsert($HotelID: String!, $AttachmentInput: [Upload!]!) {
  BISharedGAlleryInsert(HotelID: $HotelID, AttachmentInput: $AttachmentInput) {
    ID
    Gallery {
      ImageURL
    }
  }
}
    `;
export type BiSharedGAlleryInsertMutationFn = ApolloReactCommon.MutationFunction<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>;

/**
 * __useBiSharedGAlleryInsertMutation__
 *
 * To run a mutation, you first call `useBiSharedGAlleryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBiSharedGAlleryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [biSharedGAlleryInsertMutation, { data, loading, error }] = useBiSharedGAlleryInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBiSharedGAlleryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>(BiSharedGAlleryInsertDocument, baseOptions);
      }
export type BiSharedGAlleryInsertMutationHookResult = ReturnType<typeof useBiSharedGAlleryInsertMutation>;
export type BiSharedGAlleryInsertMutationResult = ApolloReactCommon.MutationResult<BiSharedGAlleryInsertMutation>;
export type BiSharedGAlleryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>;
export const SelectFolioDocument = gql`
    query SelectFolio($ID: String!) {
  SelectFolio(ID: $ID) {
    FolioNo
  }
}
    `;

/**
 * __useSelectFolioQuery__
 *
 * To run a query within a React component, call `useSelectFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectFolioQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSelectFolioQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectFolioQuery, SelectFolioQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectFolioQuery, SelectFolioQueryVariables>(SelectFolioDocument, baseOptions);
      }
export function useSelectFolioLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectFolioQuery, SelectFolioQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectFolioQuery, SelectFolioQueryVariables>(SelectFolioDocument, baseOptions);
        }
export type SelectFolioQueryHookResult = ReturnType<typeof useSelectFolioQuery>;
export type SelectFolioLazyQueryHookResult = ReturnType<typeof useSelectFolioLazyQuery>;
export type SelectFolioQueryResult = ApolloReactCommon.QueryResult<SelectFolioQuery, SelectFolioQueryVariables>;
export const IsPaymentGateWayHotelDocument = gql`
    query IsPaymentGateWayHotel($HotelID: String!) {
  IsPaymentGateWayHotel(HotelID: $HotelID)
}
    `;

/**
 * __useIsPaymentGateWayHotelQuery__
 *
 * To run a query within a React component, call `useIsPaymentGateWayHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPaymentGateWayHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPaymentGateWayHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsPaymentGateWayHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, baseOptions);
      }
export function useIsPaymentGateWayHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, baseOptions);
        }
export type IsPaymentGateWayHotelQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelQuery>;
export type IsPaymentGateWayHotelLazyQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelLazyQuery>;
export type IsPaymentGateWayHotelQueryResult = ApolloReactCommon.QueryResult<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>;
export const DebtorListingDocument = gql`
    query DebtorListing($HotelID: String!) {
  DebtorListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    SalesChannel
    Login
    Password
    ARTotalDue
    ARTotalOutstanding
    Contact {
      ID
      FullName
      PhoneNo
      Email
      Address {
        address
        city
        state
        country
        postCode
      }
    }
    LastPaymentDate
    DebtorContact {
      ID
      Contact {
        ID
        FullName
        Email
        MobileNo
        Designation
      }
    }
  }
}
    `;

/**
 * __useDebtorListingQuery__
 *
 * To run a query within a React component, call `useDebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
      }
export function useDebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
        }
export type DebtorListingQueryHookResult = ReturnType<typeof useDebtorListingQuery>;
export type DebtorListingLazyQueryHookResult = ReturnType<typeof useDebtorListingLazyQuery>;
export type DebtorListingQueryResult = ApolloReactCommon.QueryResult<DebtorListingQuery, DebtorListingQueryVariables>;